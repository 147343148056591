import { Paginated, PaginatedParams } from '@/types';
import { SortPlan } from '@/components/sort/types';
import * as AT from './actionTypes';
import * as MT from './mutationTypes';
import { ActionContext, ActionTree } from 'vuex';
import { IQCState } from './state';
import { IRootState } from '@/store';
import { QCService } from '@/modules/qc/services';
import {
  QCFundInvestment,
  UpdateQCFundsRequest,
  QCFundFilters,
} from '@/modules/qc/types';
import { AnalysisFundFilters } from '@/modules/analysis/types';
import { FundCost } from '@/models/Analysis';
import { AnalysisService } from '@/modules/analysis/services';

export const QCActions: IQCActions = {
  [AT.FETCH_QC_FUNDS]: async (
    { commit },
    params?: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filters?: QCFundFilters | null;
    }>
  ): Promise<Paginated<QCFundInvestment>> => {
    commit(MT.SET_FETCHING_QC_FUNDS, true);
    try {
      const analysis = await QCService.fetchQCFunds(params);
      commit(MT.SET_QC_FUNDS, analysis);
      return analysis;
    } catch (e) {
      commit(MT.SET_QC_FUNDS, []);
      throw e;
    } finally {
      commit(MT.SET_FETCHING_QC_FUNDS, false);
    }
  },

  [AT.UPDATE_QC_FUNDS]: async (
    { commit },
    request: UpdateQCFundsRequest
  ): Promise<Response> => {
    return await QCService.updateQCFunds(request).then((data) => {
      return data;
    });
  },

  [AT.FETCH_YOY_FUND_ANALYSIS]: async (
    { commit },
    params?: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filters?: AnalysisFundFilters | null;
    }>
  ): Promise<Paginated<FundCost>> => {
    try {
      const analysis = await AnalysisService.getFundAnalysis(params);
      commit(MT.SET_YOY_FUND_ANALYSIS, analysis);
      return analysis;
    } catch (e) {
      commit(MT.SET_YOY_FUND_ANALYSIS, []);
      throw e;
    }
  },
};

export interface IQCActions extends ActionTree<IQCState, IRootState> {
  [AT.FETCH_QC_FUNDS](
    context: IQCActionContext,
    params: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filters?: QCFundFilters | null;
    }>
  ): Promise<Paginated<QCFundInvestment>>;
  [AT.UPDATE_QC_FUNDS](
    context: IQCActionContext,
    request: UpdateQCFundsRequest
  ): Promise<Response>;
}

/**
 * --------------------------------------------------
 *  Analysis Action Context Interface
 * --------------------------------------------------
 **/
export type IQCActionContext = ActionContext<IQCState, IRootState>;
