import Organisation from '@/models/Organisation';
import { Paginated } from '@/types';

export type IClientState = {
  clients: Paginated<Organisation[]> | null;
  fetchingClients: boolean;
};

export const ClientState: IClientState = {
  clients: null,
  fetchingClients: false,
};
