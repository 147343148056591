import { FilterPlan } from '@/components/filter/types';
import { SortPlan } from '@/components/sort/types';
import Fund from '@/models/Fund';
import FundNature from '@/models/FundNature';
import ProductType from '@/models/ProductType';
import Strategy from '@/models/Strategy';
import Template from '@/models/Template';
import TimePeriod from '@/models/TimePeriod';
import { IRootState } from '@/store';
import { Paginated, PaginatedParams } from '@/types';
import { ActionContext, ActionTree } from 'vuex';
import { InvestmentService } from '../services';
import {
  DownloadDataResponse,
  ExportDataRequest,
  ExportDataResponse,
  FundFilters,
  FundInvestment,
  ImportFundsRequest,
  ImportOnboardingSheetRequest,
  MergeEntityIdsRequest,
  SendEmailsRequest,
  SendSubmissionRequestsRequest,
  UpdateFundsRequest,
  UpdateInvestmentRequest,
  UpdateRollupTagRequest,
} from '../types';
import * as AT from './actionTypes';
import * as MT from './mutationsTypes';
import { IInvestmentState } from './state';

/**
 * --------------------------------------------------
 *  Investment Actions
 * --------------------------------------------------
 **/
export const InvestmentActions: IInvestmentActions = {
  [AT.FETCH_INVESTMENTS]: async (
    { commit },
    params?: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filters?: FundFilters | null;
    }>
  ): Promise<Paginated<FundInvestment>> => {
    commit(MT.SET_FETCHING_INVESTMENTS, true);
    try {
      const data = await InvestmentService.fetchFundInvestments(params);
      commit(MT.SET_INVESTMENTS, data);
      return data;
    } catch (e) {
      commit(MT.SET_INVESTMENTS, []);
      throw e;
    } finally {
      commit(MT.SET_FETCHING_INVESTMENTS, false);
    }
  },

  [AT.FETCH_SUB_FUNDS]: async ({ commit }): Promise<Fund[]> => {
    return InvestmentService.fetchSubFunds().then((data) => {
      commit(MT.SET_SUB_FUNDS, data);
      return data;
    });
  },

  [AT.FETCH_FUND_NATURES]: async ({ commit }): Promise<FundNature[]> => {
    return InvestmentService.fetchFundNatures().then((data) => {
      commit(MT.SET_FUND_NATURES, data);
      return data;
    });
  },

  [AT.FETCH_STRATEGIES]: async ({ commit }): Promise<Strategy[]> => {
    return InvestmentService.fetchStrategies().then((data) => {
      commit(MT.SET_STRATEGIES, data);
      return data;
    });
  },

  [AT.FETCH_TEMPLATES]: async ({ commit }): Promise<Template[]> => {
    return InvestmentService.fetchTemplates().then((data) => {
      commit(MT.SET_TEMPLATES, data);
      return data;
    });
  },

  [AT.FETCH_TIME_PERIODS]: async ({ commit }): Promise<TimePeriod[]> => {
    return InvestmentService.fetchTimePeriods().then((data) => {
      commit(MT.SET_TIME_PERIODS, data);
      return data;
    });
  },

  [AT.EXPORT_DATA]: async (
    { commit },
    request: ExportDataRequest
  ): Promise<ExportDataResponse> => {
    return InvestmentService.exportData(request).then((data) => {
      return data;
    });
  },

  [AT.DOWNLOAD_DATA]: async (
    { commit },
    jobId: string
  ): Promise<DownloadDataResponse> => {
    return InvestmentService.downloadData(jobId).then((data) => {
      return data;
    });
  },

  [AT.RECOLLECT_DATA]: async (
    { commit },
    investmentIds: string[]
  ): Promise<Response> => {
    return InvestmentService.recollectData(investmentIds).then((data) => {
      return data;
    });
  },

  [AT.SEND_EMAILS]: async (
    { commit },
    sendEmailsRequest: SendEmailsRequest
  ): Promise<Response> => {
    return InvestmentService.sendEmails(sendEmailsRequest).then((data) => {
      return data;
    });
  },

  [AT.SEND_SUBMISSION_REQUESTS]: async (
    { commit },
    request: SendSubmissionRequestsRequest
  ): Promise<Response> => {
    return InvestmentService.sendSubmissionRequests(request).then((data) => {
      return data;
    });
  },

  [AT.FETCH_PRODUCT_TYPES]: async ({ commit }): Promise<ProductType[]> => {
    return InvestmentService.fetchProductTypes().then((data) => {
      commit(MT.SET_PRODUCT_TYPES, data);
      return data;
    });
  },

  [AT.UPDATE_FUNDS]: async (
    { commit },
    request: UpdateFundsRequest
  ): Promise<Response> => {
    return InvestmentService.updateFunds(request).then((data) => {
      return data;
    });
  },

  [AT.IMPORT_FUNDS]: async (
    { commit },
    request: ImportFundsRequest
  ): Promise<Response> => {
    return InvestmentService.importFunds(request).then((data) => {
      return data;
    });
  },

  [AT.IMPORT_ONBOARDING_SHEET]: async (
    { commit },
    request: ImportOnboardingSheetRequest
  ): Promise<Response> => {
    return InvestmentService.importOnboardingSheet(request).then((data) => {
      return data;
    });
  },

  [AT.RETRACT_SUBMISSION_REQUESTS]: async (
    { commit },
    submissionRequestIds: string[]
  ): Promise<Response> => {
    return InvestmentService.retractRequest(submissionRequestIds).then(
      (data) => {
        return data;
      }
    );
  },

  [AT.MERGE_ENTITY_IDS]: async (
    { commit },
    request: MergeEntityIdsRequest
  ): Promise<Response> => {
    return InvestmentService.mergeEntityIds(request).then((data) => {
      return data;
    });
  },

  [AT.UPDATE_ROLLUP_TAG]: async (
    { commit },
    request: UpdateRollupTagRequest
  ): Promise<Response> => {
    return InvestmentService.updateRollupTag(request).then((data) => {
      return data;
    });
  },

  [AT.UPDATE_INVESTMENT]: async (
    { commit },
    req: { id: string; request: UpdateInvestmentRequest }
  ): Promise<any> => {
    return InvestmentService.updateInvestment(req.id, req.request);
  },
};

/**
 * --------------------------------------------------
 *  Investment Actions Interface
 * --------------------------------------------------
 **/
export interface IInvestmentActions
  extends ActionTree<IInvestmentState, IRootState> {
  [AT.FETCH_INVESTMENTS](
    context: IInvestmentActionContext,
    params: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filterPlan?: FilterPlan | null;
    }>
  ): Promise<Paginated<FundInvestment>>;

  [AT.UPDATE_INVESTMENT](
    context: IInvestmentActionContext,
    req: { id: string; request: UpdateInvestmentRequest }
  ): Promise<any>;

  [AT.FETCH_SUB_FUNDS](context: IInvestmentActionContext): Promise<Fund[]>;

  [AT.FETCH_FUND_NATURES](
    context: IInvestmentActionContext
  ): Promise<FundNature[]>;

  [AT.FETCH_STRATEGIES](context: IInvestmentActionContext): Promise<Strategy[]>;

  [AT.FETCH_TEMPLATES](context: IInvestmentActionContext): Promise<Template[]>;

  [AT.FETCH_TIME_PERIODS](
    context: IInvestmentActionContext
  ): Promise<TimePeriod[]>;

  [AT.EXPORT_DATA](
    context: IInvestmentActionContext,
    request: ExportDataRequest
  ): Promise<ExportDataResponse>;

  [AT.DOWNLOAD_DATA](
    context: IInvestmentActionContext,
    jobId: string
  ): Promise<DownloadDataResponse>;

  [AT.RECOLLECT_DATA](
    context: IInvestmentActionContext,
    investmentIds: string[]
  ): Promise<Response>;

  [AT.SEND_EMAILS](
    context: IInvestmentActionContext,
    sendEmailsRequest: SendEmailsRequest
  ): Promise<Response>;

  [AT.SEND_SUBMISSION_REQUESTS](
    context: IInvestmentActionContext,
    request: SendSubmissionRequestsRequest
  ): Promise<Response>;

  [AT.FETCH_PRODUCT_TYPES](
    context: IInvestmentActionContext
  ): Promise<ProductType[]>;

  [AT.UPDATE_FUNDS](
    context: IInvestmentActionContext,
    request: UpdateFundsRequest
  ): Promise<Response>;

  [AT.IMPORT_FUNDS](
    context: IInvestmentActionContext,
    request: ImportFundsRequest
  ): Promise<Response>;

  [AT.RETRACT_SUBMISSION_REQUESTS](
    context: IInvestmentActionContext,
    submissionRequestIds: string[]
  ): Promise<Response>;

  [AT.IMPORT_ONBOARDING_SHEET](
    context: IInvestmentActionContext,
    request: ImportOnboardingSheetRequest
  ): Promise<Response>;
}

/**
 * --------------------------------------------------
 *  Investment Action Context Interface
 * --------------------------------------------------
 **/
export type IInvestmentActionContext = ActionContext<
  IInvestmentState,
  IRootState
>;
