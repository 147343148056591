import { IPensionSchemeState } from './state';
import { MutationTree } from 'vuex';
import * as MT from './mutationsTypes';
import { Paginated } from '@/types';
import Organisation from '@/models/Organisation';

/*--------------------------------------------------/
 * PensionScheme Mutations
 *--------------------------------------------------*/
export const PensionSchemeMutations: IPensionSchemeMutations = {
  [MT.SET_PENSION_SCHEMES]: (
    state: IPensionSchemeState,
    pensionSchemes: Paginated<Organisation[]>
  ) => (state.pensionSchemes = pensionSchemes),

  [MT.SET_FETCHING_PENSION_SCHEMES]: (
    state: IPensionSchemeState,
    flag: boolean
  ) => (state.fetchingPensionSchemes = flag),
};

/*--------------------------------------------------/
 * PensionScheme Mutations Tree Interface
 *--------------------------------------------------*/
export interface IPensionSchemeMutations
  extends MutationTree<IPensionSchemeState> {
  [MT.SET_PENSION_SCHEMES](
    state: IPensionSchemeState,
    pensionSchemes: Paginated<Organisation[]>
  ): void;

  [MT.SET_FETCHING_PENSION_SCHEMES](
    state: IPensionSchemeState,
    flag: boolean
  ): void;
}
