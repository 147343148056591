import { RouteConfig } from 'vue-router';
import { AnalysisRoutes } from '../analysis/routes';
import { UserRoutes } from '../user/routes';
import { ConsultantRoutes } from '../consultant/routes';
import { PensionSchemesRoutes } from '../pension-scheme/routes';
import { ClientRoutes } from '../client/routes';
import { InvestmentRoutes } from '../investment/routes';
import { AssetManagerRoutes } from '../asset-manager/routes';
import { SubmissionRoutes } from '../submission/routes';
import { FiduciaryRoutes } from '../fiduciary/routes';
import { RenewalsRoutes } from '../renewal/routes';
import { QCRoutes } from '@/modules/qc/routes';

const DashboardModule = () =>
  import(
    /* webpackChunkName: 'dashboard' */ '@/modules/dashboard/DashboardModule.vue'
  );
const DashboardMainPage = () =>
  import(/* webpackChunkName: 'dashboard' */ './pages/DashboardMainPage.vue');

export const DashboardRoutes: RouteConfig[] = [
  {
    path: '/dashboard',
    component: DashboardModule,
    meta: {
      middlewares: {
        requiresAuth: true,
      },
    },
    children: [
      {
        path: '/',
        name: 'dashboard',
        redirect: {
          name: 'analysis.list',
        },
      },

      // Child Module Routes
      ...UserRoutes,
      ...AnalysisRoutes,
      ...ConsultantRoutes,
      ...PensionSchemesRoutes,
      ...ClientRoutes,
      ...InvestmentRoutes,
      ...AssetManagerRoutes,
      ...SubmissionRoutes,
      ...FiduciaryRoutes,
      ...RenewalsRoutes,
      ...QCRoutes,
    ],
  },
];
