import { IRenewalState } from './state';
import { MutationTree } from 'vuex';
import * as MT from './mutationsTypes';
import { Paginated } from '@/types';
import Organisation from '@/models/Organisation';
import RenewalOrganisation from '../../../models/RenewalOrganisation';
import { RenewalFilters } from '../types';

/*--------------------------------------------------/
 * Renewal Mutations
 *--------------------------------------------------*/
export const RenewalMutations: IRenewalMutations = {
  [MT.SET_RENEWAL_ORGANISATIONS]: (
    state: IRenewalState,
    renewalClients: Paginated<RenewalOrganisation[]>
  ) => (state.renewalClients = renewalClients),

  [MT.SET_FETCHING_RENEWAL_ORGANISATION]: (
    state: IRenewalState,
    flag: boolean
  ) => (state.fetchingRenewalClients = flag),

  [MT.SET_APPLIED_RENEWAL_FILTERS]: (
    state: IRenewalState,
    appliedFilters: RenewalFilters | null
  ) => (state.appliedRenewalFilters = appliedFilters),
};

/*--------------------------------------------------/
 * Renewal Mutations Tree Interface
 *--------------------------------------------------*/
export interface IRenewalMutations extends MutationTree<IRenewalState> {
  [MT.SET_RENEWAL_ORGANISATIONS](
    state: IRenewalState,
    renewalClients: Paginated<RenewalOrganisation[]>
  ): void;

  [MT.SET_FETCHING_RENEWAL_ORGANISATION](
    state: IRenewalState,
    flag: boolean
  ): void;

  [MT.SET_APPLIED_RENEWAL_FILTERS](
    state: IRenewalState,
    appliedFilters: RenewalFilters | null
  ): void;
}
