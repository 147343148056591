import { Filter } from '@/components/filter/Filter';
import { FilterPlan } from '@/components/filter/types';
import { Sort } from '@/components/sort/Sort';
import { SortPlan } from '@/components/sort/types';
import Organisation from '@/models/Organisation';
import OrganizationUser from '@/models/OrganizationUser';
import { HTTPServiceClient } from '@/packages/http/service';
import { APIErrorResponse } from '@/packages/http/types';
import { Paginated, PaginatedParams } from '@/types';
import ErrorUtils from '@/utils/ErrorUtils';
import * as t from './endpoints';
import {
  AddAssetManagerRequest,
  AddSuperAdminUsersRequest,
  EditAssetManagerRequest,
  MergeAssetManagerOrganizationsRequest,
} from './types';

/**
 * --------------------------------------------------
 *	Asset Managers HTTP Serice
 * --------------------------------------------------
 **/
export const AssetManagerService = {
  /**
   * Fetches all the asset managers.
   * @returns {Promise<Paginated<Organisation>>}
   */
  async fetchAssetManagers(
    props?: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filterPlan?: FilterPlan | null;
    }>
  ): Promise<Paginated<Organisation>> {
    try {
      const filterParams = {
        ...(props?.filterPlan ? Filter.toQuery(props?.filterPlan) : {}),
      };
      const sortParams = {
        ...(props?.sortPlan ? Sort.toQuery(props?.sortPlan) : {}),
      };

      delete props?.sortPlan;
      delete props?.filterPlan;

      const params = { ...props, ...sortParams, ...filterParams };
      const { data } = await HTTPServiceClient.get<Paginated<Organisation>>(
        t.FETCH_ASSET_MANAGERS_ENDPOINT,
        {
          params,
        }
      );
      return data ?? {};
    } catch (e) {
      throw new Error(ErrorUtils.getErrorMessage(e as APIErrorResponse));
    }
  },

  /**
   * Fetches all the asset manager organizations.
   * @returns {Promise<Organisation[]>}
   */
  async fetchAssetManagerOrganizations(): Promise<Organisation[]> {
    try {
      const { data } = await HTTPServiceClient.get<Organisation[]>(
        t.FETCH_ASSET_MANAGER_ORGANIZATIONS_ENDPOINT
      );
      return data ?? [];
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Add an asset manager
   * @returns {Promise<Response>}
   */
  async addAssetManager(
    addAssetManagerRequest: AddAssetManagerRequest
  ): Promise<Response> {
    try {
      const body: AddAssetManagerRequest = {
        email: addAssetManagerRequest?.email,
        name: addAssetManagerRequest?.name,
      };
      if (addAssetManagerRequest?.organizationId) {
        body['organizationId'] = addAssetManagerRequest?.organizationId;
      }
      if (addAssetManagerRequest?.organizationName) {
        body['organizationName'] = addAssetManagerRequest?.organizationName;
      }
      const { data } = await HTTPServiceClient.post<Response>(
        t.ADD_ASSER_MANAGER_ENDPOINT,
        body
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Add an asset manager
   * @returns {Promise<Response>}
   */
  async editAssetManger(
    editAssetManagerRequest: EditAssetManagerRequest,
    organizationId: string
  ): Promise<Response> {
    try {
      const { data } = await HTTPServiceClient.patch<Response>(
        t.EDIT_ASSET_MANAGER_ENDPOINT(organizationId),
        editAssetManagerRequest
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Fetches all the asset manager organization users.
   * @returns {Promise<OrganizationUser[]>}
   */
  async fetchAssetManagerOrganizationUsers(
    organizationId: string
  ): Promise<OrganizationUser[]> {
    try {
      const { data } = await HTTPServiceClient.get<OrganizationUser[]>(
        t.FETCH_ASSER_MANAGER_ORGANIZATION_USERS_ENDPOINT(organizationId)
      );
      return data ?? [];
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Add an asset manager
   * @returns {Promise<Response>}
   */
  async addAssetManagerSuperAdmin(
    addSuperAdminUsersRequest: AddSuperAdminUsersRequest
  ): Promise<Response> {
    try {
      const body = {
        updatedIds: addSuperAdminUsersRequest?.updatedIds,
        deletedIds: addSuperAdminUsersRequest?.deletedIds,
      };

      const { data } = await HTTPServiceClient.post<Response>(
        t.ADD_ASSER_MANAGER_SUPER_ADMIN_ENDPOINT(
          addSuperAdminUsersRequest?.organizationId
        ),
        body
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Merge Asset Manager Organizations
   * @returns {Promise<Response>}
   */
  async mergeAssetManagerOrganizations(
    mergeRequest: MergeAssetManagerOrganizationsRequest
  ): Promise<Response> {
    try {
      const { data } = await HTTPServiceClient.post<Response>(
        t.MERGE_ASSET_MANAGER_ORGANIZATIONS,
        mergeRequest
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },
};
