import * as AT from './actionTypes';
import * as MT from './mutationsTypes';
import { IRootState } from '@/store';
import { IResourceTagState } from './state';
import { ActionContext, ActionTree } from 'vuex';
import { Paginated } from '@/types';
import { ResourceTagService } from '../services';
import { ResourceTag } from '@/models/ResourceTag';

/**
 * --------------------------------------------------
 *  Resource Tag Actions
 * --------------------------------------------------
 **/
export const ResourceTagActions: IResourceTagActions = {
  [AT.FETCH_RESOURCE_TAGS]: async (
    { commit },
    params: Parameters<typeof ResourceTagService.fetchTagsForResource>[0]
  ): ReturnType<typeof ResourceTagService.fetchTagsForResource> => {
    commit(MT.SET_RESOURCE_TAGS_ARE_LOADING);
    return ResourceTagService.fetchTagsForResource(params, {
      include: ['tag'],
      limit: 100,
    })
      .then((data) => {
        commit(MT.SET_RESOURCE_TAGS, data);
        return data;
      })
      .finally(() => {
        commit(MT.SET_RESOURCE_TAGS_ARE_NOT_LOADING);
      });
  },
  [AT.SET_RESOURCE_TAGS]: async (
    { commit },
    params: Parameters<typeof ResourceTagService.setResourceTags>[0]
  ): ReturnType<typeof ResourceTagService.setResourceTags> => {
    return ResourceTagService.setResourceTags(params).then((response) => {
      commit(MT.MERGE_RESOURCE_TAGS, { params, response });
      return response;
    });
  },
};

/**
 * --------------------------------------------------
 *  Resource Tag Actions Interface
 * --------------------------------------------------
 **/
export interface IResourceTagActions
  extends ActionTree<IResourceTagState, IRootState> {
  [AT.FETCH_RESOURCE_TAGS](
    context: IResourceTagActionContext,
    params: Parameters<typeof ResourceTagService.fetchTagsForResource>[0]
  ): ReturnType<typeof ResourceTagService.fetchTagsForResource>;
  [AT.SET_RESOURCE_TAGS](
    context: IResourceTagActionContext,
    params: Parameters<typeof ResourceTagService.setResourceTags>[0]
  ): ReturnType<typeof ResourceTagService.setResourceTags>;
}

/**
 * --------------------------------------------------
 *  Resource Tag Action Context Interface
 * --------------------------------------------------
 **/
export type IResourceTagActionContext = ActionContext<
  IResourceTagState,
  IRootState
>;
