import { Filter } from '@/components/filter/Filter';
import { FilterPlan } from '@/components/filter/types';
import { Sort } from '@/components/sort/Sort';
import { SortPlan } from '@/components/sort/types';
import Organisation from '@/models/Organisation';
import { HTTPServiceClient } from '@/packages/http/service';
import { APIErrorResponse } from '@/packages/http/types';
import { Paginated, PaginatedParams } from '@/types';
import * as t from './endpoints';

/**
 * --------------------------------------------------
 *	Fiduciary HTTP Serice
 * --------------------------------------------------
 **/
export const FiduciaryService = {
  /**
   * Fetches all the consultants.
   * @returns {Promise<Paginated<Organisation>>}
   */
  async fetchFiduciaries(
    props?: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filterPlan?: FilterPlan | null;
    }>
  ): Promise<Paginated<Organisation>> {
    try {
      const filterParams = {
        ...(props?.filterPlan ? Filter.toQuery(props?.filterPlan) : {}),
      };
      const sortParams = {
        ...(props?.sortPlan ? Sort.toQuery(props?.sortPlan) : {}),
      };

      delete props?.sortPlan;
      delete props?.filterPlan;

      const params = { ...props, ...sortParams, ...filterParams };
      const { data } = await HTTPServiceClient.get<Paginated<Organisation>>(
        t.FETCH_FIDUCIARY_ENDPOINT,
        {
          params,
        }
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },
};
