import { IAssetManagerState } from './state';
import { MutationTree } from 'vuex';
import * as MT from './mutationsTypes';
import { Paginated } from '@/types';
import Organisation from '@/models/Organisation';

/*--------------------------------------------------/
 * Asset Manager Mutations
 *--------------------------------------------------*/
export const AssetManagerMutations: IAssetManagerMutations = {
  [MT.SET_ASSET_MANAGERS]: (
    state: IAssetManagerState,
    assetManagers: Paginated<Organisation[]>
  ) => (state.assetManagers = assetManagers),

  [MT.SET_FETCHING_ASSET_MANAGERS]: (
    state: IAssetManagerState,
    flag: boolean
  ) => (state.fetchingAssetManagers = flag),
};

/*--------------------------------------------------/
 * Asset Manager Mutations Tree Interface
 *--------------------------------------------------*/
export interface IAssetManagerMutations
  extends MutationTree<IAssetManagerState> {
  [MT.SET_ASSET_MANAGERS](
    state: IAssetManagerState,
    assetManagers: Paginated<Organisation[]>
  ): void;

  [MT.SET_FETCHING_ASSET_MANAGERS](
    state: IAssetManagerState,
    flag: boolean
  ): void;
}
