import { FiduciaryActions } from './actions';
import { FiduciaryGetters } from './getters';
import { FiduciaryMutations } from './mutations';
import { FiduciaryState, IFiduciaryState } from './state';
import { IRootState } from '@/store';
import { Module } from 'vuex';

export const FiduciaryModuleStore: IFiduciaryModuleStore = {
  state: FiduciaryState,
  actions: FiduciaryActions,
  getters: FiduciaryGetters,
  mutations: FiduciaryMutations,
};

/**
 * --------------------------------------------------
 *	Fiduciary Store Interface
 * --------------------------------------------------
 **/
export type IFiduciaryModuleStore = Module<IFiduciaryState, IRootState>;
