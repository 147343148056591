import { IConsultantState } from './state';
import { MutationTree } from 'vuex';
import * as MT from './mutationsTypes';
import { Paginated } from '@/types';
import Organisation from '@/models/Organisation';

/*--------------------------------------------------/
 * Consultant Mutations
 *--------------------------------------------------*/
export const ConsultantMutations: IConsultantMutations = {
  [MT.SET_CONSULTANTS]: (
    state: IConsultantState,
    consultants: Paginated<Organisation[]>
  ) => (state.consultants = consultants),

  [MT.SET_FETCHING_CONSULTANTS]: (state: IConsultantState, flag: boolean) =>
    (state.fetchingConsultants = flag),
};

/*--------------------------------------------------/
 * Consultant Mutations Tree Interface
 *--------------------------------------------------*/
export interface IConsultantMutations extends MutationTree<IConsultantState> {
  [MT.SET_CONSULTANTS](
    state: IConsultantState,
    consultants: Paginated<Organisation[]>
  ): void;

  [MT.SET_FETCHING_CONSULTANTS](state: IConsultantState, flag: boolean): void;
}
