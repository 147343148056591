
import { Component, Vue } from 'vue-property-decorator';
import AutocompleteField from '@/components/ui/AutocompleteField.vue';
import { UPDATE_INVESTMENT } from '@/modules/investment/store/actionTypes';
import DatePickerField from '@/components/ui/DatePickerField.vue';
import Investment from '@/models/Investment';
import TextField from '@/components/ui/TextField.vue';
import { DISPLAY_SNACKBAR } from '@/modules/dashboard/store/actionTypes';
import { QCPriorityReason } from '@/utils/InvestmentUtils';
import { FETCH_INVESTMENT } from '@/modules/submission/store/actionTypes';
import FundFilters from '@/modules/qc/components/filters/FundFilters.vue';
import { QCFundFilters } from '@/modules/qc/types';
import QueryUtils, { QueryType } from '@/utils/QueryUtils';
import { CustomizerType } from '@/components/customizer/Customizer';
import { Permissions, userHasPermission } from '@/utils/Permissions';

@Component({
  components: { TextField, DatePickerField, AutocompleteField },
})
export default class PriorityDatesModal extends Vue {
  public loading: boolean = false;
  public investment: Investment | null = null;
  public priorityDate: string | null = null;
  public priorityReason: string | null = null;

  created() {
    this.fetchData();
  }

  public async update() {
    this.loading = true;
    try {
      const res = await this.$store.dispatch(UPDATE_INVESTMENT, {
        id: this.investment?.id,
        request: {
          qc_priority_date: this.priorityDate,
          qc_priority_reason: this.priorityReason,
        },
      });
      if (res) {
        await this.$store.dispatch(DISPLAY_SNACKBAR, {
          color: 'success',
          text: 'Priority date and reason updated successfully',
        });
        await this.redirectToQCList();
      }
    } catch (e) {
      await this.$store.dispatch(DISPLAY_SNACKBAR, {
        color: 'error',
        text: e,
      });
    } finally {
      this.loading = false;
    }
  }

  public async fetchData() {
    this.loading = true;
    const investmentId = this.$route?.query?.['investmentId'] ?? '';
    if (!investmentId) {
      await this.redirectToQCList();
      return;
    }

    try {
      await this.$store.dispatch(FETCH_INVESTMENT, {
        id: investmentId,
        include: 'entity,timePeriod',
      });
      this.investment = this.$store.getters.investment;
      this.setInitialValues();
    } catch (e) {
      await this.$store.dispatch(DISPLAY_SNACKBAR, {
        color: 'error',
        text: e.message,
      });
    } finally {
      this.loading = false;
    }
  }

  public setInitialValues() {
    this.priorityDate = this.currentPriorityDate;
    this.priorityReason = this.investment?.meta?.qc_priority_reason;
  }

  public get clientName() {
    return this.client?.name;
  }

  public get timePeriodRange() {
    return this.investment?.timePeriod?.range;
  }

  public get client() {
    return {
      name: this.investment?.entity?.name,
      id: this.investment?.entity_id,
    };
  }

  public get priorityReasons() {
    return Object.values(QCPriorityReason);
  }

  public get currentPriorityDate() {
    return this.investment?.meta?.qc_priority_date;
  }

  public get currentPriorityReason() {
    const slug = this.investment?.meta
      ?.qc_priority_reason as keyof typeof QCPriorityReason;
    return QCPriorityReason[slug]?.name ?? '';
  }

  public get userHasUpdatePermission() {
    return userHasPermission(Permissions.editFunds);
  }

  public async redirectToQCList() {
    const filtersToApply: Partial<Record<keyof QCFundFilters, string>> = {
      pensionSchemes: this.investment?.entity_id ?? '',
      timePeriods: this.investment?.timePeriod?.id ?? '',
    };
    QueryUtils.setQueryValueForType(CustomizerType.QC, filtersToApply);
    await this.$router.push({
      name: 'qc.list',
    });
  }
}
