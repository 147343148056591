import { QueryType } from '@/utils/QueryUtils';
import { Utils } from '@/utils/Utils';
import { groupBy, isDate, isEmpty, merge } from 'lodash';
import {
  FilterAttribute,
  FilterBuilder,
  FilterBuilderSchema,
  FilterConjunction,
  FilterOperator,
  FilterOperatorSlug,
  FilterOperatorSlugs,
  FilterPlan,
  FilterSet,
  FilterSetData,
} from './types';

export class Filter {
  public static make(builder: Partial<FilterBuilder>): FilterBuilder {
    return merge({}, { ...builder }, { ...Filter.makeDefaultSchema() });
  }

  public static toQuery(
    plan: FilterPlan,
    isAppUrlQuery = false
  ): Record<string, any> {
    const params = new Map();

    if (!isEmpty(plan?.filterSets)) {
      params.set('conjunction', plan?.conjunction?.toLowerCase());
    }

    const filterGroups = groupBy(plan.filterSets, 'slug');

    Object.keys(filterGroups).map((groupKey) => {
      const groupValues = filterGroups[groupKey];

      if (Array?.isArray(groupValues) && groupValues[0]?.includeSetAsArray) {
        groupValues[0].data = this.reduceMultipleGroupValues(groupValues);
        groupValues.splice(1);
      }
      const key = `filter[${groupKey}]`;
      const value = Filter.resolveFilterGroupValue(groupValues, isAppUrlQuery);

      params.set(key, value);
    });

    return Object.fromEntries(params);
  }

  private static reduceMultipleGroupValues(groupValues: FilterSet[]) {
    const reducedValues = groupValues?.reduce((acc: any, cur: any) => {
      const groupDataValue = cur?.data;
      const valueToInclude = Array.isArray(groupDataValue)
        ? groupDataValue
        : [groupDataValue];
      return [...valueToInclude, ...acc];
    }, []);
    const reducedValueSet = new Set(reducedValues);
    return [...reducedValueSet];
  }

  public static rePlan(
    builder: FilterBuilder | null = null,
    plan: FilterPlan
  ): FilterBuilder | null {
    if (!builder) {
      return null;
    }

    return merge(
      {},
      {
        schema: builder.schema,
        ...plan,
      }
    );
  }

  public static resolveFilterGroupValue(
    groupValues: FilterSet[],
    resolveValueForAppUrl: boolean = false
  ) {
    // For app route url all filter info (operator,values) is required
    // Reset append operator or value as array when fetching data for app url query
    const mappedValues = groupValues.map((fs) =>
      Filter.makeFilterValue(
        fs?.data,
        fs.operator,
        resolveValueForAppUrl ? true : fs.appendOperatorToValue,
        resolveValueForAppUrl ? false : fs.includeSetAsArray
      )
    );

    const hasOnlyOneValue = mappedValues.length === 1;
    return hasOnlyOneValue ? mappedValues[0] : mappedValues;
  }

  private static makeFilterValue(
    value: FilterSetData | null,
    operator: FilterOperatorSlug | null = 'isEqualTo',
    appendOperatorToValue: boolean = true,
    includeSetAsArray: boolean = false,
    separator = ':'
  ): string | FilterSetData | null {
    operator = operator ?? 'isEqualTo';

    if (Array.isArray(value) && !includeSetAsArray) {
      value = value.join(',');
    }

    const prefix = appendOperatorToValue
      ? `${operator.toLowerCase()}${separator}`
      : '';

    if (Array.isArray(value)) {
      return (value as string[])?.map((val: any) => `${prefix}${val}`);
    }

    return `${prefix}${value ?? null}`;
  }

  public static makeDefaultSchema(): FilterBuilder {
    return {
      conjunction: Filter.getDefaultConjunction(),
      schema: {
        availableAttributes: Filter.getDefaultAvailableAttributes(),
        availableOperators: Filter.getDefaultAvailableOperators(),
        availableConjunctions: Filter.getDefaultAvailableConjunctions(),
      },
      filterSets: [],
    };
  }

  public static getDefaultConjunction(): FilterConjunction {
    return 'AND';
  }

  static getDefaultAvailableConjunctions(): FilterConjunction[] {
    return Array.from(['AND', 'OR']);
  }

  public static getDefaultAvailableOperators(): FilterOperator[] {
    return Array.from([
      {
        name: 'Is empty',
        slug: 'isEmpty',
        options: {
          ignoreValue: true,
        },
      },
      {
        name: 'Is not empty',
        slug: 'isNotEmpty',
        options: {
          ignoreValue: true,
        },
      },
      {
        name: 'Is equal to',
        slug: 'isEqualTo',
        options: { disabledFor: ['list'] },
      },
      {
        name: 'Is not equal to',
        slug: 'isNotEqualTo',
        options: { disabledFor: ['list'] },
      },
      {
        name: 'Contains',
        slug: 'contains',
        options: { enabledFor: ['text'] },
      },
      {
        name: 'Does not contain',
        slug: 'doesNotContain',
        options: { enabledFor: ['text'] },
      },
      {
        name: 'Is all of',
        slug: 'isAllOf',
        options: { enabledFor: ['list'] },
      },
      {
        name: 'Is any of',
        slug: FilterOperatorSlugs.IS_ANY_OF,
        options: { enabledFor: ['list'] },
      },
      {
        name: 'Is none of',
        slug: 'isNoneOf',
        options: { enabledFor: ['list'] },
      },
      {
        name: 'Less than',
        slug: 'lessThan',
        options: { enabledFor: ['number', 'date'] },
      },
      {
        name: 'Less than or equal to',
        slug: 'lessThanOrEqualTo',
        options: { enabledFor: ['number', 'date'] },
      },
      {
        name: 'More than or equal to',
        slug: 'moreThanOrEqualTo',
        options: { enabledFor: ['number', 'date'] },
      },
      {
        name: 'More than',
        slug: 'moreThan',
        options: { enabledFor: ['number', 'date'] },
      },
    ]);
  }

  static getDefaultAvailableAttributes(): FilterAttribute[] {
    return [];
  }

  // converts key-value query to filter plan
  public static resolvePlanFromQuery(
    query: Record<string, any>,
    filterSchema: FilterBuilder | null = null
  ): FilterPlan {
    return {
      filterSets: this.resolveFilterSetsFromQuery(query, filterSchema),
      conjunction: query?.conjunction,
    };
  }

  // maps all filter queries to filter set
  private static resolveFilterSetsFromQuery(
    query: Record<string, any>,
    filterSchema: FilterBuilder | null = null
  ): FilterSet[] {
    const filterKeysPresentInQuery = Object.keys(query)?.filter((it) =>
      it?.includes(QueryType.FILTER)
    );
    const filterSet: any = [];

    filterKeysPresentInQuery?.forEach((key) => {
      filterSet.push(
        ...this.resolveFilterSetValueFromQuery(query, key, filterSchema)
      );
    });

    return filterSet;
  }

  // converts string/array value to FilterSet
  private static resolveFilterSetValueFromQuery(
    query: Record<string, any>,
    key: string,
    filterSchema: FilterBuilder | null = null
  ): FilterSet[] {
    const filterSet: any = [];
    let keyValue = query[key];

    const slug = key?.replaceAll(/(filter|\[|\])/gi, ''); // assigns filter key;
    const attributeSchema =
      filterSchema?.schema?.availableAttributes?.find(
        (av) => av?.slug === slug
      ) ?? null;

    const includeSetAsArray =
      attributeSchema?.options?.includeSetAsArray ?? false;

    if (!Array.isArray(keyValue)) {
      keyValue = [keyValue];
    }

    keyValue?.forEach((value: string) => {
      const splitValue = value?.split(':');
      filterSet.push({
        id: Utils.getRandomIdentifier(),
        slug,
        operator: this.resolveOperator(splitValue[0] ?? null),
        data: this.resolveDataValue(splitValue[1] ?? null, includeSetAsArray),
        appendOperatorToValue:
          attributeSchema?.options?.appendOperatorToValue ?? true,
        includeSetAsArray,
      });
    });

    return filterSet;
  }

  private static resolveDataValue(
    value: any,
    includeSetAsArray: boolean = true
  ): any {
    if (value === 'null') {
      // case when operator is isEmpty, isNotEmpty
      return JSON.parse(value);
    }

    if (includeSetAsArray && !Array.isArray(value)) {
      return value?.split(',');
    }

    return value;
  }

  // resolves lowercase operator value to exact slug available in getDefaultAvailableOperators
  private static resolveOperator(operator: string = ''): string {
    const availableOperatorForGivenSlug =
      this.getDefaultAvailableOperators()?.find(
        (op) => op?.slug?.toLowerCase() === operator?.toLowerCase()
      );

    return `${availableOperatorForGivenSlug?.slug}`;
  }
}
