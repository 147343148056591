import { ResourceTagActions } from './actions';
import { ResourceTagGetters } from './getters';
import { ResourceTagMutations } from './mutations';
import { IResourceTagState, ResourceTagState } from './state';
import { IRootState } from '@/store';
import { Module } from 'vuex';

export const ResourceTagModuleStore: IResourceTagModuleStore = {
  state: ResourceTagState,
  actions: ResourceTagActions,
  getters: ResourceTagGetters,
  mutations: ResourceTagMutations,
};

/**
 * --------------------------------------------------
 *  Resource Tag Store Interface
 * --------------------------------------------------
 **/
export type IResourceTagModuleStore = Module<IResourceTagState, IRootState>;
