import { IInvestmentState } from './state';
import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { Paginated } from '@/types';
import { FundFilters, FundInvestment } from '../types';
import Fund from '@/models/Fund';
import FundNature from '@/models/FundNature';
import Strategy from '@/models/Strategy';
import Template from '@/models/Template';
import TimePeriod from '@/models/TimePeriod';
import ProductType from '@/models/ProductType';

/**
 * --------------------------------------------------
 *  Investment Getters
 * --------------------------------------------------
 **/
export const InvestmentGetters: IInvestmentGetters = {
  fundInvestments: (
    state: IInvestmentState
  ): Paginated<FundInvestment[]> | null => state.fundInvestments,

  fetchingInvestments: (state: IInvestmentState): boolean =>
    state.fetchingInvestments,

  subFunds: (state: IInvestmentState): Fund[] | null => state.subFunds,

  fundNatures: (state: IInvestmentState): FundNature[] | null =>
    state.fundNatures,

  strategies: (state: IInvestmentState): Strategy[] | null => state.strategies,

  templates: (state: IInvestmentState): Template[] | null => state.templates,

  timePeriods: (state: IInvestmentState): TimePeriod[] | null =>
    state.timePeriods,

  productTypes: (state: IInvestmentState): ProductType[] | null =>
    state.productTypes,

  appliedInvestmentFilters: (state: IInvestmentState): FundFilters | null =>
    state.appliedInvestmentFilters,
};

/**
 * --------------------------------------------------
 *  Investment Getters Interface
 * --------------------------------------------------
 **/
export interface IInvestmentGetters
  extends GetterTree<IInvestmentState, IRootState> {
  fundInvestments(state: IInvestmentState): Paginated<FundInvestment[]> | null;

  fetchingInvestments(state: IInvestmentState): boolean;

  subFunds(state: IInvestmentState): Fund[] | null;

  fundNatures(state: IInvestmentState): FundNature[] | null;

  strategies(state: IInvestmentState): Strategy[] | null;

  templates(state: IInvestmentState): Template[] | null;

  timePeriods(state: IInvestmentState): TimePeriod[] | null;

  productTypes(state: IInvestmentState): ProductType[] | null;

  appliedInvestmentFilters(state: IInvestmentState): FundFilters | null;
}
