import { IBenchmarkingState } from './state';
import { MutationTree } from 'vuex';
import * as MT from './mutationsTypes';
import { Paginated } from '@/types';
import { ResourceBenchmarkingItem } from '@/components/resource/types';

/*--------------------------------------------------/
 * Benchmarking Mutations
 *--------------------------------------------------*/
export const BenchmarkingMutations: IBenchmarkingMutations = {
  [MT.SET_BENCHMARKING_ITEMS]: (
    state: IBenchmarkingState,
    benchmarkingItems: Paginated<ResourceBenchmarkingItem>
  ) => (state.benchmarkingItems = benchmarkingItems),
};

/*--------------------------------------------------/
 * Benchmarking Mutations Tree Interface
 *--------------------------------------------------*/
export interface IBenchmarkingMutations
  extends MutationTree<IBenchmarkingState> {
  [MT.SET_BENCHMARKING_ITEMS](
    state: IBenchmarkingState,
    benchmarkingItems: Paginated<ResourceBenchmarkingItem>
  ): void;
}
