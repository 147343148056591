import { TARGET_ENTITIES } from '@/utils/TargetEntity';
import { RouteConfig } from 'vue-router';
const AssetManagerModule = () =>
  import(/* webpackChunkName: 'asset-manager' */ './AssetManagerModule.vue');
const ListAssetManagersPage = () =>
  import(
    /* webpackChunkName: 'asset-manager' */ './pages/ListAssetManagersPage.vue'
  );

export const AssetManagerRoutes: RouteConfig[] = [
  {
    path: 'asset-managers',
    component: AssetManagerModule,
    meta: {
      middlewares: {
        canAccessEntity: TARGET_ENTITIES.listAssetManagers,
      },
    },
    children: [
      {
        path: 'list',
        name: 'asset_managers.list',
        component: ListAssetManagersPage,
      },
    ],
  },
];
