import Organisation from '@/models/Organisation';
import { Paginated } from '@/types';

export type IConsultantState = {
  consultants: Paginated<Organisation[]> | null;
  fetchingConsultants: boolean;
};

export const ConsultantState: IConsultantState = {
  consultants: null,
  fetchingConsultants: false,
};
