import { IBenchmarkingState } from './state';
import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { Paginated } from '@/types';
import { ResourceBenchmarkingItem } from '@/components/resource/types';

/**
 * --------------------------------------------------
 *  Benchmarking Getters
 * --------------------------------------------------
 **/
export const BenchmarkingGetters: IBenchmarkingGetters = {
  benchmarkingItems: (
    state: IBenchmarkingState
  ): Paginated<ResourceBenchmarkingItem> | null => state.benchmarkingItems,
};

/**
 * --------------------------------------------------
 *  Benchmarking Getters Interface
 * --------------------------------------------------
 **/
export interface IBenchmarkingGetters
  extends GetterTree<IBenchmarkingState, IRootState> {
  benchmarkingItems(
    state: IBenchmarkingState
  ): Paginated<ResourceBenchmarkingItem> | null;
}
