import { IRootState } from '@/store';
import { ModuleTree } from 'vuex';
import { AuthModuleStore } from './auth/store';
import { DashboardModuleStore } from './dashboard/store';
import { UserModuleStore } from './user/store';
import { AnalysisModuleStore } from './analysis/store';
import { ConsultantModuleStore } from './consultant/store';
import { PensionSchemeModuleStore } from './pension-scheme/store';
import { ClientModuleStore } from './client/store';
import { InvestmentModuleStore } from './investment/store';
import { AssetManagerModuleStore } from './asset-manager/store';
import { SubmissionModuleStore } from './submission/store';
import { TagModuleStore } from '@/modules/tag/store';
import { ResourceTagModuleStore } from '@/modules/resource-tag/store';
import { BenchmarkingModuleStore } from '@/modules/benchmarking/store';
import { FiduciaryModuleStore } from '@/modules/fiduciary/store';
import { AdminUserModuleStore } from '@/modules/admin-user/store';
import { RenewalsModuleStore } from './renewal/store';
import { QCModuleStore } from '@/modules/qc/store';

/**
 * --------------------------------------------------
 *  Application Modules
 * --------------------------------------------------
 **/
export const AppModules: ModuleTree<IRootState> = {
  AuthModuleStore,
  DashboardModuleStore,
  UserModuleStore,
  AnalysisModuleStore,
  ConsultantModuleStore,
  PensionSchemeModuleStore,
  ClientModuleStore,
  InvestmentModuleStore,
  AssetManagerModuleStore,
  SubmissionModuleStore,
  TagModuleStore,
  ResourceTagModuleStore,
  BenchmarkingModuleStore,
  FiduciaryModuleStore,
  AdminUserModuleStore,
  RenewalsModuleStore,
  QCModuleStore,
};
