import { RenewalActions } from './actions';
import { RenewalGetters } from './getters';
import { RenewalMutations } from './mutations';
import { RenewalState, IRenewalState } from './state';
import { IRootState } from '@/store';
import { Module } from 'vuex';

export const RenewalsModuleStore: IRenewalModuleStore = {
  state: RenewalState,
  actions: RenewalActions,
  getters: RenewalGetters,
  mutations: RenewalMutations,
};

/**
 * --------------------------------------------------
 *	PensionScheme Store Interface
 * --------------------------------------------------
 **/
export type IRenewalModuleStore = Module<IRenewalState, IRootState>;
