import { BenchmarkingStatusSlug } from '@/components/resource/types';
import { ButtonItem } from '@/modules/investment/types';
import { isArray } from 'lodash';
import { Utils } from './Utils';

export enum SubmissionStatusMapping {
  EXPOSED = 'EXPOSED',
  COMPLETED = 'UNDER REVIEW',
  DRAFT = 'DRAFT',
  FAILED = 'FAILED',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
  SUBMITTED = 'SUBMITTED',
  FAILED_BY_DATA_TEAM = 'FAILED BY DATA TEAM',
  PRODUCT_ADDED = 'PRODUCT ADDED',
  REQUEST_SENT = 'REQUEST SENT',
  MULTIPLE_STATUSES = 'MULTIPLE STATUSES',
  ERROR = 'ERROR',
  EXPOSED_WITHOUT_REVIEW = 'EXPOSED WITHOUT REVIEW',
  PROCESSING = 'PROCESSING',
}

export enum RollUpStatusMapping {
  PENDING = 'pending',
  STARTED = 'started',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export const REMOVE_ALL_ITEMS_SLUG = 'remove_all';
export const REMOVE_ALL_ITEMS_LABEL = 'Remove All';

export const RemoveAllItem = {
  id: REMOVE_ALL_ITEMS_SLUG,
  name: REMOVE_ALL_ITEMS_LABEL,
};

export default class InvestmentUtils {
  static getStatusColor(status?: string): string {
    let color = '';
    switch (status) {
      case SubmissionStatusMapping.EXPOSED:
        color = 'success';
        break;
      case SubmissionStatusMapping.COMPLETED:
        color = 'teal';
        break;
      case SubmissionStatusMapping.FAILED:
      case SubmissionStatusMapping.FAILED_BY_DATA_TEAM:
      case SubmissionStatusMapping.REJECTED:
      case SubmissionStatusMapping.EXPIRED:
      case SubmissionStatusMapping.ERROR:
        color = 'error';
        break;
      case SubmissionStatusMapping.DRAFT:
      case SubmissionStatusMapping.SUBMITTED:
        color = 'info';
        break;
      case SubmissionStatusMapping.PRODUCT_ADDED:
        color = 'amber';
        break;
      case SubmissionStatusMapping.MULTIPLE_STATUSES:
      case SubmissionStatusMapping.PROCESSING:
        color = 'purple';
        break;
      default:
        color = 'grey';
        break;
    }
    return color;
  }

  static getPriorityColor(priority?: string): string {
    let color = '';
    priority = priority?.toLowerCase();
    switch (priority) {
      case 'high':
        color = 'error';
        break;
      case 'medium':
        color = 'orange';
        break;
      case 'low':
        color = 'success';
        break;
    }
    return color;
  }

  static getBenchmarkingStatusColor(status?: string): string {
    let color = '';
    switch (status) {
      case BenchmarkingStatusSlug.CLEARGLASS_TO_REVIEW:
        color = 'amber';
        break;
      case BenchmarkingStatusSlug.MCLAGAN_TO_REVIEW:
        color = 'indigo';
        break;
      case BenchmarkingStatusSlug.GOOD_FOR_BENCHMARKING:
        color = 'green';
        break;
      case BenchmarkingStatusSlug.BENCHMARKING_OUTLIER:
        color = '#FF7F50';
        break;
      case BenchmarkingStatusSlug.DATA_OK_BUT_NOT_BENCHMARKABLE:
        color = 'deep-purple';
        break;
      case BenchmarkingStatusSlug.PARTIAL_PERIOD:
        color = 'warning';
        break;
      case BenchmarkingStatusSlug.DATA_ERROR:
        color = 'error';
        break;
      default:
        color = 'grey';
    }
    return color;
  }

  static compareValues = (
    valueA: any,
    valueB: any,
    _rowA: any,
    _rowB: any
  ): number => {
    // Null case
    const nullData = [null, undefined];
    if (nullData?.includes(valueA)) {
      return 1;
    }
    if (nullData?.includes(valueB)) {
      return -1;
    }

    // Array case
    if (isArray(valueA) && valueA?.length && (valueA[0] as ButtonItem)?.label) {
      const lowerCaseValueA = valueA[0]?.label?.toLowerCase();
      const lowerCaseValueB = valueB[0]?.label?.toLowerCase();
      return lowerCaseValueA?.localeCompare(lowerCaseValueB);
    }

    // String case
    if (typeof valueA === 'string' && typeof valueB === 'string') {
      const lowerCaseValueA = valueA?.toLowerCase();
      const lowerCaseValueB = valueB?.toLowerCase();
      return lowerCaseValueA?.localeCompare(lowerCaseValueB);
    }

    // Object case
    if (typeof valueA === 'object' && typeof valueB === 'object') {
      const lowerCaseValueA = (
        valueA as { value: string }
      )?.value?.toLowerCase();
      const lowerCaseValueB = valueB?.value?.toLowerCase();
      return lowerCaseValueA?.localeCompare(lowerCaseValueB);
    }

    // Default case
    return valueA - valueB;
  };
}

export enum ExportType {
  TEMPLATES = 'bulk_template_download',
  FUNDS = 'export_funds',
  RENEWALS = 'export_client_renewals',
}

export const RollupTags = {
  SELF: 'self',
  CHILDREN: 'children',
  AGGREGATE: 'aggregate',
};

export const InvestmentPeriodTags = {
  SUBSCRIBED: {
    id: 'subscribed',
    name: 'Subscribed',
    slug: 'subscribed',
  },
  REDEEMED: {
    id: 'redeemed',
    name: 'Redeemed',
    slug: 'redeemed',
  },
  SUBSCRIBED_REDEEMED: {
    id: 'subscribed_redeemed',
    name: 'Subscribed - Redeemed',
    slug: 'subscribed_redeemed',
  },
  NONE: {
    id: 'none',
    name: 'None',
    slug: 'none',
  },
};

export const AnnualizationTags = {
  ANNUALISED: {
    id: 'annualised',
    name: 'Annualised',
    slug: 'annualised',
  },
  DEANNUALISED: {
    id: 'deannualised',
    name: 'De-annualised',
    slug: 'deannualised',
  },
  ANNUALISED_DEANNUALISED: {
    id: 'annualised_deannualised',
    name: 'Annualised-And-De-annualised',
    slug: 'annualised_deannualised',
  },
  NONE: {
    id: 'none',
    name: 'None',
    slug: 'none',
  },
};

export const QCStatuses: Record<string, any> = {
  READY_FOR_QC: {
    id: 'READY_FOR_QC',
    name: 'READY FOR QC',
  },
  IN_PROGRESS: {
    id: 'IN_PROGRESS',
    name: 'IN PROGRESS',
  },
  QC_DONE: {
    id: 'QC_DONE',
    name: 'QC DONE',
  },
  NEED_RESOLUTION: {
    id: 'NEED_RESOLUTION',
    name: 'NEED RESOLUTION',
  },
  QC_ABANDONED: {
    id: 'QC_ABANDONED',
    name: 'QC ABANDONED',
  },
};

export const QCPriorityReason = {
  delivery_forecast: {
    id: 'delivery_forecast',
    name: 'Delivery forecast',
  },
  collection_target: {
    id: 'collection_target',
    name: 'Collection target',
  },
  delivery_target: {
    id: 'delivery_target',
    name: 'Delivery target',
  },
  prioritised_by_cs: {
    id: 'prioritised_by_cs',
    name: 'Prioritised by CS',
  },
  requested_by_client: {
    id: 'requested_by_client',
    name: 'Requested by client',
  },
  trustee_meeting: {
    id: 'trustee_meeting',
    name: 'Trustee meeting',
  },
  required_for_benchmarking: {
    id: 'required_for_benchmarking',
    name: 'Required for benchmarking',
  },
} as const;

export const InvestmentStatuses: Record<string, any> = {
  UNDER_REVIEW: {
    id: 'UNDER_REVIEW',
    name: 'UNDER REVIEW',
  },
  DRAFT: {
    id: 'DRAFT',
    name: 'DRAFT',
  },
  EXPOSED: {
    id: 'EXPOSED',
    name: 'EXPOSED',
  },
  FAILED: {
    id: 'FAILED',
    name: 'FAILED',
  },
  CANCELLED: {
    id: 'CANCELLED',
    name: 'CANCELLED',
  },
  EXPIRED: {
    id: 'EXPIRED',
    name: 'EXPIRED',
  },
  MULTIPLE_STATUS: {
    id: 'MULTIPLE_STATUS',
    name: 'MULTIPLE STATUS',
  },
  PRODUCT_ADDED: {
    id: 'PRODUCT_ADDED',
    name: 'PRODUCT ADDED',
  },
  REJECTED: {
    id: 'REJECTED',
    name: 'REJECTED',
  },
  REQUEST_SENT: {
    id: 'REQUEST_SENT',
    name: 'REQUEST SENT',
  },
};

export const getQCStatusColor = (status: string) => {
  let color = null;
  switch (status.toUpperCase()) {
    case QCStatuses.READY_FOR_QC.id:
      color = 'blue lighten-1';
      break;
    case QCStatuses.IN_PROGRESS.id:
      color = 'purple darken-2';
      break;
    case QCStatuses.QC_DONE.id:
      color = 'green';
      break;
    case QCStatuses.NEED_RESOLUTION.id:
      color = 'orange';
      break;
    default:
      color = 'grey';
      break;
  }

  return color;
};

export const getQCPriorityDateColor = ({
  qcStatus,
  qcPriorityReason,
  qcPriorityDate,
}: {
  qcStatus: string;
  qcPriorityDate: string;
  qcPriorityReason?: keyof typeof QCPriorityReason;
}) => {
  const defaultPriorityColor = 'white grey--text text--darken-2';
  const mediumPriorityColor = 'orange lighten-5 orange--text';
  const highPriorityColor = 'red lighten-5 red--text';
  if ([QCStatuses.QC_ABANDONED.id, QCStatuses.QC_DONE.id].includes(qcStatus)) {
    // For all the QC items that are closed, return the default color and don't highlight the item.
    return defaultPriorityColor;
  }
  if (
    qcPriorityReason &&
    qcPriorityReason === QCPriorityReason.delivery_forecast.id
  ) {
    // For all the items that have a delivery forecast reason, return the default color and don't highlight the item.
    return defaultPriorityColor;
  }
  if (
    qcPriorityReason &&
    qcPriorityReason !== QCPriorityReason.delivery_target.id &&
    qcPriorityReason !== QCPriorityReason.collection_target.id
  ) {
    // For all the items that don't have a delivery target or a collection target reason,
    // highlight it as a high priority item.
    return highPriorityColor;
  }

  // This is the case wherein the qc items:
  // 1) Don't have a priority reason or
  // 2) The priority reason is delivery target or
  // 3) The priority reason is collection target.
  const differenceInDays = Utils.differenceInDaysFromNow(qcPriorityDate);
  if (differenceInDays < 0) {
    return highPriorityColor;
  }
  if (differenceInDays < 6) {
    return mediumPriorityColor;
  }
  return defaultPriorityColor;
};
