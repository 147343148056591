import { ClientActions } from './actions';
import { ClientGetters } from './getters';
import { ClientMutations } from './mutations';
import { ClientState, IClientState } from './state';
import { IRootState } from '@/store';
import { Module } from 'vuex';

export const ClientModuleStore: IClientModuleStore = {
  state: ClientState,
  actions: ClientActions,
  getters: ClientGetters,
  mutations: ClientMutations,
};

/**
 * --------------------------------------------------
 *	Client Store Interface
 * --------------------------------------------------
 **/
export type IClientModuleStore = Module<IClientState, IRootState>;
