import { Filter } from '@/components/filter/Filter';
import { FilterPlan, FilterSet } from '@/components/filter/types';
import { Sort } from '@/components/sort/Sort';
import { SortPlan } from '@/components/sort/types';
import { ModelHasResource } from '@/models/Common';
import { ResourceTag } from '@/models/ResourceTag';
import { HTTPServiceClient } from '@/packages/http/service';
import { APIErrorResponse } from '@/packages/http/types';
import {
  Paginated,
  PaginatedParams,
  Resource,
  ResourceCollection,
} from '@/types';
import * as t from './endpoints';
import { SetResourceTagData } from './types';

/**
 * --------------------------------------------------
 *	ResourceTag HTTP Serice
 * --------------------------------------------------
 **/
export const ResourceTagService = {
  /**
   * Fetches all the resource tags.
   * @returns {Promise<Paginated<ResourceTag>>}
   */
  async fetchResourceTags(
    props?: PaginatedParams<{
      include?: string[] | null;
      sortPlan?: SortPlan | null;
      filterPlan?: FilterPlan | null;
    }>
  ): Promise<Paginated<ResourceTag>> {
    try {
      const filterParams = {
        ...(props?.filterPlan ? Filter.toQuery(props?.filterPlan) : {}),
      };
      const sortParams = {
        ...(props?.sortPlan ? Sort.toQuery(props?.sortPlan) : {}),
      };

      delete props?.sortPlan;
      delete props?.filterPlan;

      const params = {
        ...props,
        ...sortParams,
        ...filterParams,
      };
      const { data } = await HTTPServiceClient.get<Paginated<ResourceTag>>(
        t.FETCH_RESOURCE_TAGS_ENDPOINT,
        {
          params,
        }
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Set ResourceTags.
   * @returns {Promise<Resource<ResourceTag>>}
   */
  async setResourceTags(
    postData: SetResourceTagData
  ): Promise<ResourceCollection<ResourceTag>> {
    try {
      const { data } = await HTTPServiceClient.post<
        ResourceCollection<ResourceTag>
      >(t.SET_RESOURCE_TAG_ENDPOINT, postData);
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Fetches all the resource tags for the given resource.
   * @returns {Promise<Paginated<ResourceTag>>}
   */
  async fetchTagsForResource(
    params: Required<Pick<ModelHasResource, 'resource_id' | 'resource_type'>>,
    props?: PaginatedParams<{
      include?: string[] | null;
      sortPlan?: SortPlan | null;
      filterPlan?: FilterPlan | null;
    }>
  ): Promise<Paginated<ResourceTag>> {
    const queryFilter: FilterSet = {
      id: `${Date.now()}`,
      slug: 'resource_id',
      operator: 'isEqualTo',
      data: params.resource_id,
    };

    const filterPlan = {
      conjunction: props?.filterPlan?.conjunction ?? 'AND',
      filterSets: [...(props?.filterPlan?.filterSets ?? [])],
    };

    filterPlan.filterSets.push(queryFilter);

    return ResourceTagService.fetchResourceTags({
      ...props,
      filterPlan,
    });
  },
};
