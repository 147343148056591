import { Filter } from '@/components/filter/Filter';
import { FilterPlan } from '@/components/filter/types';
import { Sort } from '@/components/sort/Sort';
import { SortPlan } from '@/components/sort/types';
import { User } from '@/models/User';
import { HTTPServiceClient } from '@/packages/http/service';
import { APIErrorResponse } from '@/packages/http/types';
import { Paginated, PaginatedParams, Resource } from '@/types';
import ErrorUtils from '@/utils/ErrorUtils';
import * as t from './endpoints';
import {
  ImpersonateUserResponse,
  ResetUserPasswordRequest,
  UpdateEmailSettingRequest,
} from './types';

/**
 * --------------------------------------------------
 *	User HTTP Serice
 * --------------------------------------------------
 **/
export const UserService = {
  /**
   * Fetches all the users.
   * @returns {Promise<Paginated<User>>}
   */
  async fetchUsers(
    props?: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filterPlan?: FilterPlan | null;
    }>
  ): Promise<Paginated<User>> {
    try {
      const filterParams = {
        ...(props?.filterPlan ? Filter.toQuery(props?.filterPlan) : {}),
      };
      const sortParams = {
        ...(props?.sortPlan ? Sort.toQuery(props?.sortPlan) : {}),
      };

      delete props?.sortPlan;
      delete props?.filterPlan;

      const params = { ...props, ...sortParams, ...filterParams };
      const { data } = await HTTPServiceClient.get<Paginated<User>>(
        t.FETCH_USERS_ENDPOINT,
        {
          params,
        }
      );
      return data ?? {};
    } catch (e) {
      throw new Error(ErrorUtils.getErrorMessage(e as APIErrorResponse));
    }
  },

  /**
   * Fetches a single the user by ID.
   * @returns {Promise<Paginated<User>>}
   */
  async findById(id: string, params: any = {}): Promise<Resource<User>> {
    try {
      const { data } = await HTTPServiceClient.get<Resource<User>>(
        t.FIND_USER_BY_ID_ENDPOINT(id),
        {
          params,
        }
      );

      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  async impersonateUser(userId: string): Promise<ImpersonateUserResponse> {
    try {
      const { data } = await HTTPServiceClient.get<ImpersonateUserResponse>(
        t.IMPERSONATE_USER_ENDPOINT(userId)
      );

      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  async resetUserPassword(email: string): Promise<Response> {
    try {
      const body = {
        email,
      };

      const { data } = await HTTPServiceClient.post<Response>(
        t.RESET_USER_PASSWORD_ENDPOINT,
        body
      );

      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  async updateUserEmailSettings(
    request: UpdateEmailSettingRequest
  ): Promise<Response> {
    try {
      const body = {
        settings: request?.settings,
      };

      const { data } = await HTTPServiceClient.post<Response>(
        t.UPDATE_EMAIL_SETTINGS_ENDPOINT(request.userId),
        body
      );

      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },
};
