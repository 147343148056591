import { AdminUser } from '@/models/AdminUser';
import { IRootState } from '@/store';
import { ResourceCollection } from '@/types';
import { ActionContext, ActionTree } from 'vuex';
import { AdminUserService } from '../services';
import * as AT from './actionTypes';
import * as MT from './mutationsTypes';
import { IAdminUserState } from './state';

/**
 * --------------------------------------------------
 *  Investment Actions
 * --------------------------------------------------
 **/
export const AdminUserActions: IAdminUserActions = {
  [AT.FETCH_ADMIN_USERS]: async ({ commit }): Promise<AdminUser[]> => {
    return AdminUserService.fetchAdminUsers().then((data) => {
      const adminUsers = data?.data;
      commit(MT.SET_ADMIN_USERS, adminUsers);
      return adminUsers;
    });
  },
};

/**
 * --------------------------------------------------
 *  Admin User Actions Interface
 * --------------------------------------------------
 **/
export interface IAdminUserActions
  extends ActionTree<IAdminUserState, IRootState> {
  [AT.FETCH_ADMIN_USERS](
    context: IAdminUserActionContext
  ): Promise<AdminUser[]>;
}

/**
 * --------------------------------------------------
 *  Admin User Action Context Interface
 * --------------------------------------------------
 **/
export type IAdminUserActionContext = ActionContext<
  IAdminUserState,
  IRootState
>;
