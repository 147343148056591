import { IClientState } from './state';
import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { Paginated } from '@/types';
import Organisation from '@/models/Organisation';
/**
 * --------------------------------------------------
 *	Client Getters
 * --------------------------------------------------
 **/
export const ClientGetters: IClientGetters = {
  clients: (state: IClientState): Paginated<Organisation[]> | null =>
    state.clients,

  fetchingClients: (state: IClientState): boolean => state.fetchingClients,
};

/**
 * --------------------------------------------------
 *	Client Getters Interface
 * --------------------------------------------------
 **/
export interface IClientGetters extends GetterTree<IClientState, IRootState> {
  clients(state: IClientState): Paginated<Organisation[]> | null;

  fetchingClients(state: IClientState): boolean;
}
