import { AuthMiddleware } from '@/router/middlewares/AuthMiddleware';
import { GuestMiddleware } from '@/router/middlewares/GuestMiddleware';
import { MiddlewareMapping } from '@/router/middlewares/types';
import { AuthorizeTargetAccessMiddleware } from '@/router/middlewares/AuthorizeTargetAccessMiddleware';

export const MiddlewareMappings: MiddlewareMapping = {
  requiresAuth: AuthMiddleware,
  requiresGuest: GuestMiddleware,
  canAccessEntity: AuthorizeTargetAccessMiddleware,
};
