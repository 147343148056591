import { ITagState } from './state';
import { MutationTree } from 'vuex';
import * as MT from './mutationsTypes';
import { Tag } from '@/models/Tag';
import { Paginated } from '@/types';

/*--------------------------------------------------/
 * Tag Mutations
 *--------------------------------------------------*/
export const TagMutations: ITagMutations = {
  [MT.SET_TAGS]: (state: ITagState, tags: Paginated<Tag>) =>
    (state.tags = tags),
  [MT.SET_QC_TAGS]: (state: ITagState, qcTags: Paginated<Tag>) =>
    (state.qcTags = qcTags),
  [MT.SET_QC_TAGS_ARE_LOADING]: (state: ITagState) =>
    (state.areQCTagsLoading = true),
  [MT.SET_QC_TAGS_ARE_NOT_LOADING]: (state: ITagState) =>
    (state.areQCTagsLoading = false),
};

/*--------------------------------------------------/
 * Tag Mutations Tree Interface
 *--------------------------------------------------*/
export interface ITagMutations extends MutationTree<ITagState> {
  [MT.SET_TAGS](state: ITagState, tags: Paginated<Tag>): void;
  [MT.SET_QC_TAGS](state: ITagState, tags: Paginated<Tag>): void;
  [MT.SET_QC_TAGS_ARE_LOADING](state: ITagState): void;
  [MT.SET_QC_TAGS_ARE_NOT_LOADING](state: ITagState): void;
}
