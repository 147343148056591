import { PensionSchemeActions } from './actions';
import { PensionSchemeGetters } from './getters';
import { PensionSchemeMutations } from './mutations';
import { PensionSchemeState, IPensionSchemeState } from './state';
import { IRootState } from '@/store';
import { Module } from 'vuex';

export const PensionSchemeModuleStore: IPensionSchemeModuleStore = {
  state: PensionSchemeState,
  actions: PensionSchemeActions,
  getters: PensionSchemeGetters,
  mutations: PensionSchemeMutations,
};

/**
 * --------------------------------------------------
 *	PensionScheme Store Interface
 * --------------------------------------------------
 **/
export type IPensionSchemeModuleStore = Module<IPensionSchemeState, IRootState>;
