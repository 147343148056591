import { IDashboardState } from '@/modules/dashboard/store/state';
import { IRootState } from '@/store';
import { ActionContext, ActionTree } from 'vuex';
import { DashboardService } from '@/modules/dashboard/services';
import * as AT from './actionTypes';
import * as MT from './mutationsTypes';
import { User } from '@/models/User';
import Snackbar from '../../../models/Snackbar';
import Organisation from '@/models/Organisation';
import OrganisationType from '@/models/OrganisationType';
import Currency from '@/models/Currency';
import Country from '@/models/Country';

/**
 * --------------------------------------------------
 *  Dashboard Actions
 * --------------------------------------------------
 **/
export const DashboardActions: IDashboardActions = {
  [AT.DISPLAY_SNACKBAR]: (
    context: IDashboardActionContext,
    snackbar: Snackbar
  ) =>
    context.commit(MT.SET_SNACKBAR, {
      status: true,
      ...snackbar,
    }),

  [AT.FETCH_CURRENT_USER]: async ({ commit }): Promise<User> => {
    const user = await DashboardService.fetchCurrentUser();
    commit(MT.SET_CURRENT_USER, user);
    return user;
  },

  [AT.FETCH_ORGANIZATIONS]: async ({ commit }): Promise<Organisation[]> => {
    const organizations = await DashboardService.fetchOrganizations();
    commit(MT.SET_ORGANIZATIONS, organizations);
    return organizations;
  },

  [AT.FETCH_ORGANIZATION_TYPES]: async ({
    commit,
  }): Promise<OrganisationType[]> => {
    const organizationTypes = await DashboardService.fetchOrganizationTypes();
    commit(MT.SET_ORGANIZATION_TYPES, organizationTypes);
    return organizationTypes;
  },

  [AT.SAVE_GOOGLE_2FA_DETAILS]: async (
    { commit },
    user_id: string
  ): Promise<User> => {
    const user = await DashboardService.save2FADetails(user_id);
    commit(MT.SET_CURRENT_USER, user);
    return user;
  },

  [AT.FETCH_CURRENCY_LIST]: async ({ commit }): Promise<Currency[]> => {
    const data = await DashboardService.fetchCurrencyList();
    commit(MT.SET_CURRENCY_LIST, data);
    return data;
  },

  [AT.FETCH_COUNTRY_LIST]: async ({ commit }): Promise<Country[]> => {
    const data = await DashboardService.fetchCountryList();
    commit(MT.SET_COUNTRY_LIST, data);
    return data;
  },
};

/**
 * --------------------------------------------------
 *  Dashboard Actions Interface
 * --------------------------------------------------
 **/
export interface IDashboardActions
  extends ActionTree<IDashboardState, IRootState> {
  [AT.DISPLAY_SNACKBAR](
    context: IDashboardActionContext,
    snackbar: Snackbar
  ): void;
  [AT.FETCH_CURRENT_USER](context: IDashboardActionContext): Promise<User>;
  [AT.FETCH_ORGANIZATIONS](
    context: IDashboardActionContext
  ): Promise<Organisation[]>;
  [AT.FETCH_ORGANIZATION_TYPES](
    context: IDashboardActionContext
  ): Promise<OrganisationType[]>;
  [AT.SAVE_GOOGLE_2FA_DETAILS](
    context: IDashboardActionContext,
    user_id: string
  ): Promise<User>;
  [AT.FETCH_CURRENCY_LIST](
    context: IDashboardActionContext
  ): Promise<Currency[]>;
  [AT.FETCH_COUNTRY_LIST](context: IDashboardActionContext): Promise<Country[]>;
}

/**
 * --------------------------------------------------
 *  Dashboard Action Context Interface
 * --------------------------------------------------
 **/
export type IDashboardActionContext = ActionContext<
  IDashboardState,
  IRootState
>;
