export const FETCH_INVESTMENT = 'submission/FETCH_INVESTMENT';
export const FETCH_MANAGER_ASSOCIATION_META =
  'submission/FETCH_MANAGER_ASSOCIATION_META';
export const SUBMIT_DATA = 'submission/SUBMIT_DATA';
export const FETCH_SUBMISSION = 'submission/FETCH_SUBMISSION';
export const CHANGE_SUBMISSION_STATUS = 'submission/CHANGE_SUBMISSION_STATUS';
export const REQUEST_SUBMISSION_REOPEN = 'submission/REQUEST_SUBMISSION_REOPEN';
export const FETCH_SUBMISSION_ERRORS = 'submission/FETCH_SUBMISSION_ERRORS';
export const DOWNLOAD_ERROR_REPORT = 'submission/DOWNLOAD_ERROR_REPORT';
export const STORE_BYPASSED_ERRORS = 'submission/STORE_BYPASSED_ERRORS';
export const FETCH_SUBMISSIONS_FOR_BATCH =
  'submission/FETCH_SUBMISSIONS_FOR_BATCH';
export const DOWNLOAD_ERROR_REPORTS_FOR_BATCH =
  'submission/DOWNLOAD_ERROR_REPORTS_FOR_BATCH';
export const FETCH_ERROR_REPORTS_URL_FOR_BATCH =
  'submission/FETCH_ERROR_REPORTS_URL_FOR_BATCH';
export const FETCH_COST_SUMMARY = 'submission/FETCH_COST_SUMMARY';
export const DOWNLOAD_COST_TEMPLATE = 'submission/DOWNLOAD_COST_TEMPLATE';
export const FETCH_PROVIDED_TEMPLATE_TYPES =
  'submission/FETCH_PROVIDED_TEMPLATE_TYPES';
export const FETCH_TEMPLATE_SOURCES = 'submission/FETCH_TEMPLATE_SOURCES';
export const FETCH_TEMPLATE_RESPONSE_SWIFTNESS =
  'submission/FETCH_TEMPLATE_RESPONSE_SWIFTNESS';
export const FETCH_TEMPLATE_RESOLUTIONS =
  'submission/FETCH_TEMPLATE_RESOLUTIONS';
