import { SortPlan } from '@/components/sort/types';
import { HTTPServiceClient } from '@/packages/http/service';
import { APIErrorResponse } from '@/packages/http/types';
import { Paginated, PaginatedParams } from '@/types';
import ErrorUtils from '@/utils/ErrorUtils';
import { FundFilters } from '@/modules/investment/types';
import { MapFiltersToQuery } from '@/modules/investment/helper';
import * as ep from './endpoints';
import { QCFundInvestment, UpdateQCFundsRequest } from '@/modules/qc/types';
import { Sort } from '@/components/sort/Sort';
import { Comment } from '@/models/Comment';

/**
 * --------------------------------------------------
 *	Investments HTTP Service
 * --------------------------------------------------
 **/
export const QCService = {
  /**
   * Fetches all the fund investments in qc state.
   * @returns {Promise<Paginated<QCFundInvestment>>}
   */
  async fetchQCFunds(
    props?: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filters?: FundFilters | null;
    }>
  ): Promise<Paginated<QCFundInvestment>> {
    try {
      const filterParamString = props?.filters
        ? MapFiltersToQuery(props?.filters)
        : '';

      const sortParams = {
        sort: 'qcPriorityDate,id',
      };

      delete props?.sortPlan;
      delete props?.filters;

      const params = { ...props, ...sortParams };
      const { data } = await HTTPServiceClient.get<Paginated<QCFundInvestment>>(
        ep.FETCH_QC_FUNDS_ENDPOINT(filterParamString),
        {
          params,
        }
      );
      return data ?? {};
    } catch (e) {
      throw new Error(ErrorUtils.getErrorMessage(e as APIErrorResponse));
    }
  },

  async updateQCFunds(request: UpdateQCFundsRequest): Promise<Response> {
    try {
      const { data } = await HTTPServiceClient.post<Response>(
        ep.UPDATE_QC_FUNDS_ENDPOINT,
        request
      );
      return data ?? null;
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Fetches all qc related comments for an investment.
   * @returns {Promise<Paginated<Comment>>}
   */
  async fetchComments(
    investmentId: string,
    props?: PaginatedParams<{
      include?: string[] | null;
      sortPlan?: SortPlan | null;
    }>
  ): Promise<Paginated<Comment>> {
    const sortParams = {
      ...(props?.sortPlan ? Sort.toQuery(props?.sortPlan) : {}),
    };

    delete props?.sortPlan;

    const params = {
      ...props,
      ...sortParams,
    };

    try {
      const { data } = await HTTPServiceClient.get<Paginated<Comment>>(
        ep.FETCH_QC_COMMENTS_ENDPOINT(investmentId),
        { params }
      );
      return data ?? null;
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },
};
