import { Tag } from '@/models/Tag';
import { Paginated } from '@/types';

export type ITagState = {
  tags: Paginated<Tag> | null;
};

export const TagState: ITagState = {
  tags: null,
};
