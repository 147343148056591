import { SortPlan } from '@/components/sort/types';
import { PaginatedParams } from '@/types';
import { ANALYSIS, BASE, REPORTING, SHERLOCK } from '@/utils/service-providers';
import {
  AnalysisFundFilters,
  AnalysisParamWithFilter,
  ClientsParam,
} from './types';
import { isBoolean } from 'lodash';

export const FETCH_PRODUCT_TYPES = `${BASE}/strategies`;
export const FETCH_STRATEGIES = `${BASE}/product-types`;
export const FETCH_TIME_PERIODS = `${BASE}/time-periods`;
export const FETCH_FUND_NATURES = `${BASE}/fund-natures`;
export const FETCH_TEMPLATES = `${BASE}/templates`;
export const FETCH_SUB_FUNDS = `${BASE}/admin/sub-funds?page=1&limit=-1`;

function getFundAnalysisUrl(
  props?: PaginatedParams<{
    sortPlan?: SortPlan | null;
    filters?: AnalysisFundFilters | null;
  }>
): string {
  const urlParams = new URLSearchParams();
  const params = props?.filters;

  if (params?.parentId?.length) {
    params.parentId?.forEach((it) => {
      urlParams.append('parentId[]', it);
    });
  }
  if (params?.parentInvestmentId?.length) {
    params.parentInvestmentId?.forEach((it) => {
      urlParams.append('parentInvestmentId[]', it);
    });
  }

  if (params?.consultants?.length) {
    params.consultants?.forEach((it) => {
      urlParams.append('organizationIds[]', it);
    });
  }

  if (params?.investors?.length) {
    params.investors?.forEach((it) => {
      urlParams.append('organizationIds[]', it);
    });
  }

  if (params?.pensionSchemes?.length) {
    params.pensionSchemes.forEach((it) => {
      urlParams.append('psEntityIds[]', it);
    });
  }

  if (params?.id?.length) {
    urlParams.set('fundIds[]', params.id.toString());
  }

  if (params?.strategies?.length) {
    params.strategies.forEach((it) => {
      urlParams.append('assetClassIds[]', it);
    });
  }

  if (params?.productTypes?.length) {
    params.productTypes.forEach((it) => {
      urlParams.append('fundTypeIds[]', it);
    });
  }

  if (params?.assetManagers?.length) {
    params.assetManagers.forEach((it) => {
      urlParams.append('assetManagerIds[]', it);
    });
  }

  if (params?.timePeriods?.length) {
    params?.timePeriods?.forEach((it) => {
      urlParams.append('reportingPeriodIds[]', it);
    });
  }

  if (params?.subFunds?.length) {
    params.subFunds?.forEach((it) => {
      urlParams.append('sfEntityIds[]', it);
    });
  }

  if (params?.templates?.length) {
    params.templates?.forEach((it) => {
      urlParams.append('templateIds[]', it);
    });
  }

  if (params?.startDate) {
    urlParams.set('requestStartDate', params?.startDate?.toString());
  }

  if (params?.endDate) {
    urlParams.set('requestEndDate', params?.endDate?.toString());
  }

  if (params?.status) {
    urlParams.set('status', params.status.toString());
  }

  if (params?.benchmarkingOptions) {
    urlParams.set(
      'benchmarkingOptions',
      JSON.stringify(params?.benchmarkingOptions)
    );
  }

  if (params?.benchmarkingStatus?.length) {
    params.benchmarkingStatus?.forEach((it) => {
      urlParams.append('benchmarkingStatus[]', it);
    });
  }

  if (params?.benchmarkingSalesStatus?.length) {
    params.benchmarkingSalesStatus?.forEach((it) => {
      urlParams.append('benchmarkingSalesStatus[]', it);
    });
  }

  if (params?.investmentPeriodTags?.length) {
    params.investmentPeriodTags?.forEach((it) => {
      urlParams.append('investmentPeriodTags[]', it);
    });
  }

  if (params?.annualizationTags?.length) {
    params.annualizationTags?.forEach((it) => {
      urlParams.append('annualizationTags[]', it);
    });
  }

  if (params?.entityIds?.length) {
    params.entityIds?.forEach((it) => {
      urlParams.append('entityIds[]', it);
    });
  }

  if (params?.pickedIdentifier?.length) {
    params?.pickedIdentifier?.forEach((it) => {
      urlParams.append('pickedIdentifier[]', it);
    });
  }

  if (params?.internalCode?.length) {
    params?.internalCode?.forEach((it) => {
      urlParams.append('internalCode[]', it);
    });
  }

  if (params?.includeUnderFund) {
    urlParams.set('includeUnderFund', params.includeUnderFund.toString());
  }

  if (isBoolean(params?.includeBenchmarking)) {
    urlParams.set(
      'includeBenchmarking',
      (params?.includeBenchmarking ?? false).toString()
    );
  }

  if (params?.name) {
    urlParams.set('searchKeyword', params.name.toString());
  }
  if (props?.page) {
    urlParams.set('page', props.page.toString());
  }
  if (props?.limit) {
    urlParams.set('limit', props.limit.toString());
  }

  if (
    params?.overrideAumBasedRelativeValue !== null &&
    params?.overrideAumBasedRelativeValue !== undefined
  ) {
    urlParams.set(
      'overrideAumBasedRelativeValue',
      params?.overrideAumBasedRelativeValue?.toString()
    );
  }
  return urlParams.toString();
}

function getInvestmentsUrl(params: ClientsParam): string {
  const urlParams = new URLSearchParams();

  if (params.rootOrgIds?.length) {
    params.rootOrgIds?.forEach((it) => {
      urlParams.append('organizationIds[]', it);
    });
  }
  if (params?.page) {
    urlParams.set('page', params.page.toString());
  }
  if (params?.limit) {
    urlParams.set('limit', params.limit.toString());
  }
  if (params?.entityIds?.length) {
    params.entityIds?.forEach((it) => {
      urlParams.append('filter[entity_id][]', it);
    });
  }
  if (params?.status) {
    urlParams.set('status', params.status.toString());
  }

  urlParams.set('include', params?.include ?? 'entity,timePeriod');

  return urlParams.toString();
}

export const GET_FUND_ANALYSIS = (
  params?: PaginatedParams<{
    sortPlan?: SortPlan | null;
    filters?: AnalysisFundFilters | null;
  }>
) => {
  const url = `${SHERLOCK}/funds/analysis?`;
  const urlParams = getFundAnalysisUrl(params);
  return url + urlParams;
};

export const GET_ROOT_DETAILS = (params: AnalysisParamWithFilter) => {
  return `${BASE}/admin/organizations/root-details`;
};

export const GET_ORG_CLIENTS = (params: AnalysisParamWithFilter) => {
  return `${BASE}/organizations/${params.rootOrgId}/pension-scheme?include=entity,timePeriod,managers`;
};

export const GET_SUB_FUNDS_BY_ORG_IDS = (params: ClientsParam) => {
  params.include = 'entity,timePeriod,parent';
  const urlString = getInvestmentsUrl(params);
  return `${BASE}/admin/sub-funds/investments?` + urlString;
};

export const INVESTMENT_ENDPOINT = (params: ClientsParam) => {
  params.include =
    'entity.organizationType,timePeriod,organizationAssociations.provider.organizationType,' +
    'entity.parentOrganization.organizationType';
  const urlString = getInvestmentsUrl(params);
  return `${BASE}/admin/pension-schemes/investments?` + urlString;
};
export const GENERATE_REPORT = `${BASE}/admin/reports`;
export const DOWNLOAD_REPORT = (jobId: string) => {
  return `${BASE}/admin/reports/${jobId}/download`;
};
export const CTR_REPORT_VERSION_ENDPOINT = `${REPORTING}/ctr-report/reportVersions`;
