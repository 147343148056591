import Auth from '@/packages/Auth';
import { Middleware, MiddlewareNext } from '@/router/middlewares/types';
import { Route } from 'vue-router';

export const AuthMiddleware: Middleware = {
  handle(to: Route, from: Route, next: MiddlewareNext, data?: any) {
    if (!Auth.isAuthenticated()) {
      return next({
        name: 'auth.login',
      });
    }
  },
};
