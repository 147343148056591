import Investment from '@/models/Investment';
import SharedResource from '@/models/SharedResource';
import { TemplateResolution } from '../types';
import { OrganisationAssociationMeta } from '@/models/OrganizationAssociation';

export interface ISubmissionState {
  investment: Investment | null;
  fetchingInvestment: Boolean;
  providedTemplateTypes: SharedResource[] | null;
  templateSources: SharedResource[] | null;
  templateResponseSwiftness: SharedResource[] | null;
  templateResolutions: TemplateResolution[] | null;
  managerAssociationMeta: OrganisationAssociationMeta | null;
}

export const SubmissionState: ISubmissionState = {
  investment: null,
  fetchingInvestment: false,
  providedTemplateTypes: null,
  templateSources: null,
  templateResponseSwiftness: null,
  templateResolutions: null,
  managerAssociationMeta: null,
};
