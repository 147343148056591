import { IAuthState } from '@/modules/auth/store/state';
import { IRootState } from '@/store';
import { GetterTree } from 'vuex';

/**
 * --------------------------------------------------
 *	Auth Getters
 * --------------------------------------------------
 **/
export const AuthGetters: IAuthGetters = {};

/**
 * --------------------------------------------------
 *	Auth Getters Interface
 * --------------------------------------------------
 **/
export type IAuthGetters = GetterTree<IAuthState, IRootState>;
