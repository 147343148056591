import { IRenewalState } from './state';
import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { Paginated } from '@/types';
import RenewalOrganisation from '../../../models/RenewalOrganisation';
import { RenewalFilters } from '../types';
/**
 * --------------------------------------------------
 *	Renewal Getters
 * --------------------------------------------------
 **/
export const RenewalGetters: IRenewalGetters = {
  renewalClients: (
    state: IRenewalState
  ): Paginated<RenewalOrganisation[]> | null => state.renewalClients,

  fetchingRenewalClients: (state: IRenewalState): boolean =>
    state.fetchingRenewalClients,

  appliedRenewalFilters: (state: IRenewalState): RenewalFilters | null =>
    state.appliedRenewalFilters,
};

/**
 * --------------------------------------------------
 *	Renewal Getters Interface
 * --------------------------------------------------
 **/
export interface IRenewalGetters extends GetterTree<IRenewalState, IRootState> {
  renewalClients(state: IRenewalState): Paginated<RenewalOrganisation[]> | null;

  fetchingRenewalClients(state: IRenewalState): boolean;

  appliedRenewalFilters(state: IRenewalState): RenewalFilters | null;
}
