import { ITagState } from './state';
import { MutationTree } from 'vuex';
import * as MT from './mutationsTypes';
import { Tag } from '@/models/Tag';
import { Paginated } from '@/types';

/*--------------------------------------------------/
 * Tag Mutations
 *--------------------------------------------------*/
export const TagMutations: ITagMutations = {
  [MT.SET_TAGS]: (state: ITagState, tags: Paginated<Tag>) =>
    (state.tags = tags),
};

/*--------------------------------------------------/
 * Tag Mutations Tree Interface
 *--------------------------------------------------*/
export interface ITagMutations extends MutationTree<ITagState> {
  [MT.SET_TAGS](state: ITagState, tags: Paginated<Tag>): void;
}
