import { UserActions } from './actions';
import { UserGetters } from './getters';
import { UserMutations } from './mutations';
import { UserState, IUserState } from './state';
import { IRootState } from '@/store';
import { Module } from 'vuex';

export const UserModuleStore: IUserModuleStore = {
  state: UserState,
  actions: UserActions,
  getters: UserGetters,
  mutations: UserMutations,
};

/**
 * --------------------------------------------------
 *	User Store Interface
 * --------------------------------------------------
 **/
export type IUserModuleStore = Module<IUserState, IRootState>;
