import { AdminUser } from '@/models/AdminUser';
import { HTTPServiceClient } from '@/packages/http/service';
import { APIErrorResponse } from '@/packages/http/types';
import { ResourceCollection } from '@/types';
import * as t from './endpoints';

/**
 * --------------------------------------------------
 *	AdminUser HTTP Serice
 * --------------------------------------------------
 **/
export const AdminUserService = {
  /**
   * Fetches all the admin users.
   * @returns {Promise<ResourceCollection<AdminUser>>}
   */
  async fetchAdminUsers(): Promise<ResourceCollection<AdminUser>> {
    try {
      const { data } = await HTTPServiceClient.get<
        ResourceCollection<AdminUser>
      >(t.FETCH_ADMIN_USERS_ENDPOINT);
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },
};
