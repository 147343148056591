import Auth from '@/packages/Auth';
import { HttpClient, IHttpClient } from '@/packages/http/client';
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import router from '@/router';
import { ValidationError } from '@/packages/http/types';

/**
 * --------------------------------------------------
 *	HTTP Service
 * --------------------------------------------------
 **/
export class HttpService {
  /**
   * Http Client Constructor.
   * @param {IHttpClient} httpClient HTTP Service
   */
  constructor(protected httpClient: IHttpClient) {
    this.prepareClient(httpClient);
  }

  /**
   * Send a Request.
   * @param {HTTPRequestConfig} config Request Configuration.
   * @returns {HTTPPromise} Response Promise.
   */
  request<T = any>(config: AxiosRequestConfig): HTTPPromise<T> {
    return this.httpClient.request(config);
  }

  /**
   * Send a Request.
   * @param url
   * @param {HTTPRequestConfig} config Request Configuration.
   * @returns {HTTPPromise} Response Promise.
   */
  get<T = any>(url: string, config?: AxiosRequestConfig): HTTPPromise<T> {
    return this.httpClient.get(url, config);
  }

  /**
   * Send a Request.
   * @param url
   * @param {HTTPRequestConfig} config Request Configuration.
   * @returns {HTTPPromise} Response Promise.
   */
  delete(url: string, config?: AxiosRequestConfig): HTTPPromise {
    return this.httpClient.delete(url, config);
  }

  /**
   * Send a Request.
   * @param url
   * @param {HTTPRequestConfig} config Request Configuration.
   * @returns {HTTPPromise} Response Promise.
   */
  head(url: string, config?: AxiosRequestConfig): HTTPPromise {
    return this.httpClient.head(url, config);
  }

  /**
   * Send a Request.
   * @param url
   * @param data
   * @param {HTTPRequestConfig} config Request Configuration.
   * @returns {HTTPPromise} Response Promise.
   */
  post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): HTTPPromise<T> {
    return this.httpClient.post(url, data, config);
  }

  /**
   * Send a Request.
   * @param url
   * @param data
   * @param {HTTPRequestConfig} config Request Configuration.
   * @returns {HTTPPromise} Response Promise.
   */
  put<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): HTTPPromise<T> {
    return this.httpClient.put(url, data, config);
  }

  /**
   * Send a Request.
   * @param url
   * @param data
   * @param {HTTPRequestConfig} config Request Configuration.
   * @returns {HTTPPromise} Response Promise.
   */
  patch<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): HTTPPromise<T> {
    return this.httpClient.patch(url, data, config);
  }

  protected prepareClient(httpClient: IHttpClient) {
    this.addRequestInterceptor(httpClient);
    this.addResponseInterceptor(httpClient);
  }

  protected addResponseInterceptor(httpClient: IHttpClient) {
    httpClient.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 403) {
          window.location.href = '/dashboard';
        }

        if (error?.response?.status === 401) {
          const loginRoute = 'auth.login';
          const verifyRoute = 'auth.verify';
          if (
            ![loginRoute, verifyRoute].includes(
              router.currentRoute.name as string
            )
          ) {
            Auth.destroyToken();
            router.push({ name: loginRoute }).catch((e) => e);
          } else {
            error.response.data.msg =
              error.response.data.message ?? 'Auth failed';
          }
        }
        if (error?.response?.status === 422) {
          const res = error.response.data as ValidationError;
          error.response.data.msg =
            Object.values(res?.errors ?? {})[0][0] ?? 'Validation failed';
        }
        return Promise.reject(error && error.response);
      }
    );
  }

  protected addRequestInterceptor(httpClient: IHttpClient) {
    httpClient.interceptors.request.use(
      (request) => {
        const authToken = Auth.getToken();
        const isSameOrigin = request.url && !request.url.startsWith('http');

        if (isSameOrigin && authToken) {
          request.headers.Authorization = `Bearer ${authToken}`;
        } else {
          delete request.headers.Authorization;
        }

        return request;
      },
      (err) => Promise.reject(err)
    );
  }
}

/**
 * --------------------------------------------------
 *	HTTP Request Configuration Interface
 * --------------------------------------------------
 **/
export type HTTPRequestConfig = AxiosRequestConfig;

/**
 * --------------------------------------------------
 *	HTTP Promise Interface
 * --------------------------------------------------
 **/
export type HTTPPromise<T = any> = AxiosPromise<T>;

export const HTTPServiceClient: HttpService = new HttpService(HttpClient);
