import { IQCState, QCState } from '@/modules/qc/store/state';
import { QCActions } from '@/modules/qc/store/actions';
import { QCGetters } from '@/modules/qc/store/getters';
import { QCMutations } from '@/modules/qc/store/mutations';
import { Module } from 'vuex';
import { IRootState } from '@/store';

export const QCModuleStore: Module<IQCState, IRootState> = {
  state: QCState,
  actions: QCActions,
  getters: QCGetters,
  mutations: QCMutations,
};
