import { FundCost } from '@/models/Analysis';
import { Paginated } from '@/types';
import Fund from '../../../models/Fund';
import FundNature from '../../../models/FundNature';
import Investment from '../../../models/Investment';
import Organisation from '../../../models/Organisation';
import ProductType from '../../../models/ProductType';
import Strategy from '../../../models/Strategy';
import Template from '../../../models/Template';
import TimePeriod from '../../../models/TimePeriod';
import {
  AnalysisFundFilters,
  GeneratedReportDetails,
  RootDetail,
  SubFund,
} from '../types';

export type IAnalysisState = {
  analysisFundNatures: FundNature[] | null;
  analysisStrategies: Strategy[] | null;
  analysisTemplates: Template[] | null;
  analysisTimePeriods: TimePeriod[] | null;
  analysisProductTypes: ProductType[] | null;

  analysisSubFunds: Fund[] | null;

  paginatedFundAnalysis: Paginated<FundCost>;
  rootDetails: RootDetail[];
  fetchingAnalysis: boolean;
  orgClients: Organisation[];
  organizationSubFunds: SubFund[];
  investments: Investment[];
  allAssetManagers: Organisation[];
  generatedReportDetails: GeneratedReportDetails;
  appliedAnalysisFilters: AnalysisFundFilters | null;
};

export const AnalysisState: IAnalysisState = {
  analysisFundNatures: null,
  analysisStrategies: null,
  analysisTemplates: null,
  analysisTimePeriods: null,
  analysisProductTypes: null,
  analysisSubFunds: null,

  paginatedFundAnalysis: {} as Paginated<FundCost>,
  rootDetails: [],
  fetchingAnalysis: false,
  organizationSubFunds: [],
  investments: [],
  orgClients: [],
  allAssetManagers: [],
  generatedReportDetails: {} as GeneratedReportDetails,
  appliedAnalysisFilters: null,
};
