import { ISubmissionState } from '@/modules/submission/store/state';
import { IRootState } from '@/store';
import { ActionContext, ActionTree } from 'vuex';
import { SubmissionService } from '../services';
import * as AT from './actionTypes';
import Investment from '@/models/Investment';
import Submission from '@/models/Submission';
import {
  SummaryResponse,
  DownloadErrorReportsForBatchResponse,
  DownloadTemplateResponse,
  FetchErrorReportsForBatchUrlResponse,
  summaryApiParam,
  TemplateResolution,
} from '../types';
import {
  SET_FETCHING_INVESTMENT,
  SET_INVESTMENT,
  SET_MANAGER_ASSOCIATION_META,
  SET_PROVIDED_TEMPLATE_TYPES,
  SET_TEMPLATE_RESOLUTIONS,
  SET_TEMPLATE_RESPONSE_SWIFTNESS,
  SET_TEMPLATE_SOURCES,
} from './mutationsTypes';
import SharedResource from '@/models/SharedResource';
import { OrganisationAssociationMeta } from '@/models/OrganizationAssociation';

/**
 * --------------------------------------------------
 *  Submission Actions
 * --------------------------------------------------
 **/
export const SubmissionActions: ISubmissionActions = {
  [AT.FETCH_INVESTMENT]: async (
    { commit },
    req: { id: string; include?: string }
  ): Promise<Investment> => {
    commit(SET_FETCHING_INVESTMENT, true);
    const investment: Investment = await SubmissionService.fetchInvestment(
      req.id,
      req.include
    );
    commit(SET_FETCHING_INVESTMENT, false);
    commit(SET_INVESTMENT, investment);
    return investment;
  },

  [AT.FETCH_MANAGER_ASSOCIATION_META]: async (
    { commit },
    id: string
  ): Promise<OrganisationAssociationMeta> => {
    const oa: OrganisationAssociationMeta =
      await SubmissionService.fetchManagerAssociationMeta(id);
    commit(SET_MANAGER_ASSOCIATION_META, oa);
    return oa;
  },

  [AT.SUBMIT_DATA]: async ({ commit }, data: any) => {
    const response = await SubmissionService.submitTemplate(data);
    return response;
  },

  [AT.FETCH_SUBMISSION]: async (
    { commit },
    submissionId: string
  ): Promise<Submission> => {
    const response = await SubmissionService.fetchSubmission(submissionId);
    return response;
  },

  [AT.CHANGE_SUBMISSION_STATUS]: async (
    { commit },
    data: any
  ): Promise<Submission> => {
    const response = await SubmissionService.changeSubmissionStatus(data);
    return response;
  },

  [AT.REQUEST_SUBMISSION_REOPEN]: async (
    { commit },
    data: any
  ): Promise<any> => {
    const response = await SubmissionService.requestSubmissionReopen(data);
    return response;
  },

  [AT.FETCH_SUBMISSION_ERRORS]: async (
    { commit },
    submissionId: string
  ): Promise<any> => {
    const response = await SubmissionService.fetchSubmissionErrors(
      submissionId
    );
    return response;
  },

  [AT.DOWNLOAD_ERROR_REPORT]: async (
    { commit },
    submissionId: string
  ): Promise<any> => {
    const response = await SubmissionService.downloadErrorReport(submissionId);
    return response;
  },

  [AT.STORE_BYPASSED_ERRORS]: async (
    { commit },
    data: any
  ): Promise<Submission> => {
    const response = await SubmissionService.storeBypassedErrors(data);
    return response;
  },

  [AT.FETCH_SUBMISSIONS_FOR_BATCH]: async (
    { commit },
    batchId: any
  ): Promise<Submission[]> => {
    const response = await SubmissionService.fetchSubmissionsForBatch(batchId);
    return response;
  },

  [AT.DOWNLOAD_ERROR_REPORTS_FOR_BATCH]: async (
    { commit },
    batchId: string
  ): Promise<DownloadErrorReportsForBatchResponse> => {
    const response = await SubmissionService.downloadErrorReportsForBatch(
      batchId
    );
    return response;
  },

  [AT.FETCH_ERROR_REPORTS_URL_FOR_BATCH]: async (
    { commit },
    jobId: string
  ): Promise<FetchErrorReportsForBatchUrlResponse> => {
    const response = await SubmissionService.fetchErrorReporsUrlForBatch(jobId);
    return response;
  },

  [AT.FETCH_COST_SUMMARY]: async (
    { commit },
    params: summaryApiParam
  ): Promise<SummaryResponse> => {
    const response = await SubmissionService.fetchSummary(params);
    return response;
  },

  [AT.DOWNLOAD_COST_TEMPLATE]: async (
    { commit },
    submissionId: string
  ): Promise<DownloadTemplateResponse> => {
    const response = await SubmissionService.downloadTemplate(submissionId);
    return response;
  },

  [AT.FETCH_PROVIDED_TEMPLATE_TYPES]: async ({
    commit,
  }): Promise<SharedResource[]> => {
    const response = await SubmissionService.fetchProvidedTemplateTypes();
    commit(SET_PROVIDED_TEMPLATE_TYPES, response);
    return response;
  },

  [AT.FETCH_TEMPLATE_RESPONSE_SWIFTNESS]: async ({
    commit,
  }): Promise<SharedResource[]> => {
    const response = await SubmissionService.fetchResponseSwiftness();
    commit(SET_TEMPLATE_RESPONSE_SWIFTNESS, response);
    return response;
  },

  [AT.FETCH_TEMPLATE_SOURCES]: async ({
    commit,
  }): Promise<SharedResource[]> => {
    const response = await SubmissionService.fetchTemplateSources();
    commit(SET_TEMPLATE_SOURCES, response);
    return response;
  },

  [AT.FETCH_TEMPLATE_RESOLUTIONS]: async ({
    commit,
  }): Promise<TemplateResolution[]> => {
    const response = await SubmissionService.fetchTemplateResolutions();
    commit(SET_TEMPLATE_RESOLUTIONS, response);
    return response;
  },
};

/**
 * --------------------------------------------------
 *  Submission Action Context Interface
 * --------------------------------------------------
 **/
export interface ISubmissionActionContext
  extends ActionContext<ISubmissionState, IRootState> {}

/**
 * --------------------------------------------------
 *  Submission Actions Interface
 * --------------------------------------------------
 **/
export interface ISubmissionActions
  extends ActionTree<ISubmissionState, IRootState> {
  [AT.FETCH_INVESTMENT](
    context: ISubmissionActionContext,
    req: { id: string; include?: string }
  ): Promise<Investment>;

  [AT.FETCH_MANAGER_ASSOCIATION_META](
    context: ISubmissionActionContext,
    id: string
  ): Promise<OrganisationAssociationMeta>;

  [AT.SUBMIT_DATA](context: ISubmissionActionContext, data: any): Promise<any>;

  [AT.FETCH_SUBMISSION](
    context: ISubmissionActionContext,
    submissionId: string
  ): Promise<Submission>;

  [AT.CHANGE_SUBMISSION_STATUS](
    context: ISubmissionActionContext,
    data: any
  ): Promise<Submission>;

  [AT.FETCH_SUBMISSION_ERRORS](
    context: ISubmissionActionContext,
    submissionId: string
  ): Promise<any>;

  [AT.DOWNLOAD_ERROR_REPORT](
    context: ISubmissionActionContext,
    submissionId: string
  ): Promise<any>;

  [AT.STORE_BYPASSED_ERRORS](
    context: ISubmissionActionContext,
    data: any
  ): Promise<any>;

  [AT.FETCH_SUBMISSIONS_FOR_BATCH](
    context: ISubmissionActionContext,
    batchId: string
  ): Promise<Submission[]>;

  [AT.DOWNLOAD_ERROR_REPORTS_FOR_BATCH](
    context: ISubmissionActionContext,
    batchId: string
  ): Promise<DownloadErrorReportsForBatchResponse>;

  [AT.FETCH_ERROR_REPORTS_URL_FOR_BATCH](
    context: ISubmissionActionContext,
    jobId: string
  ): Promise<FetchErrorReportsForBatchUrlResponse>;

  [AT.FETCH_COST_SUMMARY](
    context: ISubmissionActionContext,
    params: summaryApiParam
  ): Promise<SummaryResponse>;

  [AT.DOWNLOAD_COST_TEMPLATE](
    context: ISubmissionActionContext,
    submissionId: string
  ): Promise<DownloadTemplateResponse>;

  [AT.FETCH_PROVIDED_TEMPLATE_TYPES](
    context: ISubmissionActionContext
  ): Promise<SharedResource[]>;

  [AT.FETCH_TEMPLATE_SOURCES](
    context: ISubmissionActionContext
  ): Promise<SharedResource[]>;

  [AT.FETCH_TEMPLATE_RESPONSE_SWIFTNESS](
    context: ISubmissionActionContext
  ): Promise<SharedResource[]>;

  [AT.FETCH_TEMPLATE_RESOLUTIONS](
    context: ISubmissionActionContext
  ): Promise<TemplateResolution[]>;
}
