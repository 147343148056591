import { TARGET_ENTITIES } from '@/utils/TargetEntity';
import { RouteConfig } from 'vue-router';
const UserModule = () =>
  import(/* webpackChunkName: 'user' */ './UserModule.vue');
const ListUsersPage = () =>
  import(/* webpackChunkName: 'user' */ './pages/ListUsersPage.vue');

export const UserRoutes: RouteConfig[] = [
  {
    path: 'users',
    component: UserModule,
    meta: {
      middlewares: {
        canAccessEntity: TARGET_ENTITIES.listUsers,
      },
    },
    children: [
      {
        path: 'list',
        name: 'users.list',
        component: ListUsersPage,
      },
    ],
  },
];
