import Auth from '@/packages/Auth';
import { Middleware, MiddlewareNext } from '@/router/middlewares/types';
import { Route } from 'vue-router';

export const GuestMiddleware: Middleware = {
  handle(to: Route, from: Route, next: MiddlewareNext, data?: any) {
    const impersonationRoute = 'auth.impersonation';

    if (Auth.isAuthenticated() && to.name !== impersonationRoute) {
      return next({
        // redirect to dashboard
        name: 'dashboard',
      });
    }
  },
};
