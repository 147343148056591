import { User } from '@/models/User';
import { Paginated } from '@/types';

export type IUserState = {
  users: Paginated<User[]> | null;
  fetchingUsers: boolean;
};

export const UserState: IUserState = {
  users: null,
  fetchingUsers: false,
};
