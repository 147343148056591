export const SET_FUND_NATURES = 'analysis/SET_FUND_NATURES';
export const SET_STRATEGIES = 'analysis/SET_STRATEGIES';
export const SET_TEMPLATES = 'analysis/SET_TEMPLATES';
export const SET_TIME_PERIODS = 'analysis/SET_TIME_PERIODS';
export const SET_PRODUCT_TYPES = 'analysis/SET_PRODUCT_TYPES';

export const SET_SUB_FUNDS = 'analysis/SET_SUB_FUNDS';

export const SET_FETCHING_ANALYSIS = 'analysis/SET_FETCHING_ANALYSIS_DATA';
export const SET_PAGINATED_FUND_ANALYSIS =
  'costAnalysis/SET_PAGINATED_FUND_ANALYSIS';
export const SET_ROOT_DETAILS = 'analysis/SET_ROOT_DETAILS';
export const SET_ORG_CLIENTS = 'analysis/SET_ORG_CLIENTS';
export const SET_ASSET_MANAGERS = 'analysis/SET_ASSET_MANAGERS';
export const SET_ORGANIZATION_SUB_FUNDS = 'analysis/SET_ORGANIZATION_SUB_FUNDS';
export const SET_INVESTMENTS = 'analysis/SET_INVESTMENTS';
export const SET_GENERATED_REPORT_DETAILS =
  'analysis/SET_GENERATED_REPORT_DETAILS';
export const SET_APPLIED_ANALYSIS_FILTERS =
  'analysis/SET_APPLIED_ANALYSIS_FILTERS';
