import { QueryType } from '@/utils/QueryUtils';
import { Utils } from '@/utils/Utils';
import { merge } from 'lodash';
import {
  SortAttribute,
  SortBuilder,
  SortOperator,
  SortPlan,
  SortSet,
} from './types';

export class Sort {
  public static make(builder: Partial<SortBuilder>): SortBuilder {
    return merge({}, { ...builder }, { ...Sort.makeDefaultSchema() });
  }

  public static toQuery(plan: SortPlan): Record<string, string> {
    const params = new Map();

    plan.sortSets.forEach((sortSet) => {
      params.set(
        `sort[${sortSet.slug}]`,
        Sort.makeSortOperator(sortSet.operator)
      );
    });

    return Object.fromEntries(params);
  }

  public static rePlan(
    builder: SortBuilder | null = null,
    plan: SortPlan
  ): SortBuilder | null {
    if (!builder) {
      return null;
    }

    return merge(
      {},
      {
        schema: builder.schema,
        ...plan,
      }
    );
  }

  public static makeSortOperator(
    operator: SortOperator | null = 'asc'
  ): string {
    operator = operator ?? 'asc';
    return `${operator.toLowerCase()}`;
  }

  public static makeDefaultSchema(): SortBuilder {
    return {
      schema: {
        availableAttributes: Sort.getDefaultAvailableAttributes(),
        availableOperators: Sort.getDefaultAvailableOperators(),
      },
      sortSets: [],
    };
  }

  public static getDefaultAvailableOperators(): SortOperator[] {
    return Array.from(['asc', 'desc']);
  }

  static getDefaultAvailableAttributes(): SortAttribute[] {
    return [];
  }

  // converts key-value query to sort plan
  public static resolvePlanFromQuery(query: Record<string, any>): SortPlan {
    return {
      sortSets: this.resolveSortSetsFromQuery(query),
    };
  }

  // maps all filter queries to sort set
  private static resolveSortSetsFromQuery(
    query: Record<string, any>
  ): SortSet[] {
    const sortKeysPresentInQuery = Object.keys(query)?.filter((it) =>
      it?.includes(QueryType.SORT)
    );
    const filterSet: any = [];

    sortKeysPresentInQuery?.forEach((key) => {
      const sortValue = query[key]?.split(':');

      filterSet.push({
        id: Utils.getRandomIdentifier(),
        position: parseInt(sortValue[0] ?? null),
        operator: sortValue[1] ?? null,
        slug: key?.replaceAll(/(sort|\[|\])/gi, ''),
      });
    });
    return filterSet;
  }

  public static toRouteQuery(plan: SortPlan): Record<string, string> {
    const params = new Map();

    plan.sortSets.forEach((sortSet) => {
      params.set(
        `sort[${sortSet.slug}]`,
        [sortSet.position, Sort.makeSortOperator(sortSet.operator)].join(':')
      );
    });

    return Object.fromEntries(params);
  }
}
