import { IInvestmentState } from './state';
import { MutationTree } from 'vuex';
import * as MT from './mutationsTypes';
import { Paginated } from '@/types';
import { FundFilters, FundInvestment } from '../types';
import Fund from '@/models/Fund';
import FundNature from '@/models/FundNature';
import Strategy from '@/models/Strategy';
import Template from '@/models/Template';
import TimePeriod from '@/models/TimePeriod';
import ProductType from '@/models/ProductType';

/*--------------------------------------------------/
 * Investment Mutations
 *--------------------------------------------------*/
export const InvestmentMutations: IInvestmentMutations = {
  [MT.SET_INVESTMENTS]: (
    state: IInvestmentState,
    investments: Paginated<FundInvestment[]>
  ) => (state.fundInvestments = investments),

  [MT.SET_FETCHING_INVESTMENTS]: (state: IInvestmentState, flag: boolean) =>
    (state.fetchingInvestments = flag),

  [MT.SET_SUB_FUNDS]: (state: IInvestmentState, subFunds: Fund[]) =>
    (state.subFunds = subFunds),
  [MT.SET_FUND_NATURES]: (state: IInvestmentState, fundNatures: FundNature[]) =>
    (state.fundNatures = fundNatures),
  [MT.SET_STRATEGIES]: (state: IInvestmentState, strategies: Strategy[]) =>
    (state.strategies = strategies),
  [MT.SET_TEMPLATES]: (state: IInvestmentState, templates: Template[]) =>
    (state.templates = templates),
  [MT.SET_TIME_PERIODS]: (state: IInvestmentState, timePeriods: TimePeriod[]) =>
    (state.timePeriods = timePeriods),
  [MT.SET_PRODUCT_TYPES]: (
    state: IInvestmentState,
    productTypes: ProductType[]
  ) => (state.productTypes = productTypes),
  [MT.SET_APPLIED_INVESTMENT_FILTERS]: (
    state: IInvestmentState,
    appliedFilters: FundFilters | null
  ) => (state.appliedInvestmentFilters = appliedFilters),
};

/*--------------------------------------------------/
 * Investment Mutations Tree Interface
 *--------------------------------------------------*/
export interface IInvestmentMutations extends MutationTree<IInvestmentState> {
  [MT.SET_INVESTMENTS](
    state: IInvestmentState,
    investments: Paginated<FundInvestment[]>
  ): void;

  [MT.SET_FETCHING_INVESTMENTS](state: IInvestmentState, flag: boolean): void;

  [MT.SET_SUB_FUNDS](state: IInvestmentState, subFunds: Fund[]): void;

  [MT.SET_FUND_NATURES](
    state: IInvestmentState,
    fundNatures: FundNature[]
  ): void;

  [MT.SET_STRATEGIES](state: IInvestmentState, strategies: Strategy[]): void;

  [MT.SET_TEMPLATES](state: IInvestmentState, templates: Template[]): void;

  [MT.SET_TIME_PERIODS](
    state: IInvestmentState,
    timePeriods: TimePeriod[]
  ): void;

  [MT.SET_PRODUCT_TYPES](
    state: IInvestmentState,
    productTypes: ProductType[]
  ): void;

  [MT.SET_APPLIED_INVESTMENT_FILTERS](
    state: IInvestmentState,
    appliedFilters: FundFilters | null
  ): void;
}
