import { Paginated } from '@/types';
import RenewalOrganisation from '../../../models/RenewalOrganisation';
import { RenewalFilters } from '../types';

export type IRenewalState = {
  renewalClients: Paginated<RenewalOrganisation[]> | null;
  fetchingRenewalClients: boolean;
  appliedRenewalFilters: RenewalFilters | null;
};

export const RenewalState: IRenewalState = {
  renewalClients: null,
  fetchingRenewalClients: false,
  appliedRenewalFilters: null,
};
