import Currency from '@/models/Currency';
import Organisation from '@/models/Organisation';
import OrganisationType from '@/models/OrganisationType';
import { User } from '@/models/User';
import Snackbar from '../../../models/Snackbar';
import Country from '@/models/Country';

export type IDashboardState = {
  currentUser: User | null;
  validatingEntityAccess: Boolean;
  snackbar: Snackbar | null;
  packageVersion: string;
  organizations: Organisation[] | null;
  organizationTypes: OrganisationType[] | null;
  currencies: Currency[] | null;
  countries: Country[] | null;
};

export const DashboardState: IDashboardState = {
  currentUser: null,
  validatingEntityAccess: false,
  snackbar: null,
  packageVersion: process.env.PACKAGE_VERSION || '0',
  organizations: null,
  organizationTypes: null,
  currencies: null,
  countries: null,
};
