import { Tag } from '@/models/Tag';
import { Paginated } from '@/types';

export type ITagState = {
  tags: Paginated<Tag> | null;
  qcTags: Paginated<Tag> | null;
  areQCTagsLoading: boolean;
};

export const TagState: ITagState = {
  tags: null,
  qcTags: null,
  areQCTagsLoading: false,
};
