import Fund from '@/models/Fund';
import FundNature from '@/models/FundNature';
import ProductType from '@/models/ProductType';
import Strategy from '@/models/Strategy';
import Template from '@/models/Template';
import TimePeriod from '@/models/TimePeriod';
import { Paginated } from '@/types';
import { FundFilters, FundInvestment } from '../types';

export type IInvestmentState = {
  fundInvestments: Paginated<FundInvestment[]> | null;
  fetchingInvestments: boolean;
  subFunds: Fund[] | null;
  fundNatures: FundNature[] | null;
  strategies: Strategy[] | null;
  templates: Template[] | null;
  timePeriods: TimePeriod[] | null;
  productTypes: ProductType[] | null;
  appliedInvestmentFilters: FundFilters | null;
};

export const InvestmentState: IInvestmentState = {
  fundInvestments: null,
  fetchingInvestments: false,
  subFunds: null,
  fundNatures: null,
  strategies: null,
  templates: null,
  timePeriods: null,
  productTypes: null,
  appliedInvestmentFilters: null,
};
