import { AnalysisActions } from './actions';
import { AnalysisGetters } from './getters';
import { AnalysisMutations } from './mutations';
import { AnalysisState, IAnalysisState } from './state';
import { IRootState } from '@/store';
import { Module } from 'vuex';

export const AnalysisModuleStore: IAnalysisModuleStore = {
  state: AnalysisState,
  actions: AnalysisActions,
  getters: AnalysisGetters,
  mutations: AnalysisMutations,
};

/**
 * --------------------------------------------------
 *	Analysis Store Interface
 * --------------------------------------------------
 **/
export type IAnalysisModuleStore = Module<IAnalysisState, IRootState>;
