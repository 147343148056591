import { BenchmarkingActions } from './actions';
import { BenchmarkingGetters } from './getters';
import { BenchmarkingMutations } from './mutations';
import { IBenchmarkingState, BenchmarkingState } from './state';
import { IRootState } from '@/store';
import { Module } from 'vuex';

export const BenchmarkingModuleStore: IBenchmarkingModuleStore = {
  state: BenchmarkingState,
  actions: BenchmarkingActions,
  getters: BenchmarkingGetters,
  mutations: BenchmarkingMutations,
};

/**
 * --------------------------------------------------
 *  Benchmarking Store Interface
 * --------------------------------------------------
 **/
export type IBenchmarkingModuleStore = Module<IBenchmarkingState, IRootState>;
