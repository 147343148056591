import { ITagState } from './state';
import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { Tag } from '@/models/Tag';
import { Paginated } from '@/types';

/**
 * --------------------------------------------------
 *  Tag Getters
 * --------------------------------------------------
 **/
export const TagGetters: ITagGetters = {
  tags: (state: ITagState): Paginated<Tag> | null => state.tags,
  qcTags: (state: ITagState): Tag[] => state.qcTags?.data || [],
  areQCTagsLoading: (state: ITagState): boolean => state.areQCTagsLoading,
};

/**
 * --------------------------------------------------
 *  Tag Getters Interface
 * --------------------------------------------------
 **/
export interface ITagGetters extends GetterTree<ITagState, IRootState> {
  tags(state: ITagState): Paginated<Tag> | null;
  qcTags(state: ITagState): Tag[];
  areQCTagsLoading(state: ITagState): boolean;
}
