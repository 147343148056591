import { FilterPlan } from '@/components/filter/types';
import { SortPlan } from '@/components/sort/types';
import Organisation from '@/models/Organisation';
import { IRootState } from '@/store';
import { Paginated, PaginatedParams } from '@/types';
import { ActionContext, ActionTree } from 'vuex';
import { AssetManagerService } from '../services';
import * as AT from './actionTypes';
import {
  SET_ASSET_MANAGERS,
  SET_FETCHING_ASSET_MANAGERS,
} from './mutationsTypes';
import { IAssetManagerState } from './state';

/**
 * --------------------------------------------------
 *  Asset Manager Actions
 * --------------------------------------------------
 **/
export const AssetManagerActions: IAssetManagerActions = {
  [AT.FETCH_ASSET_MANAGERS]: async (
    { commit },
    params?: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filterPlan?: FilterPlan | null;
    }>
  ): Promise<Paginated<Organisation>> => {
    commit(SET_FETCHING_ASSET_MANAGERS, true);
    try {
      const data = await AssetManagerService.fetchAssetManagers(params);
      commit(SET_ASSET_MANAGERS, data);
      return data;
    } catch (e) {
      commit(SET_ASSET_MANAGERS, []);
      throw e;
    } finally {
      commit(SET_FETCHING_ASSET_MANAGERS, false);
    }
  },
};

/**
 * --------------------------------------------------
 *  Asset Manager Actions Interface
 * --------------------------------------------------
 **/
export interface IAssetManagerActions
  extends ActionTree<IAssetManagerState, IRootState> {
  [AT.FETCH_ASSET_MANAGERS](
    context: IAssetManagerActionContext,
    params: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filterPlan?: FilterPlan | null;
    }>
  ): Promise<Paginated<Organisation>>;
}

/**
 * --------------------------------------------------
 *  Asset Manager Action Context Interface
 * --------------------------------------------------
 **/
export type IAssetManagerActionContext = ActionContext<
  IAssetManagerState,
  IRootState
>;
