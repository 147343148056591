import { TARGET_ENTITIES } from '@/utils/TargetEntity';
import { RouteConfig } from 'vue-router';
const PensionSchemeModule = () =>
  import(/* webpackChunkName: 'scheme' */ './PensionSchemeModule.vue');
const ListPensionSchemesPage = () =>
  import(/* webpackChunkName: 'scheme' */ './pages/ListPensionSchemesPage.vue');

export const PensionSchemesRoutes: RouteConfig[] = [
  {
    path: 'pension-schemes',
    component: PensionSchemeModule,
    meta: {
      middlewares: {
        canAccessEntity: TARGET_ENTITIES.listPensionSchemes,
      },
    },
    children: [
      {
        path: 'list',
        name: 'pension_schemes.list',
        component: ListPensionSchemesPage,
      },
    ],
  },
];
