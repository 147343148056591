import { AssetManagerActions } from './actions';
import { AssetManagerGetters } from './getters';
import { AssetManagerMutations } from './mutations';
import { AssetManagerState, IAssetManagerState } from './state';
import { IRootState } from '@/store';
import { Module } from 'vuex';

export const AssetManagerModuleStore: IAssetManagerModuleStore = {
  state: AssetManagerState,
  actions: AssetManagerActions,
  getters: AssetManagerGetters,
  mutations: AssetManagerMutations,
};

/**
 * --------------------------------------------------
 *	AssetManager Store Interface
 * --------------------------------------------------
 **/
export type IAssetManagerModuleStore = Module<IAssetManagerState, IRootState>;
