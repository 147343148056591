import { IPensionSchemeState } from './state';
import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { Paginated } from '@/types';
import Organisation from '@/models/Organisation';
/**
 * --------------------------------------------------
 *	PensionScheme Getters
 * --------------------------------------------------
 **/
export const PensionSchemeGetters: IPensionSchemeGetters = {
  pensionSchemes: (
    state: IPensionSchemeState
  ): Paginated<Organisation[]> | null => state.pensionSchemes,

  fetchingPensionSchemes: (state: IPensionSchemeState): boolean =>
    state.fetchingPensionSchemes,
};

/**
 * --------------------------------------------------
 *	PensionScheme Getters Interface
 * --------------------------------------------------
 **/
export interface IPensionSchemeGetters
  extends GetterTree<IPensionSchemeState, IRootState> {
  pensionSchemes(state: IPensionSchemeState): Paginated<Organisation[]> | null;

  fetchingPensionSchemes(state: IPensionSchemeState): boolean;
}
