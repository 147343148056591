import { RenewalFilters } from './types';

export const MapRenewalFiltersToQuery = (renewalFilters: RenewalFilters) => {
  const params = new URLSearchParams();

  renewalFilters?.timePeriods?.forEach((it) =>
    params.append('filter[time_period_id][]', it)
  );

  renewalFilters?.pensionSchemes?.forEach((it) =>
    params.append('filter[pension_scheme_id][]', it)
  );

  renewalFilters?.assignees?.forEach((it) =>
    params.append('filter[assignees.userId][]', it)
  );

  if (renewalFilters?.providerSearchKey) {
    params.set(
      'filter[provider_name]',
      renewalFilters?.providerSearchKey?.toString()
    );
  }

  if (renewalFilters?.consultantSearchKey) {
    params.set(
      'filter[consultant_name]',
      renewalFilters?.consultantSearchKey?.toString()
    );
  }

  if (renewalFilters?.completed) {
    params.set('filter[completed]', renewalFilters?.completed?.toString());
  }

  return params.toString();
};
