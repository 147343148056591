import { ResourceBenchmarkingItem } from '@/components/resource/types';
import { Paginated } from '@/types';

export type IBenchmarkingState = {
  benchmarkingItems: Paginated<ResourceBenchmarkingItem> | null;
};

export const BenchmarkingState: IBenchmarkingState = {
  benchmarkingItems: null,
};
