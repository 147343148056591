import config from '@/config.ts';
import axios, { AxiosInstance } from 'axios';

/**
 * --------------------------------------------------
 *	HTTP Client Interface
 * --------------------------------------------------
 **/
export type IHttpClient = AxiosInstance;

/**
 * --------------------------------------------------
 *	Http Client Instance
 * --------------------------------------------------
 **/
const HttpClient = axios.create({
  baseURL: config.getApiUrl(),
});

export { HttpClient };
