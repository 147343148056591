
import { RemoveAllItem, REMOVE_ALL_ITEMS_SLUG } from '@/utils/InvestmentUtils';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class AutocompleteField extends Vue {
  @Prop({ default: 'generic-autocomplete-input' }) testId!: string;
  @Prop({ default: () => [] }) items!: any;
  @Prop({}) value!: any;
  @Prop({ default: '' }) label!: string;
  @Prop({ default: '' }) placeholder!: string;
  @Prop({ default: false }) loading!: Boolean;
  @Prop({ default: true }) multiple!: Boolean;
  @Prop({ default: 'name' }) itemText!: string;
  @Prop({ default: false }) disabled!: Boolean;
  @Prop({ default: 'mx-2' }) elementClass!: string;
  @Prop({ default: '10vh' }) chipWidth!: string;
  @Prop({ default: 'id' }) itemValue!: string;
  @Prop({ default: false }) appendRemoveAllItem!: Boolean;
  @Prop({ default: () => [] }) error!: string[];

  public get placeholderLabel() {
    return this.placeholder ? this.placeholder : `Select ${this.label}`;
  }

  public get labelClass() {
    return `caption ${this.elementClass}`;
  }

  public get chipStyle() {
    return {
      'max-width': this.chipWidth,
    };
  }

  public get itemsList(): any {
    if (!this.appendRemoveAllItem) {
      return this.items;
    }

    return [RemoveAllItem, ...this.items];
  }

  public handleValueChange(selectedValue: any) {
    let value = selectedValue;
    if (this.multiple && selectedValue?.includes(REMOVE_ALL_ITEMS_SLUG)) {
      value = [REMOVE_ALL_ITEMS_SLUG];
    }
    this.$emit('change', value);
  }

  public get errorMessage() {
    return this.error[0] ?? null;
  }
}
