import VueRouter, { Location, Route } from 'vue-router';

export const pushRouteAndSkipAccessAuthorization = (
  router: VueRouter,
  location: Location
): Promise<Route> => {
  location.params = { skipAccessAuthorization: 'true', ...location.params };
  return router.push(location);
};

export const shouldSkipAccessAuthorization = (
  params: Location['params']
): boolean => {
  return Boolean(params?.skipAccessAuthorization);
};
