import { ResourceTag } from '@/models/ResourceTag';
import { Paginated } from '@/types';

export type IResourceTagState = {
  resourceTags: Paginated<ResourceTag> | null;
  areResourceTagsLoading: boolean;
};

export const ResourceTagState: IResourceTagState = {
  resourceTags: null,
  areResourceTagsLoading: false,
};
