export const FETCH_INVESTMENTS = 'investment/FETCH_INVESTMENTS';
export const UPDATE_INVESTMENT = 'investment/UPDATE_INVESTMENT';
export const FETCH_SUB_FUNDS = 'investment/FETCH_SUB_FUNDS';
export const FETCH_FUND_NATURES = 'investment/FETCH_FUND_NATURES';
export const FETCH_STRATEGIES = 'investment/FETCH_STRATEGIES';
export const FETCH_TEMPLATES = 'investment/FETCH_TEMPLATES';
export const FETCH_TIME_PERIODS = 'investment/FETCH_TIME_PERIODS';
export const EXPORT_DATA = 'investment/EXPORT_DATA';
export const DOWNLOAD_DATA = 'investment/DOWNLOAD_DATA';
export const RECOLLECT_DATA = 'investment/RECOLLECT_DATA';
export const SEND_EMAILS = 'investment/SEND_EMAILS';
export const SEND_SUBMISSION_REQUESTS = 'investment/SEND_SUBMISSION_REQUESTS';
export const FETCH_PRODUCT_TYPES = 'investment/FETCH_PRODUCT_TYPES';
export const UPDATE_FUNDS = 'investment/UPDATE_FUNDS';
export const IMPORT_FUNDS = 'investment/IMPORT_FUNDS';
export const IMPORT_ONBOARDING_SHEET = 'investment/IMPORT_ONBOARDING_SHEET';
export const RETRACT_SUBMISSION_REQUESTS =
  'investment/RETRACT_SUBMISSION_REQUESTS';
export const MERGE_ENTITY_IDS = 'investment/merge';
export const UPDATE_ROLLUP_TAG = 'investment/UPDATE_ROLLUP_TAG';
