import { RouteConfig } from 'vue-router';
import { ModuleRoutes } from '@/modules/routes';
import { AppRoutes } from './app';

export const Routes: RouteConfig[] = [
  // Application Routes
  ...AppRoutes,
  // Module Routes
  ...ModuleRoutes,
];
