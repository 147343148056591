import { HTTPServiceClient } from '@/packages/http/service';
import * as t from './endpoints';
import { User } from '@/models/User';
import Organisation from '@/models/Organisation';
import { Paginated } from '@/types';
import { APIErrorResponse } from '@/packages/http/types';
import OrganisationType from '@/models/OrganisationType';
import {
  ClientContractRequest,
  ClientPreferredCurrencyRequest,
  Google2FADetails,
} from './types';
import Currency from '@/models/Currency';
import Country from '@/models/Country';

/**
 * --------------------------------------------------
 *  Dashboard HTTP Serice
 * --------------------------------------------------
 **/
export const DashboardService = {
  /**
   * Fetches the logged in user
   * @returns {Promise<User>}
   */
  async fetchCurrentUser(): Promise<User> {
    const { data } = await HTTPServiceClient.get(t.FETCH_USER_ENDPOINT);
    return data.data;
  },

  /**
   * Fetches all the organizations.
   * @returns {Promise<Organisation[]>}
   */
  async fetchOrganizations(): Promise<Organisation[]> {
    try {
      const { data } = await HTTPServiceClient.get<Paginated<Organisation>>(
        t.FETCH_ORGANIZATIONS
      );
      return data.data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Fetches all the organization types.
   * @returns {Promise<OrganisationType[]>}
   */
  async fetchOrganizationTypes(): Promise<OrganisationType[]> {
    try {
      const { data } = await HTTPServiceClient.get<Paginated<OrganisationType>>(
        t.FETCH_ORGANIZATION_TYPES
      );
      return data.data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Save Client Contracts.
   * @returns {Promise<Response>}
   */
  async saveClientContracts(request: ClientContractRequest): Promise<Response> {
    try {
      const { data } = await HTTPServiceClient.post<Response>(
        t.CREATE_CLIENT_CONTRACTS,
        request
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Fetch 2FA details
   * @returns {Promise<Response>}
   */
  async fetch2FADetails(userId: string): Promise<Google2FADetails> {
    try {
      const { data } = await HTTPServiceClient.get(
        t.USER_2FA_DETAILS_ENDPOINT(userId)
      );

      return data;
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Save 2FA details
   * @returns {Promise<Response>}
   */
  async save2FADetails(userId: string): Promise<User> {
    try {
      const { data } = await HTTPServiceClient.post(
        t.USER_2FA_DETAILS_ENDPOINT(userId)
      );

      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Save Client's Preferred Currency.
   * @returns {Promise<Response>}
   */
  async savePreferredCurrency(
    request: ClientPreferredCurrencyRequest
  ): Promise<Response> {
    try {
      const { data } = await HTTPServiceClient.put<Response>(
        t.SET_CLIENTS_PREFERRED_CURRENCY,
        request
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Fetch Currency List.
   * @returns {Promise<Currency[]>}
   */
  async fetchCurrencyList(): Promise<Currency[]> {
    try {
      const { data } = await HTTPServiceClient.get<Paginated<Currency>>(
        t.FETCH_CURRENCY_LIST
      );
      return data?.data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Fetch Country List.
   * @returns {Promise<Currency[]>}
   */
  async fetchCountryList(): Promise<Country[]> {
    try {
      const { data } = await HTTPServiceClient.get<Paginated<Country>>(
        t.FETCH_COUNTRY_LIST
      );
      return data?.data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },
};
