export const FETCH_PENSION_SCHEMES_ENDPOINT = `base/admin/pension-schemes?include=contract`;
export const FETCH_PENSION_SCHEME_DETAILS_ENDPOINT = (
  pensionSchemeId: string
) => `base/admin/pension-schemes/${pensionSchemeId}`;
export const STORE_PENSION_SCHEME_FEEDBACK_ENDPOINT = `base/admin/pension-schemes/store-feedback`;
export const STORE_REPORTING_DUE_DATE = `base/admin/pension-schemes/reporting_due_date`;
export const FETCH_REPORTING_DUE_DATE_DETAILS_ENDPOINT = (
  pensionSchemeId: string
) => `base/admin/pension-schemes/${pensionSchemeId}/reporting_due_date`;
export const INTERNAL_CLIENT_CODE_DETAILS = (pensionSchemeId: string) =>
  `base/admin/pension-schemes/internal-code/${pensionSchemeId}`;
export const STORE_PENSION_SCHEME_ENDPOINT = (organisationId: string) =>
  `base/admin/pension-schemes/${organisationId}`;
