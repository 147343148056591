import { AuthService } from '@/modules/auth/services';
import * as AT from '@/modules/auth/store/actionTypes';
import { IAuthState } from '@/modules/auth/store/state';
import Auth from '@/packages/Auth';
import { IRootState } from '@/store';
import { ActionContext, ActionTree } from 'vuex';
import { Google2FAVerifyForm, LoginFormData } from '../types';

/**
 * --------------------------------------------------
 *  Auth Actions
 * --------------------------------------------------
 **/
export const AuthActions: IAuthActions = {
  [AT.LOGIN_USER]: async ({ commit }, data: LoginFormData) => {
    const response = await AuthService.login(data);
    if (response && response.token) {
      Auth.setToken(response.token);
      const user = JSON.parse(atob(response.token.split('.')[1]));
      Auth.setUserId(user.id);
    }

    return response;
  },
  [AT.VERIFY_2FA]: async ({ commit }, data: Google2FAVerifyForm) => {
    const response = await AuthService.verify2FA(data);
    if (response && response.token) {
      Auth.setToken(response.token);
      const user = JSON.parse(atob(response.token.split('.')[1]));
      Auth.setUserId(user.id);
    }

    return response;
  },
};

/**
 * --------------------------------------------------
 *  Auth Actions Interface
 * --------------------------------------------------
 **/
export interface IAuthActions extends ActionTree<IAuthState, IRootState> {
  [AT.LOGIN_USER](context: IAuthActionContext, data: LoginFormData): void;

  [AT.VERIFY_2FA](context: IAuthActionContext, data: Google2FAVerifyForm): void;
}

/**
 * --------------------------------------------------
 *  Auth Action Context Interface
 * --------------------------------------------------
 **/
export type IAuthActionContext = ActionContext<IAuthState, IRootState>;
