import Organisation from '@/models/Organisation';
import { Paginated } from '@/types';

export type IFiduciaryState = {
  fiduciaries: Paginated<Organisation[]> | null;
  fetchingFiduciaries: boolean;
};

export const FiduciaryState: IFiduciaryState = {
  fiduciaries: null,
  fetchingFiduciaries: false,
};
