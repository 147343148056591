import { IResourceTagState } from './state';
import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { ResourceTag } from '@/models/ResourceTag';

/**
 * --------------------------------------------------
 *  Resource Tag Getters
 * --------------------------------------------------
 **/
export const ResourceTagGetters: IResourceTagGetters = {
  resourceTags: (state: IResourceTagState): ResourceTag[] =>
    state.resourceTags?.data ?? [],
  areResourceTagsLoading: (state: IResourceTagState): boolean =>
    state.areResourceTagsLoading,
};

/**
 * --------------------------------------------------
 *  Resource Tag Getters Interface
 * --------------------------------------------------
 **/
export interface IResourceTagGetters
  extends GetterTree<IResourceTagState, IRootState> {
  resourceTags(state: IResourceTagState): ResourceTag[];
  areResourceTagsLoading(state: IResourceTagState): boolean;
}
