import { DashboardActions } from '@/modules/dashboard/store/actions';
import { DashboardGetters } from '@/modules/dashboard/store/getters';
import { DashboardMutations } from '@/modules/dashboard/store/mutations';
import {
  DashboardState,
  IDashboardState,
} from '@/modules/dashboard/store/state';
import { IRootState } from '@/store';
import { Module } from 'vuex';

export const DashboardModuleStore: IDashboardModuleStore = {
  state: DashboardState,
  actions: DashboardActions,
  getters: DashboardGetters,
  mutations: DashboardMutations,
};

/**
 * --------------------------------------------------
 *	Dashboard Store Interface
 * --------------------------------------------------
 **/
export type IDashboardModuleStore = Module<IDashboardState, IRootState>;
