import { IAdminUserState } from './state';
import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { AdminUser } from '@/models/AdminUser';

/**
 * --------------------------------------------------
 *  Admin User Getters
 * --------------------------------------------------
 **/
export const AdminUserGetters: IAdminUserGetters = {
  adminUsers: (state: IAdminUserState): AdminUser[] | null => state.adminUsers,
};

/**
 * --------------------------------------------------
 *  Admin User Getters Interface
 * --------------------------------------------------
 **/
export interface IAdminUserGetters
  extends GetterTree<IAdminUserState, IRootState> {
  adminUsers(state: IAdminUserState): AdminUser[] | null;
}
