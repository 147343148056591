import { IResourceTagState } from './state';
import { MutationTree } from 'vuex';
import * as MT from './mutationsTypes';
import { Paginated, ResourceCollection } from '@/types';
import { ResourceTag } from '@/models/ResourceTag';
import { ResourceTagService } from '../services';

/*--------------------------------------------------/
 * Resource Tag Mutations
 *--------------------------------------------------*/
export const ResourceTagMutations: IResourceTagMutations = {
  [MT.SET_RESOURCE_TAGS]: (
    state: IResourceTagState,
    resourceTags: Paginated<ResourceTag>
  ) => (state.resourceTags = resourceTags),
  [MT.MERGE_RESOURCE_TAGS]: (
    state: IResourceTagState,
    {
      params,
      response,
    }: {
      params: Parameters<typeof ResourceTagService.setResourceTags>[0];
      response: ResourceCollection<ResourceTag>;
    }
  ) => {
    if (!state.resourceTags) {
      state.resourceTags = { data: [], meta: {}, links: {} };
    }

    // Removing existing ones.
    state.resourceTags.data = state.resourceTags.data.filter((resourceTag) => {
      return (
        resourceTag.resource_id !== params.resource_id ||
        params.target !== resourceTag.target
      );
    });

    // Adding new ones.
    state.resourceTags.data.push(...response.data);
  },
  [MT.SET_RESOURCE_TAGS_ARE_LOADING]: (state: IResourceTagState) =>
    (state.areResourceTagsLoading = true),
  [MT.SET_RESOURCE_TAGS_ARE_NOT_LOADING]: (state: IResourceTagState) =>
    (state.areResourceTagsLoading = false),
};

/*--------------------------------------------------/
 * Resource Tag Mutations Tree Interface
 *--------------------------------------------------*/
export interface IResourceTagMutations extends MutationTree<IResourceTagState> {
  [MT.SET_RESOURCE_TAGS](
    state: IResourceTagState,
    resourceTags: Paginated<ResourceTag>
  ): void;
  [MT.MERGE_RESOURCE_TAGS](
    state: IResourceTagState,
    args: {
      params: Parameters<typeof ResourceTagService.setResourceTags>[0];
      response: ResourceCollection<ResourceTag>;
    }
  ): void;
  [MT.SET_RESOURCE_TAGS_ARE_LOADING](state: IResourceTagState): void;
  [MT.SET_RESOURCE_TAGS_ARE_LOADING](state: IResourceTagState): void;
}
