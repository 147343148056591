import { InvestmentActions } from './actions';
import { InvestmentGetters } from './getters';
import { InvestmentMutations } from './mutations';
import { InvestmentState, IInvestmentState } from './state';
import { IRootState } from '@/store';
import { Module } from 'vuex';

export const InvestmentModuleStore: IInvestmentModuleStore = {
  state: InvestmentState,
  actions: InvestmentActions,
  getters: InvestmentGetters,
  mutations: InvestmentMutations,
};

/**
 * --------------------------------------------------
 *	Investment Store Interface
 * --------------------------------------------------
 **/
export type IInvestmentModuleStore = Module<IInvestmentState, IRootState>;
