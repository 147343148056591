import { IFiduciaryState } from './state';
import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { Paginated } from '@/types';
import Organisation from '@/models/Organisation';
/**
 * --------------------------------------------------
 *	Fiduciary Getters
 * --------------------------------------------------
 **/
export const FiduciaryGetters: IFiduciaryGetters = {
  fiduciaries: (state: IFiduciaryState): Paginated<Organisation[]> | null =>
    state.fiduciaries,

  fetchingFiduciaries: (state: IFiduciaryState): boolean =>
    state.fetchingFiduciaries,
};

/**
 * --------------------------------------------------
 *	Fiduciary Getters Interface
 * --------------------------------------------------
 **/
export interface IFiduciaryGetters
  extends GetterTree<IFiduciaryState, IRootState> {
  fiduciaries(state: IFiduciaryState): Paginated<Organisation[]> | null;

  fetchingFiduciaries(state: IFiduciaryState): boolean;
}
