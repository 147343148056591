import { FilterPlan } from '@/components/filter/types';
import { SortPlan } from '@/components/sort/types';
import Organisation from '@/models/Organisation';
import OrganizationUser from '@/models/OrganizationUser';
import { IRootState } from '@/store';
import { Paginated, PaginatedParams } from '@/types';
import { ActionContext, ActionTree } from 'vuex';
import { ClientService } from '../services';
import { SaveOrgPreferencesRequest } from '../types';
import * as AT from './actionTypes';
import { SET_CLIENTS, SET_FETCHING_CLIENTS } from './mutationsTypes';
import { IClientState } from './state';

/**
 * --------------------------------------------------
 *  Client Actions
 * --------------------------------------------------
 **/
export const ClientActions: IClientActions = {
  [AT.FETCH_CLIENTS]: async (
    { commit },
    params?: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filterPlan?: FilterPlan | null;
    }>
  ): Promise<Paginated<Organisation>> => {
    commit(SET_FETCHING_CLIENTS, true);

    try {
      const data = await ClientService.fetchClients(params);
      commit(SET_CLIENTS, data);
      return data;
    } catch (e) {
      commit(SET_CLIENTS, []);
      throw e;
    } finally {
      commit(SET_FETCHING_CLIENTS, false);
    }
  },

  [AT.FETCH_ORG_USERS]: async (
    { commit },
    orgId: string
  ): Promise<OrganizationUser[]> => {
    return ClientService.fetchOrgUsers(orgId).then((data) => {
      return data;
    });
  },

  [AT.SAVE_ORG_PREFERENCES]: async (
    { commit },
    saveOrgPreferencesRequest: SaveOrgPreferencesRequest
  ): Promise<Response> => {
    return ClientService.saveOrgPreferences(saveOrgPreferencesRequest).then(
      (data) => {
        return data;
      }
    );
  },
};

/**
 * --------------------------------------------------
 *  Client Actions Interface
 * --------------------------------------------------
 **/
export interface IClientActions extends ActionTree<IClientState, IRootState> {
  [AT.FETCH_CLIENTS](
    context: IClientActionContext,
    params: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filterPlan?: FilterPlan | null;
    }>
  ): Promise<Paginated<Organisation>>;

  [AT.FETCH_ORG_USERS](
    context: IClientActionContext,
    orgId: string
  ): Promise<OrganizationUser[]>;

  [AT.SAVE_ORG_PREFERENCES](
    context: IClientActionContext,
    saveOrgPreferencesRequest: SaveOrgPreferencesRequest
  ): Promise<Response>;
}

/**
 * --------------------------------------------------
 *  Client Action Context Interface
 * --------------------------------------------------
 **/
export type IClientActionContext = ActionContext<IClientState, IRootState>;
