import Auth from '@/packages/Auth';
import { Middleware, MiddlewareNext } from '@/router/middlewares/types';
import { Route } from 'vue-router';
import { RootStore } from '../../store/index';
import { SET_VALIDATING_ENTITY_ACCESS } from '@/modules/dashboard/store/mutationsTypes';
import { HTTPServiceClient } from '@/packages/http/service';
import { VALIDATE_ENTITY_ACCESS_ENDPOINT } from '@/modules/dashboard/endpoints';
import { shouldSkipAccessAuthorization } from '../helpers';

export const AuthorizeTargetAccessMiddleware: Middleware = {
  async handle(
    to: Route,
    from: Route,
    next: MiddlewareNext,
    targetEntity: string
  ) {
    if (shouldSkipAccessAuthorization(to.params)) {
      return;
    }

    RootStore.commit(SET_VALIDATING_ENTITY_ACCESS, true);

    try {
      const canAccessEntity = await HTTPServiceClient.get(
        VALIDATE_ENTITY_ACCESS_ENDPOINT(targetEntity)
      );

      if (!canAccessEntity) {
        return next({
          name: 'auth.login',
        });
      }
    } catch (e) {
      console.log('Error while validating target access');
    } finally {
      RootStore.commit(SET_VALIDATING_ENTITY_ACCESS, false);
    }
  },
};
