export const SET_INVESTMENT = 'submission/SET_INVESTMENT';
export const SET_FETCHING_INVESTMENT = 'submission/SET_FETCHING_INVESTMENT';
export const SET_PROVIDED_TEMPLATE_TYPES =
  'submission/SET_PROVIDED_TEMPLATE_TYPES';
export const SET_TEMPLATE_SOURCES = 'submission/SET_TEMPLATE_SOURCES';
export const SET_TEMPLATE_RESPONSE_SWIFTNESS =
  'submission/SET_TEMPLATE_RESPONSE_SWIFTNESS';
export const SET_TEMPLATE_RESOLUTIONS = 'submission/SET_TEMPLATE_RESOLUTIONS';
export const SET_MANAGER_ASSOCIATION_META =
  'submission/SET_MANAGER_ASSOCIATION';
