import { QueryType } from '@/utils/QueryUtils';
import { merge, sortBy } from 'lodash';
import {
  CustomizerBuilder,
  CustomizerColumnWithOpt,
  CustomizerPlan,
} from './types';

export enum CustomizerType {
  USERS = 'users',
  ASSET_MANAGERS = 'asset-managers',
  CLIENTS = 'clients',
  CONSULTANTS = 'consultants',
  PENSION_SCHEMES = 'pension-schemes',
  RENEWALS = 'renewals',
  DATA_RECON = 'data-recon',
  INVESTMENTS = 'investments',
  FIDUCIARIES = 'fiduciaries',
  QC = 'qc',
}

export class Customizer {
  public static make(builder: Partial<CustomizerBuilder>): CustomizerBuilder {
    return merge({}, { ...Customizer.makeDefaultSchema() }, { ...builder });
  }

  public static makeDefaultSchema(): CustomizerBuilder {
    return {
      useAssetBasedRelativeValue: true,
      frozenColumns: 0,
      itemsPerPage: 15,
      availableColumns: [],
      schema: {
        allowedFrozenColumns: [0, 1, 2, 3],
        allowedItemsPerPage: [15, 30, 60, 120],
      },
    };
  }

  public static rePlan(
    builder: CustomizerBuilder | null = null,
    plan: CustomizerPlan
  ): CustomizerBuilder | null {
    if (!builder) {
      return null;
    }

    return merge(
      {},
      {
        schema: builder.schema,
        ...plan,
      }
    );
  }

  public static reOrderColumns<T extends CustomizerColumnWithOpt>(
    builder: CustomizerBuilder,
    columns: T[],
    orderByKey = 'position'
  ): T[] {
    const columnPositions =
      builder?.availableColumns?.reduce((acc: Record<string, number>, c) => {
        acc[c.slug] = c[orderByKey];
        return acc;
      }, {}) ?? {};

    if (Object.keys(columnPositions).length === 0) {
      return columns;
    }

    const mappedColumns =
      columns?.map((c) => ({
        ...c,
        [orderByKey]:
          columnPositions[c.slug] !== undefined
            ? columnPositions[c.slug]
            : Infinity,
      })) ?? [];

    return sortBy(mappedColumns, [orderByKey]);
  }

  private static getColumnCustomizerKey(): string {
    return 'columnCustomizer';
  }

  public static getColumnCustomizations(): Record<string, any> {
    const columnCustomizations: any = window.localStorage.getItem(
      this.getColumnCustomizerKey()
    );
    return columnCustomizations ? JSON.parse(columnCustomizations) : {};
  }

  public static getInitialColumns(
    type: string,
    defaultColumns: CustomizerColumnWithOpt[]
  ): CustomizerColumnWithOpt[] {
    const storedCustomisation = this.getColumnCustomizationsForType(type);
    const storedColumns: any[] = storedCustomisation?.availableColumns;

    if (!storedColumns?.length) {
      return defaultColumns;
    }

    return storedColumns
      .map((storedCol) =>
        defaultColumns.find((defaultCol) => defaultCol.slug === storedCol.slug)
      )
      .filter((c): c is CustomizerColumnWithOpt => !!c);
  }

  public static getColumnCustomizationsForType(type: string): any {
    const columnCustomizations = this.getColumnCustomizations();
    return columnCustomizations[type] ?? null;
  }

  public static setColumnCustomizations(
    columnCustomizations: Record<string, any>
  ): void {
    window.localStorage.setItem(
      Customizer.getColumnCustomizerKey(),
      JSON.stringify(columnCustomizations)
    );
  }

  public static resolvePlanFromQuery(
    query: Record<string, any>,
    type: string,
    builder: CustomizerBuilder | null = null
  ): CustomizerBuilder {
    const columnCustomizations = this.getColumnCustomizationsForType(type);
    const limit = query[QueryType.LIMIT];
    return this.make({
      ...builder,
      itemsPerPage: limit ? parseInt(limit) : builder?.itemsPerPage,
      useAssetBasedRelativeValue:
        columnCustomizations?.useAssetBasedRelativeValue,
      frozenColumns: columnCustomizations
        ? columnCustomizations['frozenColumns']
        : builder?.frozenColumns,
      availableColumns: columnCustomizations?.availableColumns?.length
        ? this.reOrderColumns(
            {
              availableColumns: columnCustomizations.availableColumns,
            } as CustomizerBuilder,
            builder?.availableColumns || []
          )
        : builder?.availableColumns,
    });
  }
}
