import * as t from '@/modules/auth/endpoints';
import { HTTPServiceClient } from '@/packages/http/service';
import { Google2FAVerifyForm, LoginFormData, LoginResponseData } from './types';

/**
 * --------------------------------------------------
 *  Authentication HTTP Service
 * --------------------------------------------------
 **/
export const AuthService = {
  /**
   * Log in a User.
   * @param data {LoginFormData} Credentials from the login form.
   * @returns {Promise<LoginResponseData>} Auth Token and User Data.
   */
  async login(data: LoginFormData): Promise<any> {
    const res = await HTTPServiceClient.post(t.LOGIN_ENDPOINT, {
      email: data.email,
      password: data.password,
    });

    return res.data;
  },

  /**
   * Verify 2FA token.
   * @param formData {Google2FAVerifyForm} .
   * @returns {Promise<any>} Auth Token and User Data.
   */
  async verify2FA(formData: Google2FAVerifyForm): Promise<any> {
    const { data } = await HTTPServiceClient.post(
      t.ADMIN_G2FA_VERIFICATION_ENDPOINT,
      formData
    );
    return data;
  },
};
