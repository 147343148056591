import { SortPlan } from '@/components/sort/types';
import { AdminUser } from '@/models/AdminUser';
import Fund from '@/models/Fund';
import FundNature from '@/models/FundNature';
import ProductType from '@/models/ProductType';
import Strategy from '@/models/Strategy';
import Template from '@/models/Template';
import TimePeriod from '@/models/TimePeriod';
import { HTTPServiceClient } from '@/packages/http/service';
import { APIErrorResponse } from '@/packages/http/types';
import { Paginated, PaginatedParams } from '@/types';
import ErrorUtils from '@/utils/ErrorUtils';
import * as t from './endpoints';
import {
  MapFiltersToQuery,
  MapRenewalsFiltersToQuery,
  ResolveExportDataParams,
} from './helper';
import {
  DownloadDataResponse,
  DownloadTemplateResponse,
  ExportDataRequest,
  ExportDataResponse,
  FundFilters,
  FundInvestment,
  ImpersonateClientResponse,
  ImportFundsRequest,
  ImportOnboardingSheetRequest,
  MergeEntityIdsRequest,
  SendEmailsRequest,
  SendSubmissionRequestsRequest,
  UpdateFundsRequest,
  UpdateInvestmentRequest,
  UpdateRollupTagRequest,
} from './types';
import { ExportType } from '@/utils/InvestmentUtils';

/**
 * --------------------------------------------------
 *	Investments HTTP Service
 * --------------------------------------------------
 **/
export const InvestmentService = {
  /**
   * Fetches all the fund investments.
   * @returns {Promise<Paginated<FundInvestment>>}
   */
  async fetchFundInvestments(
    props?: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filters?: FundFilters | null;
    }>
  ): Promise<Paginated<FundInvestment>> {
    try {
      const filterParamString = props?.filters
        ? MapFiltersToQuery(props?.filters)
        : '';

      const sortParams = {
        sort: '-subtreeSubmissionCreatedAt',
      };

      delete props?.sortPlan;
      delete props?.filters;

      const params = { ...props, ...sortParams };
      const { data } = await HTTPServiceClient.get<Paginated<FundInvestment>>(
        t.FETCH_FUNDS_INVESTMENTS_ENDPOINT(filterParamString),
        {
          params,
        }
      );
      return data ?? {};
    } catch (e) {
      throw new Error(ErrorUtils.getErrorMessage(e as APIErrorResponse));
    }
  },

  /**
   * Fetches all the subfunds.
   * @returns {Promise<Fund[]>}
   */
  async fetchSubFunds(): Promise<Fund[]> {
    try {
      const { data } = await HTTPServiceClient.get<Paginated<Fund>>(
        t.FETCH_SUB_FUNDS
      );
      return data.data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Fetches all the strategies.
   * @returns {Promise<Strategy[]>}
   */
  async fetchStrategies(): Promise<Strategy[]> {
    try {
      const { data } = await HTTPServiceClient.get<Paginated<Strategy>>(
        t.FETCH_STRATEGIES
      );
      return data.data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Fetches all the fund natures.
   * @returns {Promise<FundNature[]>}
   */
  async fetchFundNatures(): Promise<FundNature[]> {
    try {
      const { data } = await HTTPServiceClient.get<Paginated<FundNature>>(
        t.FETCH_FUND_NATURES
      );
      return data.data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Fetches all the templates.
   * @returns {Promise<Template[]>}
   */
  async fetchTemplates(): Promise<Template[]> {
    try {
      const { data } = await HTTPServiceClient.get<Paginated<Template>>(
        t.FETCH_TEMPLATES
      );
      return data.data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Fetches all the time periods.
   * @returns {Promise<TimePeriod[]>}
   */
  async fetchTimePeriods(): Promise<TimePeriod[]> {
    try {
      const { data } = await HTTPServiceClient.get<Paginated<TimePeriod>>(
        t.FETCH_TIME_PERIODS
      );
      return data.data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Export Data templates/funds.
   * @returns {Promise<ExportDataResponse>}
   */
  async exportData(request: ExportDataRequest): Promise<ExportDataResponse> {
    try {
      let filterParamString;
      if (request.exportType === ExportType.RENEWALS) {
        filterParamString = request?.appliedFilters
          ? MapRenewalsFiltersToQuery(request?.appliedFilters)
          : '';
      } else {
        filterParamString = request?.appliedFilters
          ? MapFiltersToQuery(request?.appliedFilters)
          : '';
      }
      const params = ResolveExportDataParams(request);

      const { data } = await HTTPServiceClient.get<ExportDataResponse>(
        t.EXPORT_DATA(filterParamString),
        { params }
      );
      return data ?? null;
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Download Data templates/funds.
   * @returns {Promise<DownloadDataResponse>}
   */
  async downloadData(jobId: string): Promise<DownloadDataResponse> {
    try {
      const { data } = await HTTPServiceClient.get<DownloadDataResponse>(
        t.DOWNLOAD_DATA(jobId)
      );
      return data ?? null;
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Recollect Data.
   * @returns {Promise<Response>}
   */
  async recollectData(investmentIds: string[]): Promise<Response> {
    try {
      const { data } = await HTTPServiceClient.post<Response>(
        t.RECOLLECT_DATA,
        {
          investmentIds,
        }
      );
      return data ?? null;
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Send Email.
   * @returns {Promise<Response>}
   */
  async sendEmails(sendEmailRequest: SendEmailsRequest): Promise<Response> {
    try {
      const { data } = await HTTPServiceClient.post<Response>(
        t.SEND_EMAILS,
        sendEmailRequest
      );
      return data ?? null;
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Send Submission Requests.
   * @returns {Promise<Response>}
   */
  async sendSubmissionRequests(
    request: SendSubmissionRequestsRequest
  ): Promise<Response> {
    try {
      const body = new FormData();
      body.append('dueDate', request.dueDate);
      request.investmentIds.forEach((investmentId) => {
        body.append('investmentIds[]', investmentId);
      });

      body.append('authorizationLetter', request.authorizationLetter);
      body.append(
        'notificationsEnabled',
        request.notificationsEnabled ? '1' : '0'
      );
      body.append('groupInvestmentsInMailBy', request.groupInvestmentsInMailBy);
      const { data } = await HTTPServiceClient.post<Response>(
        t.SEND_SUBMISSION_REQUESTS,
        body,
        {
          headers: {
            'Content-Type': 'application/form-data',
          },
        }
      );
      return data ?? null;
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Fetches all the product types.
   * @returns {Promise<ProductType[]>}
   */
  async fetchProductTypes(): Promise<ProductType[]> {
    try {
      const { data } = await HTTPServiceClient.get<Paginated<ProductType>>(
        t.FETCH_PRODUCT_TYPES
      );
      return data.data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Update Funds.
   * @returns {Promise<Response>}
   */
  async updateFunds(updateFundsRequest: UpdateFundsRequest): Promise<Response> {
    try {
      const { data } = await HTTPServiceClient.post<Response>(
        t.UPDATE_FUNDS,
        updateFundsRequest
      );
      return data ?? null;
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Import Funds.
   * @returns {Promise<Response>}
   */
  async importFunds(importFundsRequest: ImportFundsRequest): Promise<Response> {
    try {
      const body = new FormData();
      if (importFundsRequest?.fiduciary) {
        body.append('fiduciary', importFundsRequest.fiduciary);
      }
      if (importFundsRequest?.consultant) {
        body.append('consultant', importFundsRequest.consultant);
      }
      if (importFundsRequest?.contactEmail) {
        body.append('contactEmail', importFundsRequest.contactEmail);
        body.append('contactName', importFundsRequest.contactName);
      }
      body.append('file', importFundsRequest.file);

      const { data } = await HTTPServiceClient.post<Response>(
        t.IMPORT_FUNDS,
        body,
        {
          headers: {
            'Content-Type': 'application/form-data',
          },
        }
      );
      return data ?? null;
    } catch (e) {
      throw e;
    }
  },

  /**
   * Import Onboarding Sheet.
   * @returns {Promise<Response>}
   */
  async importOnboardingSheet(
    importOnboardingSheetRequest: ImportOnboardingSheetRequest
  ): Promise<Response> {
    try {
      const body = new FormData();
      body.append('file', importOnboardingSheetRequest.file);

      if (
        importOnboardingSheetRequest.currency &&
        importOnboardingSheetRequest.countryId
      ) {
        body.append('currency', importOnboardingSheetRequest.currency);
        body.append('countryId', importOnboardingSheetRequest.countryId);
      }

      const { data } = await HTTPServiceClient.post<Response>(
        t.IMPORT_ONBOARDING_SHEET,
        body,
        {
          headers: {
            'Content-Type': 'application/form-data',
          },
        }
      );
      return data ?? null;
    } catch (e) {
      throw e;
    }
  },

  /**
   * Download Submission Template.
   * @returns {Promise<DownloadTemplateResponse>}
   */
  async downloadSubmissionTemplate(
    submissionId: string
  ): Promise<DownloadTemplateResponse> {
    try {
      const { data } = await HTTPServiceClient.get<DownloadTemplateResponse>(
        t.DOWNLOAD_SUBMISSION_TEMPLATE(submissionId)
      );
      return data ?? null;
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Impersonate Client.
   * @returns {Promise<ImpersonateClientResponse>}
   */
  async impersonateClient(
    investmentId: string
  ): Promise<ImpersonateClientResponse> {
    try {
      const { data } = await HTTPServiceClient.get<ImpersonateClientResponse>(
        t.IMPERSONATE_CLIENT(investmentId)
      );
      return data ?? null;
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Fetch admin users.
   * @returns {Promise<AdminUser[]>}
   */
  async fetchAdminUsers(): Promise<AdminUser[]> {
    try {
      const { data } = await HTTPServiceClient.get<Paginated<AdminUser>>(
        t.FETCH_ADMIN_USERS
      );
      return data?.data ?? null;
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Retract Submission Request.
   * @returns {Promise<Response>}
   */
  async retractRequest(submissionRequestIds: string[]): Promise<Response> {
    try {
      const { data } = await HTTPServiceClient.post<Response>(
        t.RETRACT_SUBMISSION_REQUESTS,
        {
          submissionRequestIds,
        }
      );
      return data ?? null;
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  async mergeEntityIds(request: MergeEntityIdsRequest): Promise<Response> {
    try {
      const { data } = await HTTPServiceClient.put<Response>(
        t.MERGE_ENTITY_IDS,
        request
      );
      return data ?? null;
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  async updateRollupTag(request: UpdateRollupTagRequest): Promise<Response> {
    try {
      const { data } = await HTTPServiceClient.put<Response>(
        t.UPDATE_ROLLUP_TAG,
        request
      );
      return data ?? null;
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  async updateInvestment(
    invId: string,
    updateInvRequest: UpdateInvestmentRequest
  ): Promise<Response> {
    try {
      const { data } = await HTTPServiceClient.patch<Response>(
        t.UPDATE_INVESTMENT(invId),
        updateInvRequest
      );
      return data ?? null;
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },
};
