import { IRootState } from '@/store';
import { Paginated, PaginatedParams } from '@/types';
import { ActionContext, ActionTree } from 'vuex';
import { SortPlan } from '@/components/sort/types';
import { FundCost } from '@/models/Analysis';
import Fund from '../../../models/Fund';
import FundNature from '../../../models/FundNature';
import Investment from '../../../models/Investment';
import Organisation from '../../../models/Organisation';
import ProductType from '../../../models/ProductType';
import Strategy from '../../../models/Strategy';
import Template from '../../../models/Template';
import TimePeriod from '../../../models/TimePeriod';
import { AnalysisService } from '../services';
import {
  AnalysisFundFilters,
  AnalysisParam,
  AnalysisParamWithFilter,
  ReportExportRequest,
  ClientsParam,
  ExcelReportParam,
  FundAnalysisParam,
  GeneratedReportDetails,
  ReportResponse,
  RootDetail,
  SubFund,
} from '../types';
import * as AT from './actionTypes';
import * as MT from './mutationsTypes';
import { IAnalysisState } from './state';

/**
 * --------------------------------------------------
 *  Analysis Actions
 * --------------------------------------------------
 **/
export const AnalysisActions: IAnalysisActions = {
  [AT.FETCH_PAGINATED_FUND_ANALYSIS]: async (
    { commit },
    params?: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filters?: AnalysisFundFilters | null;
    }>
  ): Promise<Paginated<FundCost>> => {
    try {
      const analysis = await AnalysisService.getFundAnalysis(params);
      commit(MT.SET_PAGINATED_FUND_ANALYSIS, analysis);
      return analysis;
    } catch (e) {
      commit(MT.SET_PAGINATED_FUND_ANALYSIS, []);
      throw e;
    }
  },

  [AT.FETCH_ROOT_DETAILS]: (
    { commit },
    params?: AnalysisParam
  ): Promise<Paginated<RootDetail>> => {
    // TODO - Pagination Params for Root Details API
    const queryParams = {} as AnalysisParamWithFilter;
    return AnalysisService.getRootDetails(queryParams).then(
      (rootDetails: Paginated<RootDetail>) => {
        const filteredClients = rootDetails?.data?.filter(
          (it) => it?.organizationType === 'Pension Scheme'
        );
        const filteredAssetManagers = rootDetails?.data?.filter(
          (it) => it?.organizationType === 'Asset Manager'
        );
        const rootClients = rootDetails?.data?.filter(
          (it) =>
            // it?.organizationType === 'Pension Scheme' ||
            it?.organizationType === 'Consultant' ||
            it?.organizationType === 'Provider' ||
            it?.organizationType === 'Fiduciary'
        );
        rootDetails.data = rootClients;

        commit(MT.SET_ORG_CLIENTS, filteredClients);
        commit(MT.SET_ASSET_MANAGERS, filteredAssetManagers);
        commit(MT.SET_ROOT_DETAILS, rootDetails);
        return rootDetails;
      }
    );
  },

  [AT.FETCH_SUB_FUNDS_ORG_IDS]: (
    { commit },
    params: ClientsParam
  ): Promise<SubFund[]> => {
    return AnalysisService.getSubFundsByOrgIds(params).then(
      (data: SubFund[]) => {
        commit(MT.SET_ORGANIZATION_SUB_FUNDS, data);
        return data;
      }
    );
  },

  [AT.FETCH_INVESTMENTS_BY_ORG_IDS]: (
    { commit },
    params: ClientsParam
  ): Promise<Investment[]> => {
    return AnalysisService.fetchSchemesByOrgIds(params).then(
      (data: Investment[]) => {
        commit(MT.SET_INVESTMENTS, data);
        return data;
      }
    );
  },

  [AT.FETCH_CLIENTS_BY_ORG]: (
    { commit },
    params?: AnalysisParam
  ): Promise<Organisation[]> => {
    const queryParams = {
      ...params,
    } as AnalysisParamWithFilter;
    return AnalysisService.getClientsByOrgId(queryParams).then(
      (clients: Organisation[]) => {
        const filteredClients = clients?.filter(
          (it) =>
            it?.entity?.organizationType?.slug === 'pension-scheme' ||
            it?.entity?.organizationType?.slug === 'consultant'
        );
        commit(MT.SET_ORG_CLIENTS, filteredClients);
        return filteredClients;
      }
    );
  },

  [AT.GENERATE_DATA_RECON_EXCEL_REPORT]: (
    { commit },
    params: ReportExportRequest
  ): Promise<ReportResponse> => {
    if (!params?.reportType || !params?.page || !params?.limit) {
      throw Error('Bad Export Request');
    }
    const reportReqParams: ExcelReportParam = {
      planType: params?.planType ?? 'admin',
      reportType: params?.reportType,
      params,
    };
    return AnalysisService.generateDataReconReportExport(reportReqParams).then(
      (data: ReportResponse) => {
        return data;
      }
    );
  },

  [AT.SEND_REPORT_EXPORT_REQUEST]: (
    { commit },
    params: ReportExportRequest
  ): Promise<ReportResponse> => {
    return AnalysisService.generateDataReconReportExport(params).then(
      (data: ReportResponse) => {
        return data;
      }
    );
  },

  [AT.DOWNLOAD_DATA_RECON_REPORT]: (
    { commit },
    downloadReportRequest
  ): Promise<GeneratedReportDetails> => {
    return AnalysisService.downloadReport(downloadReportRequest).then(
      (generatedReportDetails: GeneratedReportDetails) => {
        commit(MT.SET_GENERATED_REPORT_DETAILS, generatedReportDetails);
        return generatedReportDetails;
      }
    );
  },

  [AT.FETCH_SUB_FUNDS]: async ({ commit }): Promise<Fund[]> => {
    return AnalysisService.fetchSubFunds().then((data) => {
      commit(MT.SET_SUB_FUNDS, data);
      return data;
    });
  },

  [AT.FETCH_FUND_NATURES]: async ({ commit }): Promise<FundNature[]> => {
    return AnalysisService.fetchFundNatures().then((data) => {
      commit(MT.SET_FUND_NATURES, data);
      return data;
    });
  },

  [AT.FETCH_STRATEGIES]: async ({ commit }): Promise<Strategy[]> => {
    return AnalysisService.fetchStrategies().then((data) => {
      commit(MT.SET_STRATEGIES, data);
      return data;
    });
  },

  [AT.FETCH_TEMPLATES]: async ({ commit }): Promise<Template[]> => {
    return AnalysisService.fetchTemplates().then((data) => {
      commit(MT.SET_TEMPLATES, data);
      return data;
    });
  },

  [AT.FETCH_PRODUCT_TYPES]: ({ commit }): Promise<ProductType[]> => {
    const queryParams = null;
    return AnalysisService.fetchProductTypes(queryParams).then(
      (allProductTypes: ProductType[]) => {
        commit(MT.SET_PRODUCT_TYPES, allProductTypes);
        return allProductTypes;
      }
    );
  },

  [AT.FETCH_TIME_PERIODS]: ({ commit }): Promise<TimePeriod[]> => {
    const queryParams = null;
    return AnalysisService.fetchTimePeriods(queryParams).then(
      (allTimePeriods: TimePeriod[]) => {
        commit(MT.SET_TIME_PERIODS, allTimePeriods);
        return allTimePeriods;
      }
    );
  },
};

/**
 * --------------------------------------------------
 *  Analysis Actions Interface
 * --------------------------------------------------
 **/
export interface IAnalysisActions
  extends ActionTree<IAnalysisState, IRootState> {
  [AT.FETCH_PAGINATED_FUND_ANALYSIS](
    context: IAnalysisActionContext,
    params: FundAnalysisParam
  ): Promise<Paginated<FundCost>>;

  [AT.FETCH_ROOT_DETAILS](
    context: IAnalysisActionContext,
    params: AnalysisParam
  ): Promise<Paginated<RootDetail>>;

  [AT.FETCH_SUB_FUNDS_ORG_IDS](
    context: IAnalysisActionContext,
    params: ClientsParam
  ): Promise<SubFund[]>;

  [AT.FETCH_INVESTMENTS_BY_ORG_IDS](
    context: IAnalysisActionContext,
    params: ClientsParam
  ): Promise<Investment[]>;

  [AT.FETCH_CLIENTS_BY_ORG](
    context: IAnalysisActionContext,
    params: AnalysisParam
  ): Promise<Organisation[]>;

  [AT.GENERATE_DATA_RECON_EXCEL_REPORT](
    context: IAnalysisActionContext,
    generateReportRequest: ReportExportRequest
  ): Promise<ReportResponse>;

  [AT.SEND_REPORT_EXPORT_REQUEST](
    context: IAnalysisActionContext,
    request: ReportExportRequest
  ): Promise<ReportResponse>;

  [AT.DOWNLOAD_DATA_RECON_REPORT](
    context: IAnalysisActionContext,
    downloadReportRequest: ReportResponse
  ): Promise<GeneratedReportDetails>;

  [AT.FETCH_PRODUCT_TYPES](
    context: IAnalysisActionContext
  ): Promise<ProductType[]>;
  [AT.FETCH_TIME_PERIODS](
    context: IAnalysisActionContext
  ): Promise<TimePeriod[]>;
  [AT.FETCH_FUND_NATURES](
    context: IAnalysisActionContext
  ): Promise<FundNature[]>;
  [AT.FETCH_STRATEGIES](context: IAnalysisActionContext): Promise<Strategy[]>;
  [AT.FETCH_TEMPLATES](context: IAnalysisActionContext): Promise<Template[]>;
}

/**
 * --------------------------------------------------
 *  Analysis Action Context Interface
 * --------------------------------------------------
 **/
export type IAnalysisActionContext = ActionContext<IAnalysisState, IRootState>;
