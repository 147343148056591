import { FilterPlan } from '@/components/filter/types';
import { SortPlan } from '@/components/sort/types';
import { User } from '@/models/User';
import { IRootState } from '@/store';
import { Paginated, PaginatedParams } from '@/types';
import { ActionContext, ActionTree } from 'vuex';
import { UserService } from '../services';
import {
  ImpersonateUserResponse,
  ResetUserPasswordRequest,
  UpdateEmailSettingRequest,
} from '../types';
import * as AT from './actionTypes';
import { SET_FETCHING_USERS, SET_USERS } from './mutationsTypes';
import { IUserState } from './state';

/**
 * --------------------------------------------------
 *  User Actions
 * --------------------------------------------------
 **/
export const UserActions: IUserActions = {
  [AT.FETCH_USERS]: async (
    { commit },
    params?: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filterPlan?: FilterPlan | null;
    }>
  ): Promise<Paginated<User>> => {
    commit(SET_FETCHING_USERS, true);

    try {
      const data = await UserService.fetchUsers(params);
      commit(SET_USERS, data);
      return data;
    } catch (e) {
      commit(SET_USERS, []);
      throw e;
    } finally {
      commit(SET_FETCHING_USERS, false);
    }
  },

  [AT.IMPERSONATE_USER]: async (
    { commit },
    userId: string
  ): Promise<ImpersonateUserResponse> => {
    return UserService.impersonateUser(userId).then((data) => {
      return data;
    });
  },

  [AT.RESET_USER_PASSWORD]: async (
    { commit },
    email: string
  ): Promise<Response> => {
    return UserService.resetUserPassword(email).then((data) => {
      return data;
    });
  },

  [AT.UPDATE_USER_EMAIL_SETTINGS]: async (
    { commit },
    updateUserEmailSettings: UpdateEmailSettingRequest
  ): Promise<Response> => {
    return UserService.updateUserEmailSettings(updateUserEmailSettings).then(
      (data) => {
        return data;
      }
    );
  },
};

/**
 * --------------------------------------------------
 *  User Actions Interface
 * --------------------------------------------------
 **/
export interface IUserActions extends ActionTree<IUserState, IRootState> {
  [AT.FETCH_USERS](
    context: IUserActionContext,
    params: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filterPlan?: FilterPlan | null;
    }>
  ): Promise<Paginated<User>>;
  [AT.IMPERSONATE_USER](
    context: IUserActionContext,
    userId: string
  ): Promise<ImpersonateUserResponse>;
  [AT.RESET_USER_PASSWORD](
    context: IUserActionContext,
    email: string
  ): Promise<Response>;
  [AT.UPDATE_USER_EMAIL_SETTINGS](
    context: IUserActionContext,
    updateUserEmailSettings: UpdateEmailSettingRequest
  ): Promise<Response>;
}

/**
 * --------------------------------------------------
 *  User Action Context Interface
 * --------------------------------------------------
 **/
export type IUserActionContext = ActionContext<IUserState, IRootState>;
