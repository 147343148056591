import { IAdminUserState } from './state';
import { MutationTree } from 'vuex';
import * as MT from './mutationsTypes';
import { AdminUser } from '@/models/AdminUser';

/*--------------------------------------------------/
 * Admin User Mutations
 *--------------------------------------------------*/
export const AdminUserMutations: IAdminUserMutations = {
  [MT.SET_ADMIN_USERS]: (state: IAdminUserState, adminUsers: AdminUser[]) =>
    (state.adminUsers = adminUsers),
};

/*--------------------------------------------------/
 * Admin User Mutations Tree Interface
 *--------------------------------------------------*/
export interface IAdminUserMutations extends MutationTree<IAdminUserState> {
  [MT.SET_ADMIN_USERS](state: IAdminUserState, adminUsers: AdminUser[]): void;
}
