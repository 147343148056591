import { AdminUserActions } from './actions';
import { AdminUserGetters } from './getters';
import { AdminUserMutations } from './mutations';
import { IAdminUserState, AdminUserState } from './state';
import { IRootState } from '@/store';
import { Module } from 'vuex';

export const AdminUserModuleStore: IAdminUserModuleStore = {
  state: AdminUserState,
  actions: AdminUserActions,
  getters: AdminUserGetters,
  mutations: AdminUserMutations,
};

/**
 * --------------------------------------------------
 *	Admin User Store Interface
 * --------------------------------------------------
 **/
export type IAdminUserModuleStore = Module<IAdminUserState, IRootState>;
