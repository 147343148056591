import { IFiduciaryState } from './state';
import { MutationTree } from 'vuex';
import * as MT from './mutationsTypes';
import { Paginated } from '@/types';
import Organisation from '@/models/Organisation';

/*--------------------------------------------------/
 * Fiduciary Mutations
 *--------------------------------------------------*/
export const FiduciaryMutations: IFiduciaryMutations = {
  [MT.SET_FIDUCIARIES]: (
    state: IFiduciaryState,
    fiduciaries: Paginated<Organisation[]>
  ) => (state.fiduciaries = fiduciaries),

  [MT.SET_FETCHING_FIDUCIARIES]: (state: IFiduciaryState, flag: boolean) =>
    (state.fetchingFiduciaries = flag),
};

/*--------------------------------------------------/
 * Fiduciary Mutations Tree Interface
 *--------------------------------------------------*/
export interface IFiduciaryMutations extends MutationTree<IFiduciaryState> {
  [MT.SET_FIDUCIARIES](
    state: IFiduciaryState,
    fiduciaries: Paginated<Organisation[]>
  ): void;

  [MT.SET_FETCHING_FIDUCIARIES](state: IFiduciaryState, flag: boolean): void;
}
