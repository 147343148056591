var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { class: _vm.labelClass }, [_vm._v(_vm._s(_vm.label))]),
      _c("v-autocomplete", {
        class: _vm.elementClass,
        attrs: {
          "data-testid": _vm.testId,
          items: _vm.itemsList,
          value: _vm.value,
          "item-text": _vm.itemText,
          "item-value": _vm.itemValue,
          placeholder: _vm.placeholderLabel,
          "hide-details": "",
          solo: "",
          multiple: _vm.multiple,
          error: _vm.error.length > 0,
          "error-messages": _vm.errorMessage,
          clearable: "",
          "menu-props": { bottom: true, offsetY: true },
          loading: _vm.loading,
          disabled: _vm.disabled,
          dense: "",
        },
        on: { change: _vm.handleValueChange },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function ({ item, index }) {
              return [
                index === 0
                  ? _c(
                      "v-chip",
                      {
                        staticClass:
                          "text-capitalize caption font-weight-medium",
                        attrs: { "x-small": "" },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "text-truncate",
                            style: _vm.chipStyle,
                          },
                          [_vm._v(_vm._s(item[_vm.itemText]))]
                        ),
                      ]
                    )
                  : _vm._e(),
                index === 1
                  ? _c("span", { staticClass: "body-2 font-weight-bold" }, [
                      _vm._v("(+" + _vm._s(_vm.value.length - 1) + ")"),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }