import { BASE } from '../../utils/service-providers';
import { summaryApiParam } from './types';

export const INVESTMENT_ENDPOINT = (investment_id?: string) =>
  `${BASE}/admin/investments/${investment_id ? investment_id : ''}`;

export const MANAGER_ASSOCIATION_META_ENDPOINT = (investment_id?: string) =>
  `${INVESTMENT_ENDPOINT(investment_id)}/manager-association-meta`;

export const SUBMISSION_ENDPOINT = (submission_id?: string) =>
  `${BASE}/admin/submissions/${submission_id ? submission_id : ''}`;

export const SUBMISSION_REQUEST_ENDPOINT = (subReqId?: string) =>
  `${BASE}/admin/submission-requests${subReqId ? '/' + subReqId : ''}`;
export const SUBMISSION_ERRORS_ENDPOINT = (submission_id: string) =>
  `${SUBMISSION_ENDPOINT(submission_id)}/errors`;
export const ERROR_REPORT_DOWNLOAD_ENDPOINT = (submission_id: string) =>
  `${SUBMISSION_ENDPOINT(submission_id)}/error-report`;
export const SUBMISSION_BYPASSABLE_ERRORS_ENDPOINT = (submission_id: string) =>
  `${SUBMISSION_ENDPOINT(submission_id)}/bypassable-errors`;
export const SUBMISSION_REQUEST_REOPEN_ENDPOINT = (subReqId?: string) =>
  `${SUBMISSION_REQUEST_ENDPOINT(subReqId)}/reopen`;
export const FETCH_SUBMISSION_FOR_BATCH = (batch_id?: string) =>
  `${BASE}/admin/submissions/batch/${batch_id ? batch_id : ''}`;
export const DOWNLOAD_ERROR_REPORTS_FOR_BATCH = (batch_id?: string) =>
  `${BASE}/admin/submissions/${batch_id ? batch_id : ''}/error-reports`;
export const FETCH_ERROR_REPORTS_URL_FOR_BATCH = (job_id?: string) =>
  `${BASE}/admin/submissions/${job_id ? job_id : ''}/error-reports/download`;

export const EDIT_SUBMISSION_STATUS_ENDPOINT = (submission_id: string) =>
  `${SUBMISSION_ENDPOINT(submission_id)}/status`;

export const FETCH_COST_SUMMARY = (params: summaryApiParam) => {
  const urlParams = new URLSearchParams();

  if (params.investmentId?.length) {
    params.investmentId.forEach((it) => {
      urlParams.append('id[]', it);
    });
  }
  if (params.fundIds?.length) {
    params.fundIds.forEach((it) => {
      urlParams.append('fundIds[]', it);
    });
  }
  if (params.submissionIds?.length) {
    params.submissionIds.forEach((it) => {
      urlParams.append('submissionIds[]', it);
    });
  }
  if (params.source) {
    urlParams.set('source', params.source.toString());
  }
  if (params?.rootOrgId) {
    urlParams.set('rootOrgId', params.rootOrgId.toString());
  }
  if (params?.drilldownLevel) {
    urlParams.set('drilldownLevel', params.drilldownLevel.toString());
  }
  return `analysis/reporting/summary?` + urlParams.toString();
};

export const FETCH_SUBMISSION_SUMMARY = (submissionId: string) => {
  return `sherlock/submissions/${submissionId}/summary`;
};

export const DOWNLOAD_TEMPLATE = (submissionId: string) =>
  `base/admin/submissions/${submissionId}/download`;

export const SAVE_NOTES = `base/admin/notes`;
export const FETCH_PROVIDED_TEMPLATE_TYPES = `base/admin/templates/types`;
export const FETCH_TEMPLATE_SOURCES = `base/admin/templates/sources`;
export const FETCH_TEMPLATE_RESPONSE_SWIFTNESS = `base/admin/templates/response-swiftness`;
export const FETCH_TEMPLATE_RESOLUTIONS = `base/admin/templates/resolutions`;
