const Page404 = () => import('../../pages/Page404.vue');
import { RouteConfig } from 'vue-router';

export const AppRoutes: RouteConfig[] = [
  {
    path: '/',
    name: 'index',
    redirect: { name: 'auth.login' },
    meta: {
      middlewares: {
        requiresGuest: true,
      },
    },
  },
  {
    path: '/404',
    alias: '*',
    name: 'Page404',
    component: Page404,
  },
];
