import { IConsultantState } from './state';
import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { Paginated } from '@/types';
import Organisation from '@/models/Organisation';
/**
 * --------------------------------------------------
 *	Consultant Getters
 * --------------------------------------------------
 **/
export const ConsultantGetters: IConsultantGetters = {
  consultants: (state: IConsultantState): Paginated<Organisation[]> | null =>
    state.consultants,

  fetchingConsultants: (state: IConsultantState): boolean =>
    state.fetchingConsultants,
};

/**
 * --------------------------------------------------
 *	Consultant Getters Interface
 * --------------------------------------------------
 **/
export interface IConsultantGetters
  extends GetterTree<IConsultantState, IRootState> {
  consultants(state: IConsultantState): Paginated<Organisation[]> | null;

  fetchingConsultants(state: IConsultantState): boolean;
}
