import { TARGET_ENTITIES } from '@/utils/TargetEntity';
import { RouteConfig } from 'vue-router';
const RenewalsModule = () =>
  import(/* webpackChunkName: 'renewals' */ './RenewalsModule.vue');
const ListRenewalsPage = () =>
  import(/* webpackChunkName: 'renewals' */ './pages/ListRenewalsPage.vue');

export const RenewalsRoutes: RouteConfig[] = [
  {
    path: 'renewals',
    component: RenewalsModule,
    meta: {
      middlewares: {
        canAccessEntity: TARGET_ENTITIES.listPensionSchemes,
      },
    },
    children: [
      {
        path: 'list',
        name: 'renewals.list',
        component: ListRenewalsPage,
      },
    ],
  },
];
