import { IAuthState } from '@/modules/auth/store/state';
import { MutationTree } from 'vuex';

/*--------------------------------------------------/
 * Auth Mutations
 *--------------------------------------------------*/
export const AuthMutations: IAuthMutations = {};

/*--------------------------------------------------/
 * Auth Mutations Tree Interface
 *--------------------------------------------------*/
export type IAuthMutations = MutationTree<IAuthState>;
