import { Filter } from '@/components/filter/Filter';
import { FilterPlan } from '@/components/filter/types';
import { Sort } from '@/components/sort/Sort';
import { SortPlan } from '@/components/sort/types';
import { Tag } from '@/models/Tag';
import { HTTPServiceClient } from '@/packages/http/service';
import { APIErrorResponse } from '@/packages/http/types';
import { Paginated, PaginatedParams, Resource } from '@/types';
import * as t from './endpoints';
import { CreateTagData } from './types';

/**
 * --------------------------------------------------
 *	Tag HTTP Serice
 * --------------------------------------------------
 **/
export const TagService = {
  /**
   * Fetches all the resource tags.
   * @returns {Promise<Paginated<Tag>>}
   */
  async fetchTags(
    props?: PaginatedParams<{
      include?: string[] | null;
      sortPlan?: SortPlan | null;
      filterPlan?: FilterPlan | null;
    }>
  ): Promise<Paginated<Tag>> {
    try {
      const filterParams = {
        ...(props?.filterPlan ? Filter.toQuery(props?.filterPlan) : {}),
      };
      const sortParams = {
        ...(props?.sortPlan ? Sort.toQuery(props?.sortPlan) : {}),
      };

      delete props?.sortPlan;
      delete props?.filterPlan;

      const params = {
        ...props,
        ...sortParams,
        ...filterParams,
      };
      const { data } = await HTTPServiceClient.get<Paginated<Tag>>(
        t.FETCH_TAGS_ENDPOINT,
        {
          params,
        }
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },
  /**
   * Fetches all the qc related tags.
   * @returns {Promise<Paginated<Tag>>}
   */
  async fetchQCTags(
    props?: PaginatedParams<{
      include?: string[] | null;
      sortPlan?: SortPlan | null;
      filterPlan?: FilterPlan | null;
    }>
  ): Promise<Paginated<Tag>> {
    const filterPlan: FilterPlan = {
      conjunction: 'AND',
      filterSets: props?.filterPlan?.filterSets ?? [],
    };
    filterPlan.filterSets.push({
      id: `${Date.now()}`,
      slug: 'type',
      operator: 'isEqualTo',
      data: 'QC',
    });
    return TagService.fetchTags({
      ...props,
      filterPlan,
    });
  },

  /**
   * Create tag.
   * @returns {Promise<Resource<Tag>>}
   */
  async createTag(postData: CreateTagData): Promise<Resource<Tag>> {
    try {
      const { data } = await HTTPServiceClient.post<Resource<Tag>>(
        t.CREATE_TAG_ENDPOINT,
        postData
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },
};
