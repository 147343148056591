export const TARGET_ENTITIES = {
  listUsers: 'list-users',
  listFunds: 'list-funds',
  listConsultants: 'list-consultants',
  listPensionSchemes: 'list-pension-schemes',
  listRenewals: 'list-renewals',
  listClients: 'list-providers',
  listAssetManagers: 'list-asset-managers',
  listQCFunds: 'list-qc-funds',
};
