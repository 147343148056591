export const SET_INVESTMENTS = 'investment/SET_INVESTMENTS';
export const SET_FETCHING_INVESTMENTS = 'investment/SET_FETCHING_INVESTMENTS';
export const SET_SUB_FUNDS = 'investment/SET_SUB_FUNDS';
export const SET_FUND_NATURES = 'investment/SET_FUND_NATURES';
export const SET_STRATEGIES = 'investment/SET_STRATEGIES';
export const SET_TEMPLATES = 'investment/SET_TEMPLATES';
export const SET_TIME_PERIODS = 'investment/SET_TIME_PERIODS';
export const SET_PRODUCT_TYPES = 'investment/SET_PRODUCT_TYPES';
export const SET_APPLIED_INVESTMENT_FILTERS =
  'investment/SET_APPLIED_INVESTMENT_FILTERS';
