export interface ResourcePriorityItem {
  label: string;
  slug: Priority;
  bgColor?: string;
  fgColor?: string;
}

export type Priority = 'high' | 'medium' | 'low' | string;
export interface ResourceBenchmarkingItem {
  label: string;
  slug: Benchmarking;
  bgColor?: string;
  fgColor?: string;
}

export type Benchmarking = Exclude<
  BenchmarkingStatusSlug,
  BenchmarkingStatusSlug.NO_BENCHMARKING_TAG
>;

export enum BenchmarkingStatusSlug {
  GOOD_FOR_BENCHMARKING = 'good_for_benchmarking',
  MCLAGAN_TO_REVIEW = 'mclagan_to_review',
  CLEARGLASS_TO_REVIEW = 'clearglass_to_review',
  DATA_OK_BUT_NOT_BENCHMARKABLE = 'data_ok_but_not_benchmarkable',
  BENCHMARKING_OUTLIER = 'benchmarking_outlier',
  PARTIAL_PERIOD = 'partial_period',
  DATA_ERROR = 'data_error',
  NO_BENCHMARKING_TAG = 'no_benchmarking_tag',
}

export enum BenchmarkingSalesSlug {
  SOLD_FOR_BENCHMARKING = 'sold_for_benchmarking',
  UNSOLD = 'unsold', // replace with null while writing in db
}

export const BenchmarkingSalesTags = [
  {
    name: 'Sold for benchmarking',
    slug: BenchmarkingSalesSlug.SOLD_FOR_BENCHMARKING,
  },
];

export type ResourceConfig = {
  slug: string;
  resourceId: string;
  resourceType: string;
};

export enum ResourceType {
  SUBMISSION = 'submission',
  INVESTMENT = 'investment',
}

export enum ResourceSlug {
  BENCHMAKRING = 'benchmarking',
  BENCHMARKING_SALES = 'benchmarking_sales',
}
