import * as MT from './mutationTypes';
import { Paginated } from '@/types';
import { IQCState } from '@/modules/qc/store/state';
import { QCFundFilters, QCFundInvestment } from '@/modules/qc/types';
import { MutationTree } from 'vuex';
import { FundCost } from '@/models/Analysis';

export const QCMutations: IQCMutations = {
  [MT.SET_FETCHING_QC_FUNDS]: (state: IQCState, flag: boolean) =>
    (state.fetchingQCFunds = flag),

  [MT.SET_QC_FUNDS]: (
    state: IQCState,
    paginatedQCFundInvestment: Paginated<QCFundInvestment>
  ) => (state.paginatedQCFundInvestment = paginatedQCFundInvestment),

  [MT.SET_APPLIED_QC_FILTERS]: (
    state: IQCState,
    appliedFilters: QCFundFilters | null
  ) => (state.appliedQCFilters = appliedFilters),

  [MT.SET_YOY_FUND_ANALYSIS]: (
    state: IQCState,
    yoyFundAnalysis: Paginated<FundCost>
  ) => (state.yoyFundAnalysis = yoyFundAnalysis),
};

export interface IQCMutations extends MutationTree<IQCState> {
  [MT.SET_FETCHING_QC_FUNDS](state: IQCState, flag: boolean): void;

  [MT.SET_QC_FUNDS](
    state: IQCState,
    paginatedQCFundInvestment: Paginated<QCFundInvestment>
  ): void;

  [MT.SET_APPLIED_QC_FILTERS](
    state: IQCState,
    appliedFilters: QCFundFilters | null
  ): void;

  [MT.SET_YOY_FUND_ANALYSIS](
    state: IQCState,
    yoyFundAnalysis: Paginated<FundCost>
  ): void;
}
