import { IAnalysisState } from './state';
import { MutationTree } from 'vuex';
import * as MT from './mutationsTypes';
import { Paginated } from '@/types';
import { FundCost } from '@/models/Analysis';
import Organisation from '../../../models/Organisation';
import {
  AnalysisFundFilters,
  GeneratedReportDetails,
  RootDetail,
  SubFund,
} from '../types';
import ProductType from '../../../models/ProductType';
import TimePeriod from '../../../models/TimePeriod';
import Investment from '../../../models/Investment';
import FundNature from '../../../models/FundNature';
import Strategy from '../../../models/Strategy';
import Template from '../../../models/Template';
import OrganisationType from '../../../models/OrganisationType';
import Fund from '../../../models/Fund';

/*--------------------------------------------------/
 * Analysis Mutations
 *--------------------------------------------------*/
export const AnalysisMutations: IAnalysisMutations = {
  [MT.SET_FETCHING_ANALYSIS]: (state: IAnalysisState, flag: boolean) =>
    (state.fetchingAnalysis = flag),

  [MT.SET_PAGINATED_FUND_ANALYSIS]: (
    state: IAnalysisState,
    paginatedFundAnalysis: Paginated<FundCost>
  ) => (state.paginatedFundAnalysis = paginatedFundAnalysis),

  [MT.SET_ROOT_DETAILS]: (state: IAnalysisState, rootDetails: RootDetail[]) =>
    (state.rootDetails = rootDetails),

  [MT.SET_ORGANIZATION_SUB_FUNDS]: (
    state: IAnalysisState,
    subFunds: SubFund[]
  ) => (state.organizationSubFunds = subFunds),

  [MT.SET_INVESTMENTS]: (state: IAnalysisState, investments: Investment[]) =>
    (state.investments = investments),

  [MT.SET_ORG_CLIENTS]: (state: IAnalysisState, orgClients: Organisation[]) =>
    (state.orgClients = orgClients),

  [MT.SET_ASSET_MANAGERS]: (
    state: IAnalysisState,
    allAssetManagers: Organisation[]
  ) => (state.allAssetManagers = allAssetManagers),

  [MT.SET_GENERATED_REPORT_DETAILS]: (
    state: IAnalysisState,
    generatedReportDetails: GeneratedReportDetails
  ) => (state.generatedReportDetails = generatedReportDetails),

  [MT.SET_SUB_FUNDS]: (state: IAnalysisState, subFunds: Fund[]) =>
    (state.analysisSubFunds = subFunds),

  [MT.SET_FUND_NATURES]: (state: IAnalysisState, fundNatures: FundNature[]) =>
    (state.analysisFundNatures = fundNatures),
  [MT.SET_STRATEGIES]: (state: IAnalysisState, strategies: Strategy[]) =>
    (state.analysisStrategies = strategies),
  [MT.SET_TEMPLATES]: (state: IAnalysisState, templates: Template[]) =>
    (state.analysisTemplates = templates),
  [MT.SET_TIME_PERIODS]: (state: IAnalysisState, timePeriods: TimePeriod[]) =>
    (state.analysisTimePeriods = timePeriods),
  [MT.SET_PRODUCT_TYPES]: (
    state: IAnalysisState,
    productTypes: ProductType[]
  ) => (state.analysisProductTypes = productTypes),
  [MT.SET_APPLIED_ANALYSIS_FILTERS]: (
    state: IAnalysisState,
    appliedFilters: AnalysisFundFilters | null
  ) => (state.appliedAnalysisFilters = appliedFilters),
};

/*--------------------------------------------------/
 * Analysis Mutations Tree Interface
 *--------------------------------------------------*/
export interface IAnalysisMutations extends MutationTree<IAnalysisState> {
  [MT.SET_FETCHING_ANALYSIS](state: IAnalysisState, flag: boolean): void;

  [MT.SET_PAGINATED_FUND_ANALYSIS](
    state: IAnalysisState,
    paginatedFundAnalysis: Paginated<FundCost>
  ): void;

  [MT.SET_ROOT_DETAILS](state: IAnalysisState, rootDetails: RootDetail[]): void;

  [MT.SET_ORGANIZATION_SUB_FUNDS](
    state: IAnalysisState,
    subFunds: SubFund[]
  ): void;

  [MT.SET_INVESTMENTS](state: IAnalysisState, investments: Investment[]): void;

  [MT.SET_ORG_CLIENTS](state: IAnalysisState, orgClients: Organisation[]): void;

  [MT.SET_ASSET_MANAGERS](
    state: IAnalysisState,
    allAssetManagers: Organisation[]
  ): void;

  [MT.SET_GENERATED_REPORT_DETAILS](
    state: IAnalysisState,
    generatedReportDetails: GeneratedReportDetails
  ): void;

  [MT.SET_SUB_FUNDS](state: IAnalysisState, subFunds: Fund[]): void;

  [MT.SET_FUND_NATURES](state: IAnalysisState, fundNatures: FundNature[]): void;
  [MT.SET_STRATEGIES](state: IAnalysisState, strategies: Strategy[]): void;
  [MT.SET_TEMPLATES](state: IAnalysisState, templates: Template[]): void;
  [MT.SET_TIME_PERIODS](state: IAnalysisState, timePeriods: TimePeriod[]): void;
  [MT.SET_PRODUCT_TYPES](
    state: IAnalysisState,
    productTypes: ProductType[]
  ): void;
  [MT.SET_APPLIED_ANALYSIS_FILTERS](
    state: IAnalysisState,
    appliedFilters: AnalysisFundFilters | null
  ): void;
}
