import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import vuetify from './plugins/vuetify';
import './plugins/vuehotkey';
import './plugins/vueresize';
import { RootStore as store } from './store';
import './scss/app.scss';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

Vue.config.productionTip = false;

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['admin.clearglass.com', 'testing.admin.clearglass.com'],
    }),
  ],
  tracesSampleRate: 0.3,
});

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app');
