import { Filter } from '@/components/filter/Filter';
import { FilterPlan } from '@/components/filter/types';
import { Sort } from '@/components/sort/Sort';
import { SortPlan } from '@/components/sort/types';
import Organisation from '@/models/Organisation';
import OrganizationUser from '@/models/OrganizationUser';
import { HTTPServiceClient } from '@/packages/http/service';
import { APIErrorResponse } from '@/packages/http/types';
import { Paginated, PaginatedParams } from '@/types';
import ErrorUtils from '@/utils/ErrorUtils';
import * as t from './endpoints';
import { SaveOrgPreferencesRequest } from './types';

/**
 * --------------------------------------------------
 *	Client HTTP Serice
 * --------------------------------------------------
 **/
export const ClientService = {
  /**
   * Fetches all the users.
   * @returns {Promise<Paginated<User>>}
   */
  async fetchClients(
    props?: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filterPlan?: FilterPlan | null;
    }>
  ): Promise<Paginated<Organisation>> {
    try {
      const filterParams = {
        ...(props?.filterPlan ? Filter.toQuery(props?.filterPlan) : {}),
      };
      const sortParams = {
        ...(props?.sortPlan ? Sort.toQuery(props?.sortPlan) : {}),
      };

      delete props?.sortPlan;
      delete props?.filterPlan;

      const params = { ...props, ...sortParams, ...filterParams };
      const { data } = await HTTPServiceClient.get<Paginated<Organisation>>(
        t.FETCH_CLIENTS_ENDPOINT,
        {
          params,
        }
      );
      return data ?? {};
    } catch (e) {
      throw new Error(ErrorUtils.getErrorMessage(e as APIErrorResponse));
    }
  },

  async fetchOrgUsers(orgId: Organisation['id']): Promise<OrganizationUser[]> {
    try {
      const { data } = await HTTPServiceClient.get<OrganizationUser[]>(
        t.ORG_USERS_ENDPOINT(orgId)
      );

      return data;
    } catch (e) {
      throw new Error(e.response.data.msg ?? 'Something went wrong');
    }
  },

  async saveOrgPreferences(
    request: SaveOrgPreferencesRequest
  ): Promise<Response> {
    try {
      const { data } = await HTTPServiceClient.post<Response>(
        t.ORG_PREFERENCES_ENDPOINT(request.id),
        { ...request }
      );

      return data ?? {};
    } catch (e) {
      throw new Error(e.response.data.msg ?? 'Something went wrong');
    }
  },
};
