import {
  format,
  formatDistanceToNow,
  parse,
  parseJSON,
  isValid as isValidDate,
  endOfDay,
  differenceInMinutes,
  sub,
  add,
  differenceInDays,
} from 'date-fns';
import numeral from 'numeral';
import { UnitType } from '../types';
import { ButtonItem } from '@/modules/investment/types';

numeral.register('locale', 'en-gb', {
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'bn',
    trillion: 'tn',
  },
  ordinal: (num: number) => {
    const b = num % 10;
    return Math.floor((num % 100) / 10) === 1
      ? 'th'
      : b === 1
      ? 'st'
      : b === 2
      ? 'nd'
      : b === 3
      ? 'rd'
      : 'th';
  },
  currency: {
    symbol: '£',
  },
});
numeral.locale('en-gb');
const EPSILON = 0.000001;

export class Utils {
  public static initials(text: string) {
    const initials = text.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  }

  public static CGAtlassianURL = 'https://clearglass.atlassian.net';

  /**
   * Checks whether a given value is empty of not.
   *
   * The value will be considered empty as follows:
   *
   * default: null | undefined
   *
   * string: ''
   * object: {}
   * array: []
   * @param value
   */
  public static isEmpty(value?: any): boolean {
    if ([null, undefined].includes(value)) {
      return true;
    }

    if (typeof value === 'string') {
      return value === '';
    }

    if (Array.isArray(value)) {
      return value.length === 0;
    }

    if (typeof value === 'object') {
      return Object.keys(value).length === 0;
    }

    return false;
  }

  public static isValidURL(url: string): boolean {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(url);
  }

  public static parseDateTime(
    dateString: string,
    format = 'yyyy-MM-dd H:m:s',
    tz = 'UTC',
    referenceDate?: Date | number
  ): string {
    // TODO Handle timezone
    return formatDistanceToNow(
      parse(dateString, format, referenceDate ?? Date.now())
    );
  }

  public static parseJSONDateTime(
    dateString: string,
    tz = 'UTC',
    referenceDate?: Date | number
  ): string {
    // TODO Handle timezone
    return formatDistanceToNow(parseJSON(dateString));
  }

  public static parseJSONDate(
    dateString: string,
    toFormat = 'do MMM yyyy',
    tz = 'UTC',
    referenceDate?: Date | number
  ): string {
    // TODO Handle timezone
    if (!dateString) {
      return '';
    }

    try {
      return format(parseJSON(dateString), toFormat);
    } catch (e) {
      throw e;
    }
  }

  public static parseDate(
    dateString: string,
    toFormat = 'do MMM yyyy',
    originalFormat = 'yyyy-MM-dd',
    referenceDate: Date | number = new Date()
  ): string {
    // TODO Handle timezone
    if (!dateString) {
      return '';
    }

    return format(parse(dateString, originalFormat, referenceDate), toFormat);
  }

  public static formatNumber(value: any, format = '0.0a') {
    if (!Number.isFinite(value)) {
      return value;
    }
    if (Math.abs(value) < EPSILON) {
      value = numeral(0).format(format);
    }
    return numeral(value).format(format);
  }

  public static formatByUnitType(
    value: number | any,
    unit: string = 'gbp',
    format: string = '0.0'
  ) {
    if (!value) {
      return value;
    }
    const formats: any = {
      [UnitType.GBP]: '0.[00]a',
      [UnitType.PERCENT]: '0.0[0][0]%',
      [UnitType.BPS]: '0.[0][0]',
    };

    const frmt = formats[unit] || format;
    const formattedValue = this.formatNumber(value, frmt);

    if (unit === UnitType.BPS) {
      return `${formattedValue} BPS`;
    }

    return formattedValue;
  }

  public static impersonationEndpoint(token: string) {
    if (!token) {
      return;
    }

    if (!process?.env?.VUE_APP_FRONTEND_URL) {
      return null;
    }
    return `${process?.env?.VUE_APP_FRONTEND_URL}auth/impersonation/${token}`;
  }

  public static getResetPasswordLink(token: string | null) {
    if (!token) {
      return;
    }

    if (!process?.env?.VUE_APP_FRONTEND_URL) {
      return null;
    }
    return `${process?.env?.VUE_APP_FRONTEND_URL}auth/reset-password/${token}`;
  }

  static endOfDay(date?: any) {
    if (date) {
      return endOfDay(new Date(date));
    }
    return endOfDay(new Date());
  }

  public static getRandomIdentifier() {
    return Math.random().toString().slice(2, 11);
  }

  /** Converts byte to nearest highest unit . i.e. KB to be converted to MB, MB to GB ... */
  public static upgradeUnitForByte(bytes: number) {
    return bytes / 1024;
  }

  public static getDate(date?: string) {
    if (!date) {
      return new Date();
    }

    let dateObject = new Date(date);
    if (!isValidDate(dateObject)) {
      // One of the date formates used ('MM-DD-YYYY') is not parsed correctly in Firefox with new Date() constructor
      // so change format to YYYY=MM-DD and re-parse
      const splitDate = date.split('-');
      if (Array.isArray(splitDate) && splitDate.length === 3) {
        dateObject = new Date(
          [splitDate[2], splitDate[0], splitDate[1]].join('-')
        );
      }
    }

    if (isValidDate(dateObject)) {
      return dateObject;
    }

    return new Date();
  }

  public static formatDate(date?: string | null, dateFormat = 'MM-dd-yyyy') {
    return date ? format(this.getDate(date), dateFormat) : null;
  }

  public static addYearToDate(date: Date) {
    const nextYearDate = sub(add(date, { years: 1 }), { days: 1 });
    return this.formatDate(nextYearDate.toDateString(), 'yyy-MM-dd');
  }

  public static isPastDueDate(date: string | null) {
    // returns the result in minutes, if negative, means it has passed the due date
    const daysRemainingTillDueDate =
      date && differenceInMinutes(endOfDay(this.getDate(date)), this.getDate());

    if (daysRemainingTillDueDate !== null && daysRemainingTillDueDate <= 0) {
      return true;
    }

    return false;
  }

  static currencyFormatter(value: number) {
    if (!value) {
      return value;
    } else return new Intl.NumberFormat('en-GB').format(value);
  }

  static validateUniversalIdentifier(identifier: string) {
    const regEx =
      /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/;
    return regEx?.test(identifier);
  }

  static todaysDate() {
    return this.formatDate(new Date().toLocaleTimeString(), 'yyyy-MM-dd');
  }

  static mapComments(item: any): string {
    const comments = item?.comments;

    return comments
      ?.map((c: any) => {
        if (!c?.body) {
          return null;
        }
        try {
          const body =
            typeof c?.body === 'string' ? JSON.parse(c?.body) : c?.body;
          return body?.blocks?.map((b: any) => b?.data?.text)?.join('\n');
        } catch (e) {
          return null;
        }
      })
      ?.join('\n');
  }

  public static isFlagEnabled(flag: string): boolean {
    return process.env[flag] === 'true';
  }

  public static getGridCellLabel(value: ButtonItem[]): string | null {
    if (value?.length) {
      return value[0]?.label ?? null;
    }
    return null;
  }

  static differenceInDaysFromNow(date: string) {
    return differenceInDays(this.getDate(date), new Date());
  }

  static formatBooleanValue(value: boolean) {
    return value ? 'Yes' : 'No';
  }
}
