import { FilterPlan } from '@/components/filter/types';
import { SortPlan } from '@/components/sort/types';
import Organisation from '@/models/Organisation';
import { IRootState } from '@/store';
import { Paginated, PaginatedParams } from '@/types';
import { ActionContext, ActionTree } from 'vuex';
import { PensionSchemeService } from '../services';
import {
  ReportingDueDates,
  Feedback,
  PensionSchemeDetails,
  PensionSchemeRequest,
} from '../types';
import * as AT from './actionTypes';
import {
  SET_FETCHING_PENSION_SCHEMES,
  SET_PENSION_SCHEMES,
} from './mutationsTypes';
import { IPensionSchemeState } from './state';

/**
 * --------------------------------------------------
 *  PensionScheme Actions
 * --------------------------------------------------
 **/
export const PensionSchemeActions: IPensionSchemeActions = {
  [AT.FETCH_PENSION_SCHEMES]: async (
    { commit },
    params?: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filterPlan?: FilterPlan | null;
    }>
  ): Promise<Paginated<Organisation>> => {
    commit(SET_FETCHING_PENSION_SCHEMES, true);

    try {
      const data = await PensionSchemeService.fetchPensionSchemes(params);
      commit(SET_PENSION_SCHEMES, data);
      return data;
    } catch (e) {
      commit(SET_PENSION_SCHEMES, []);
      throw e;
    } finally {
      commit(SET_FETCHING_PENSION_SCHEMES, false);
    }
  },

  [AT.FETCH_PENSION_SCHEME_DETAILS]: async (
    { commit },
    schemeId: string
  ): Promise<PensionSchemeDetails> => {
    return PensionSchemeService.fetchPensionSchemeDetails(schemeId).then(
      (data) => {
        return data;
      }
    );
  },

  [AT.STORE_PENSION_SCHEME_FEEDBACK]: async (
    { commit },
    feedback: Feedback
  ): Promise<Response> => {
    return PensionSchemeService.storePensionSchemeFeedback(feedback).then(
      (data) => {
        return data;
      }
    );
  },

  [AT.STORE_REPORTING_DUE_DATE]: async (
    { commit },
    reportingDueDates: ReportingDueDates
  ): Promise<Response> => {
    return PensionSchemeService.storeReportingDueDate(reportingDueDates).then(
      (data) => {
        return data;
      }
    );
  },

  [AT.STORE_PENSION_SCHEME]: async (
    { commit },
    pensionSchemeRequest: PensionSchemeRequest
  ): Promise<Response> => {
    return PensionSchemeService.storePensionScheme(pensionSchemeRequest).then(
      (data) => {
        return data;
      }
    );
  },
};

/**
 * --------------------------------------------------
 *  PensionScheme Actions Interface
 * --------------------------------------------------
 **/
export interface IPensionSchemeActions
  extends ActionTree<IPensionSchemeState, IRootState> {
  [AT.FETCH_PENSION_SCHEMES](
    context: IPensionSchemeActionContext,
    params: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filterPlan?: FilterPlan | null;
    }>
  ): Promise<Paginated<Organisation>>;
  [AT.FETCH_PENSION_SCHEME_DETAILS](
    context: IPensionSchemeActionContext,
    schemeId: string
  ): Promise<PensionSchemeDetails>;
  [AT.STORE_PENSION_SCHEME_FEEDBACK](
    context: IPensionSchemeActionContext,
    feedback: Feedback
  ): Promise<Response>;
  [AT.STORE_REPORTING_DUE_DATE](
    context: IPensionSchemeActionContext,
    dueDates: ReportingDueDates
  ): Promise<Response>;
  [AT.STORE_PENSION_SCHEME](
    context: IPensionSchemeActionContext,
    pensionSchemeRequest: PensionSchemeRequest
  ): Promise<Response>;
}

/**
 * --------------------------------------------------
 *  PensionScheme Action Context Interface
 * --------------------------------------------------
 **/
export type IPensionSchemeActionContext = ActionContext<
  IPensionSchemeState,
  IRootState
>;
