
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class DatePickerField extends Vue {
  @Prop({ default: 'generic-date-picker-input' }) inputFieldTestId!: string;
  @Prop({ default: 'generic-date-picker' }) datePickerTestId!: string;
  @Prop({ default: '' }) value!: any;
  @Prop({ default: () => '' }) label!: string;
  @Prop({ default: false }) disabled!: Boolean;
  @Prop({ default: 'mx-2' }) elementClass!: string;
  @Prop({ default: '' }) min!: string;
  @Prop({ default: '' }) max!: string;
  @Prop({ default: false }) outlined!: Boolean;
  @Prop({ default: () => '' }) textFieldLabel!: string;
  @Prop({ default: true }) dense!: Boolean;

  public menu: boolean = false;

  public input(value: string) {
    this.$emit('input', value);
    this.menu = false;
  }

  public get labelClass() {
    return `caption ${this.elementClass}`;
  }
}
