import { TARGET_ENTITIES } from '@/utils/TargetEntity';
import { RouteConfig } from 'vue-router';
const AnalysisModule = () =>
  import(/* webpackChunkName: 'analysis' */ './AnalysisModule.vue');
const AnalysisPage = () =>
  import(/* webpackChunkName: 'analysis' */ './pages/AnalysisPage.vue');

export const AnalysisRoutes: RouteConfig[] = [
  {
    path: 'analysis',
    component: AnalysisModule,
    meta: {
      middlewares: {
        canAccessEntity: TARGET_ENTITIES.listFunds,
      },
    },
    children: [
      {
        path: 'list',
        name: 'analysis.list',
        component: AnalysisPage,
      },
    ],
  },
];
