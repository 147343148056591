import { IAnalysisState } from './state';
import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { Paginated } from '@/types';
import { FundCost } from '@/models/Analysis';
import Organisation from '../../../models/Organisation';
import {
  AnalysisFundFilters,
  GeneratedReportDetails,
  RootDetail,
  SubFund,
} from '../types';
import ProductType from '../../../models/ProductType';
import TimePeriod from '../../../models/TimePeriod';
import Investment from '../../../models/Investment';
import FundNature from '../../../models/FundNature';
import Strategy from '../../../models/Strategy';
import Template from '../../../models/Template';
import Fund from '../../../models/Fund';
/**
 * --------------------------------------------------
 *	Analysis Getters
 * --------------------------------------------------
 **/
export const AnalysisGetters: IAnalysisGetters = {
  fetchingAnalysis: (state: IAnalysisState): boolean => state.fetchingAnalysis,
  paginatedFundAnalysis: (state: IAnalysisState): Paginated<FundCost> =>
    state.paginatedFundAnalysis,
  rootDetails: (state: IAnalysisState): RootDetail[] => state.rootDetails,
  orgClients: (state: IAnalysisState): Organisation[] => state.orgClients,
  organizationSubFunds: (state: IAnalysisState): SubFund[] =>
    state.organizationSubFunds,
  investments: (state: IAnalysisState): Investment[] => state.investments,
  allAssetManagers: (state: IAnalysisState): Organisation[] =>
    state.allAssetManagers,
  generatedReportDetails: (state: IAnalysisState): GeneratedReportDetails =>
    state.generatedReportDetails,
  analysisSubFunds: (state: IAnalysisState): Fund[] | null =>
    state.analysisSubFunds,
  analysisFundNatures: (state: IAnalysisState): FundNature[] | null =>
    state.analysisFundNatures,
  analysisStrategies: (state: IAnalysisState): Strategy[] | null =>
    state.analysisStrategies,
  analysisTemplates: (state: IAnalysisState): Template[] | null =>
    state.analysisTemplates,
  analysisTimePeriods: (state: IAnalysisState): TimePeriod[] | null =>
    state.analysisTimePeriods,
  analysisProductTypes: (state: IAnalysisState): ProductType[] | null =>
    state.analysisProductTypes,
  appliedAnalysisFilters: (state: IAnalysisState): AnalysisFundFilters | null =>
    state.appliedAnalysisFilters,
};

/**
 * --------------------------------------------------
 *	Analysis Getters Interface
 * --------------------------------------------------
 **/
export interface IAnalysisGetters
  extends GetterTree<IAnalysisState, IRootState> {
  fetchingAnalysis(state: IAnalysisState): boolean;
  paginatedFundAnalysis(state: IAnalysisState): Paginated<FundCost>;
  generatedReportDetails(state: IAnalysisState): GeneratedReportDetails;
  rootDetails(state: IAnalysisState): RootDetail[];
  orgClients(state: IAnalysisState): Organisation[];
  organizationSubFunds(state: IAnalysisState): SubFund[];
  allAssetManagers(state: IAnalysisState): Organisation[];
  analysisSubFunds(state: IAnalysisState): Fund[] | null;
  analysisFundNatures(state: IAnalysisState): FundNature[] | null;
  analysisStrategies(state: IAnalysisState): Strategy[] | null;
  analysisTemplates(state: IAnalysisState): Template[] | null;
  analysisTimePeriods(state: IAnalysisState): TimePeriod[] | null;
  analysisProductTypes(state: IAnalysisState): ProductType[] | null;
  appliedAnalysisFilters(state: IAnalysisState): AnalysisFundFilters | null;
}
