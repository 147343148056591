import Investment from '@/models/Investment';
import SharedResource from '@/models/SharedResource';
import { ISubmissionState } from '@/modules/submission/store/state';
import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { TemplateResolution } from '../types';
import { OrganisationAssociationMeta } from '@/models/OrganizationAssociation';

/**
 * --------------------------------------------------
 *	Submission Getters
 * --------------------------------------------------
 **/
export const SubmissionGetters: ISubmissionGetters = {
  investment: (state: ISubmissionState): Investment | null => state.investment,
  fetchInvestment: (state: ISubmissionState): Boolean =>
    state.fetchingInvestment,
  providedTemplateTypes: (state: ISubmissionState): SharedResource[] | null =>
    state.providedTemplateTypes,
  templateSources: (state: ISubmissionState): SharedResource[] | null =>
    state.templateSources,
  templateResponseSwiftness: (
    state: ISubmissionState
  ): SharedResource[] | null => state.templateResponseSwiftness,
  templateResolutions: (state: ISubmissionState): TemplateResolution[] | null =>
    state.templateResolutions,
  managerAssociationMeta: (
    state: ISubmissionState
  ): OrganisationAssociationMeta | null => state.managerAssociationMeta,
};

/**
 * --------------------------------------------------
 *	Submission Getters Interface
 * --------------------------------------------------
 **/
export interface ISubmissionGetters
  extends GetterTree<ISubmissionState, IRootState> {
  investment(state: ISubmissionState): Investment | null;
  fetchInvestment(state: ISubmissionState): Boolean;
  providedTemplateTypes(state: ISubmissionState): SharedResource[] | null;
  templateSources(state: ISubmissionState): SharedResource[] | null;
  templateResponseSwiftness(state: ISubmissionState): SharedResource[] | null;
  templateResolutions(state: ISubmissionState): TemplateResolution[] | null;
  managerAssociationMeta(
    state: ISubmissionState
  ): OrganisationAssociationMeta | null;
}
