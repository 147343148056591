import { TARGET_ENTITIES } from '@/utils/TargetEntity';
import { RouteConfig } from 'vue-router';
import PriorityDatesModal from '@/modules/qc/components/PriorityDatesModal.vue';
const QCModule = () => import(/* webpackChunkName: 'qc' */ './QCModule.vue');
const QCPage = () => import(/* webpackChunkName: 'qc' */ './pages/QCPage.vue');

export const QCRoutes: RouteConfig[] = [
  {
    path: 'qc',
    component: QCModule,
    meta: {
      middlewares: {
        canAccessEntity: TARGET_ENTITIES.listQCFunds,
      },
    },
    children: [
      {
        path: 'list',
        name: 'qc.list',
        component: QCPage,
      },
      {
        path: 'priority',
        name: 'qc.priority.modal',
        component: PriorityDatesModal,
      },
    ],
  },
];
