import { IDashboardState } from '@/modules/dashboard/store/state';
import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { User } from '@/models/User';
import Snackbar from '../../../models/Snackbar';
import Organisation from '@/models/Organisation';
import OrganisationType from '@/models/OrganisationType';
import Currency from '@/models/Currency';
import Country from '@/models/Country';
/**
 * --------------------------------------------------
 *	Dashboard Getters
 * --------------------------------------------------
 **/
export const DashboardGetters: IDashboardGetters = {
  snackbar: (state: IDashboardState): Snackbar | null => state.snackbar,
  currentUser: (state: IDashboardState): User | null => state.currentUser,
  validatingEntityAccess: (state: IDashboardState): Boolean =>
    state.validatingEntityAccess,
  packageVersion: (state: IDashboardState): string => state.packageVersion,
  allOrganizations: (state: IDashboardState): Organisation[] | null =>
    state.organizations,

  allOrganizationTypes: (state: IDashboardState): OrganisationType[] | null =>
    state.organizationTypes,
  currencyList: (state: IDashboardState): Currency[] | null => state.currencies,
  countryList: (state: IDashboardState): Country[] | null => state.countries,
};

/**
 * --------------------------------------------------
 *	Dashboard Getters Interface
 * --------------------------------------------------
 **/
export interface IDashboardGetters
  extends GetterTree<IDashboardState, IRootState> {
  snackbar: (state: IDashboardState) => Snackbar | null;
  currentUser(state: IDashboardState): User | null;
  validatingEntityAccess(state: IDashboardState): Boolean;
  packageVersion(state: IDashboardState): string;
  allOrganizations(state: IDashboardState): Organisation[] | null;
  allOrganizationTypes(state: IDashboardState): OrganisationType[] | null;
  currencyList(state: IDashboardState): Currency[] | null;
  countryList(state: IDashboardState): Country[] | null;
}
