import { FilterPlan } from '@/components/filter/types';
import { SortPlan } from '@/components/sort/types';
import Organisation from '@/models/Organisation';
import { IRootState } from '@/store';
import { Paginated, PaginatedParams } from '@/types';
import { ActionContext, ActionTree } from 'vuex';
import { FiduciaryService } from '../services';
import * as AT from './actionTypes';
import { SET_FIDUCIARIES, SET_FETCHING_FIDUCIARIES } from './mutationsTypes';
import { IFiduciaryState } from './state';

/**
 * --------------------------------------------------
 *  Fiduciary Actions
 * --------------------------------------------------
 **/
export const FiduciaryActions: IFiduciaryActions = {
  [AT.FETCH_FIDUCIARIES]: async (
    { commit },
    params?: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filterPlan?: FilterPlan | null;
    }>
  ): Promise<Paginated<Organisation>> => {
    commit(SET_FETCHING_FIDUCIARIES, true);

    try {
      const data = await FiduciaryService.fetchFiduciaries(params);
      commit(SET_FIDUCIARIES, data);
      return data;
    } catch (e) {
      commit(SET_FIDUCIARIES, []);
      throw e;
    } finally {
      commit(SET_FETCHING_FIDUCIARIES, false);
    }
  },
};

/**
 * --------------------------------------------------
 *  Fiduciary Actions Interface
 * --------------------------------------------------
 **/
export interface IFiduciaryActions
  extends ActionTree<IFiduciaryState, IRootState> {
  [AT.FETCH_FIDUCIARIES](
    context: IFiduciaryActionContext,
    params: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filterPlan?: FilterPlan | null;
    }>
  ): Promise<Paginated<Organisation>>;
}

/**
 * --------------------------------------------------
 *  Fiduciary Action Context Interface
 * --------------------------------------------------
 **/
export type IFiduciaryActionContext = ActionContext<
  IFiduciaryState,
  IRootState
>;
