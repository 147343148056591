import { Filter } from '@/components/filter/Filter';
import { FilterPlan } from '@/components/filter/types';
import { ResourceBenchmarkingItem } from '@/components/resource/types';
import { Sort } from '@/components/sort/Sort';
import { SortPlan } from '@/components/sort/types';
import { HTTPServiceClient } from '@/packages/http/service';
import { APIErrorResponse } from '@/packages/http/types';
import { Paginated, PaginatedParams, Resource } from '@/types';
import * as t from './endpoints';

/**
 * --------------------------------------------------
 *	Benchmarking HTTP Serice
 * --------------------------------------------------
 **/
export const BenchmarkingService = {
  /**
   * Fetches all the resource benchmarking.
   * @returns {Promise<ResourceBenchmarkingItem>}
   */
  async fetchBenchmarking(
    props?: PaginatedParams<{
      include?: string[] | null;
      sortPlan?: SortPlan | null;
      filterPlan?: FilterPlan | null;
    }>
  ): Promise<Paginated<ResourceBenchmarkingItem>> {
    try {
      const filterParams = {
        ...(props?.filterPlan ? Filter.toQuery(props?.filterPlan) : {}),
      };
      const sortParams = {
        ...(props?.sortPlan ? Sort.toQuery(props?.sortPlan) : {}),
      };

      delete props?.sortPlan;
      delete props?.filterPlan;

      const params = {
        ...props,
        ...sortParams,
        ...filterParams,
      };
      const { data } = await HTTPServiceClient.get<
        Paginated<ResourceBenchmarkingItem>
      >(t.FETCH_BENCHMARKING_ENDPOINT, {
        params,
      });
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },
};
