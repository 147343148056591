import { IQCState } from '@/modules/qc/store/state';
import { Paginated } from '@/types';
import { GetterTree } from 'vuex';
import { IRootState } from '@/store';
import { QCFundFilters, QCFundInvestment } from '@/modules/qc/types';
import { FundCost } from '@/models/Analysis';

/**
 * --------------------------------------------------
 *	QC Getters
 * --------------------------------------------------
 **/
export const QCGetters: IQCGetters = {
  fetchingQCFunds: (state: IQCState): boolean => state.fetchingQCFunds,
  paginatedQCFundInvestment: (state: IQCState): Paginated<QCFundInvestment> =>
    state.paginatedQCFundInvestment,
  appliedQCFilters: (state: IQCState): QCFundFilters | null =>
    state.appliedQCFilters,
  yoyFundAnalysis: (state: IQCState): Paginated<FundCost> | null =>
    state.yoyFundAnalysis,
};

/**
 * --------------------------------------------------
 *	QC Getters Interface
 * --------------------------------------------------
 **/
export interface IQCGetters extends GetterTree<IQCState, IRootState> {
  fetchingQCFunds(state: IQCState): boolean;
  paginatedQCFundInvestment: (state: IQCState) => Paginated<QCFundInvestment>;
  appliedQCFilters: (state: IQCState) => QCFundFilters | null;
  yoyFundAnalysis: (state: IQCState) => Paginated<FundCost> | null;
}
