export const Permissions = {
  listOrganizations: 'list-organizations',
  listUsers: 'list-users',
  createUsers: 'create-users',
  deleteUsers: 'delete-users',
  editUsers: 'edit-users',
  impersonateUsers: 'impersonate-users',
  resetUsersPassword: 'reset-users-password',
  editUsersEmailNotifications: 'edit-users-email-notifications',
  listFunds: 'list-funds',
  createFunds: 'create-funds',
  deleteFunds: 'delete-funds',
  editFunds: 'edit-funds',
  sendEmails: 'send-emails',
  createSubmissionRequests: 'create-submission-requests',
  deleteSubmissionRequests: 'delete-submission-requests',
  recollectData: 'recollect-data',
  downloadSubmissionTemplates: 'download-submission-templates',
  bulkDownloadSubmissionTemplates: 'bulk-download-submission-templates',
  exportFunds: 'export-funds',
  impersonateClients: 'impersonate-clients',
  mergeOrganizations: 'merge-organizations',
  listPensionSchemes: 'list-pension-schemes',
  createPensionSchemes: 'create-pension-schemes',
  listClientDetails: 'list-client-details',
  createManagerRatings: 'create-manager-ratings',
  listAssetManagers: 'list-asset-managers',
  createAssetManagers: 'create-asset-managers',
  editAssetManagers: 'edit-asset-managers',
  listSubFunds: 'list-sub-funds',
  listConsultants: 'list-consultants',
  listStrategies: 'list-strategies',
  listTemplates: 'list-templates',
  listReportingPeriods: 'list-reporting-periods',
  generateReports: 'generate-reports',
  listReports: 'list-reports',
  listAdminUsers: 'list-admin-users',
  listLinks: 'list-links',
  createLinks: 'create-links',
  deleteLinks: 'delete-links',
  editLinks: 'edit-links',
  listTags: 'list-tags',
  createTags: 'create-tags',
  deleteTags: 'delete-tags',
  editTags: 'edit-tags',
  listChecklists: 'list-checklists',
  createChecklists: 'create-checklists',
  deleteChecklists: 'delete-checklists',
  editChecklists: 'edit-checklists',
  listComments: 'list-comments',
  createComments: 'create-comments',
  deleteComments: 'delete-comments',
  editComments: 'edit-comments',
  listResourceAttributes: 'list-resource-attributes',
  createResourceAttributes: 'create-resource-attributes',
  deleteResourceAttributes: 'delete-resource-attributes',
  editResourceAttributes: 'edit-resource-attributes',
  listResourceUsers: 'list-resource-users',
  createResourceUsers: 'create-resource-users',
  deleteResourceUsers: 'delete-resource-users',
  editResourceUsers: 'edit-resource-users',
  listResourceTags: 'list-resource-tags',
  createResourceTags: 'create-resource-tags',
  deleteResourceTags: 'delete-resource-tags',
  editResourceTags: 'edit-resource-tags',
  listSubmissions: 'list-submissions',
  createSubmissions: 'create-submissions',
  createNotes: 'create-notes',
  createAssetManagerSuperAdmin: 'create-asset-manager-super-admin',
  editAssetManagerSuperAdmin: 'edit-asset-manager-super-admin',
  listFundTypes: 'list-fund-types',
  enableTfa: 'enable-tfa',
  listProductTypes: 'list-product-types',
  listChecklistItems: 'list-checklist-items',
  listProviders: 'list-providers',
  manageORgPreferences: 'manage-org-preferences',
  listSubmissionRequests: 'list-submission-requests',
  createClientContracts: 'create-client-contracts',
  disableTemplateValidationRules: 'disable-template-validation-rules',
  listFiduciaries: 'list-fiduciaries',
  listReportingDueDates: 'list-reporting-due-dates',
  editReportingDueDates: 'edit-reporting-due-dates',
  editPreferredCurrency: 'edit-preferred-currency',
  listInternalCodes: 'list-internal-codes',
  editInternalCodes: 'edit-internal-codes',
  listPensionSchemeRenewals: 'list-scheme-renewals',
  editPensionSchemeRenewals: 'edit-scheme-renewals',
  editFundsKDE: 'edit-funds-kde',
  editMergeEntityIds: 'edit-merge-entity-ids',
  editRollupTag: 'edit-rollup-tag',
  listQCFunds: 'list-qc-funds',
  editQCFunds: 'edit-qc-funds',
};

import { RootStore } from '@/store';

export const userHasPermission = (permission: string) => {
  const currentUser = RootStore?.getters?.currentUser;
  if (currentUser) {
    const permissions = currentUser?.permissions;
    return permissions?.includes(permission);
  }
  return false;
};
