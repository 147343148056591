import * as AT from './actionTypes';
import * as MT from './mutationsTypes';
import { IRootState } from '@/store';
import { ITagState } from '@/modules/tag/store/state';
import { ActionContext, ActionTree } from 'vuex';
import { TagService } from '@/modules/tag/services';
import { Tag } from '@/models/Tag';
import { Paginated } from '@/types';

/**
 * --------------------------------------------------
 *  Tag Actions
 * --------------------------------------------------
 **/
export const TagActions: ITagActions = {
  [AT.FETCH_TAGS]: async ({ commit }): Promise<Paginated<Tag>> => {
    return TagService.fetchTags().then((data) => {
      commit(MT.SET_TAGS, data);
      return data;
    });
  },
  [AT.FETCH_QC_TAGS]: async ({ commit, state }): Promise<Paginated<Tag>> => {
    if (state.qcTags) {
      // Don't fetch them again if they are already fetched.
      return state.qcTags;
    }
    commit(MT.SET_QC_TAGS_ARE_LOADING);
    return TagService.fetchQCTags({ limit: 50 })
      .then((data) => {
        commit(MT.SET_QC_TAGS, data);
        return data;
      })
      .finally(() => {
        commit(MT.SET_QC_TAGS_ARE_NOT_LOADING);
      });
  },
};

/**
 * --------------------------------------------------
 *  Tag Actions Interface
 * --------------------------------------------------
 **/
export interface ITagActions extends ActionTree<ITagState, IRootState> {
  [AT.FETCH_TAGS](context: ITagActionContext): Promise<Paginated<Tag>>;
  [AT.FETCH_QC_TAGS](context: ITagActionContext): Promise<Paginated<Tag>>;
}

/**
 * --------------------------------------------------
 *  Tag Action Context Interface
 * --------------------------------------------------
 **/
export type ITagActionContext = ActionContext<ITagState, IRootState>;
