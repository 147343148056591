import { isEmpty } from 'lodash';

export enum QueryType {
  FILTER = 'filter',
  SORT = 'sort',
  LOCAL_SORT = 'localSort',
  PAGE = 'page',
  LIMIT = 'limit',
}

export default class QueryUtils {
  // update/append new query params to current applied params
  public static resolveQueryParams(
    queryType: QueryType,
    queryToUpdate: Record<string, any>,
    currentQuery: Record<string, any> = {}
  ): Record<string, any> {
    const immutableParams = this.filterImmutableParams(queryType, currentQuery);

    /** Reset page to 1 when filters are applied */
    if (
      queryType === QueryType.FILTER &&
      !isEmpty(currentQuery[QueryType.PAGE])
    ) {
      immutableParams[QueryType.PAGE] = 1;
    }

    return Object.assign({}, immutableParams, queryToUpdate);
  }

  // filters immutable params for given query type from applied params
  private static filterImmutableParams(
    queryType: QueryType,
    currentQuery: Record<string, any> = {}
  ): Record<string, any> {
    const immutableParams = this.getImmutableParams(queryType) ?? [];

    const immutableParamsKeyPresentInQuery = Object.keys(currentQuery)?.filter(
      (it) => immutableParams?.findIndex((param) => it?.includes(param)) >= 0
    );

    return this.filterQueryParamsByKey(
      currentQuery,
      immutableParamsKeyPresentInQuery
    );
  }

  // filters only those key-value pairs where key is present in keys from given query
  private static filterQueryParamsByKey(
    query: Record<string, any> = {},
    keys: string[]
  ): Record<string, any> {
    const filteredObject: any = {};
    keys?.forEach((key) => {
      filteredObject[key] = query[key];
    });
    return filteredObject;
  }

  // returns immutable parama for given query type
  private static getImmutableParams(queryType: QueryType): string[] {
    const allParams = this.allParams();
    const mutableParams = this.getMutableParams(queryType);
    return this.differenceInArrays(allParams, mutableParams);
  }

  // returns mutable params for given query type
  private static getMutableParams(queryType: QueryType): string[] {
    if (queryType === QueryType.FILTER) {
      return [QueryType.FILTER];
    }

    if (queryType === QueryType.SORT) {
      return [QueryType.SORT];
    }

    if (queryType === QueryType.LOCAL_SORT) {
      return [QueryType.LOCAL_SORT];
    }

    if (queryType === QueryType.PAGE) {
      return [QueryType.PAGE];
    }

    if (queryType === QueryType.LIMIT) {
      return [QueryType.LIMIT];
    }

    return this.allParams();
  }

  // returns all params
  private static allParams(): string[] {
    return Object.values(QueryType);
  }

  // returns subtract array2 from array1
  private static differenceInArrays(
    array1: string[],
    array2: string[]
  ): string[] {
    return array1?.filter((it: string) => !array2.includes(it));
  }

  public static appendFilterSlugToParamKeys(params: Record<string, any>) {
    const mappedParams = new Map();

    Object.keys(params)?.forEach((key: string) => {
      if (!isEmpty(params[key])) {
        mappedParams.set(`${QueryType.FILTER}[${key}]`, params[key]);
      }
    });

    return Object.fromEntries(mappedParams);
  }

  public static removeFilterSlugFromParamKeys(
    params: Record<string, any>,
    stringTypeKeys: string[] = []
  ) {
    const mappedParams = new Map();

    Object.keys(params)
      ?.filter((key) => key.includes(QueryType.FILTER))
      ?.forEach((key: string) => {
        if (!isEmpty(params[key])) {
          const mappedKey = key.replaceAll(/(filter|\[|])/gi, '');
          mappedParams.set(
            mappedKey,
            this.resolveFilterValue(mappedKey, params[key], stringTypeKeys)
          );
        }
      });
    return Object.fromEntries(mappedParams);
  }

  public static resolveFilterValue(
    key: string,
    value: string[] | string,
    stringTypeKeys?: string[]
  ): string[] | string {
    if (stringTypeKeys?.includes(key)) {
      return value;
    }

    if (Array.isArray(value)) {
      return value;
    }
    return value?.split(',');
  }

  private static getAppliedQueryKey(): string {
    return 'appliedQuery';
  }

  public static getQueryValue(type?: string): Record<string, any> {
    let appliedQuery: any = window.localStorage.getItem(
      this.getAppliedQueryKey()
    );
    appliedQuery = appliedQuery ? JSON.parse(appliedQuery) : {};
    return type ? appliedQuery[type] : appliedQuery;
  }

  public static setQueryValueForType(
    type: string,
    query: Record<string, any> = {}
  ): void {
    const appliedQuery: any = this.getQueryValue();
    Object.keys(query)?.forEach((key) => {
      if (isEmpty(query[key])) {
        query[key] = undefined;
      }
    });

    appliedQuery[type] = query;

    window.localStorage.setItem(
      this.getAppliedQueryKey(),
      JSON.stringify(appliedQuery)
    );
  }

  public static distroyAllQueryValues(): void {
    window.localStorage.removeItem(this.getAppliedQueryKey());
  }
}
