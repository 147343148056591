import { SubmissionActions } from '@/modules/submission/store/actions';
import { SubmissionGetters } from '@/modules/submission/store/getters';
import { SubmissionMutations } from '@/modules/submission/store/mutations';
import {
  SubmissionState,
  ISubmissionState,
} from '@/modules/submission/store/state';
import { IRootState } from '@/store';
import { Module } from 'vuex';

export const SubmissionModuleStore: ISubmissionModuleStore = {
  state: SubmissionState,
  actions: SubmissionActions,
  getters: SubmissionGetters,
  mutations: SubmissionMutations,
};

/**
 * --------------------------------------------------
 *	Submission Store Interface
 * --------------------------------------------------
 **/
export interface ISubmissionModuleStore
  extends Module<ISubmissionState, IRootState> {}
