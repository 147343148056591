export const FETCH_ASSET_MANAGERS_ENDPOINT = `base/admin/asset-managers`;
export const FETCH_ASSET_MANAGER_ORGANIZATIONS_ENDPOINT =
  `base/admin/organizations?` +
  `filter[organizationType]=asset-manager&page=1&limit=-1`;
export const ADD_ASSER_MANAGER_ENDPOINT = `base/admin/asset-managers`;
export const EDIT_ASSET_MANAGER_ENDPOINT = (organizationId: string) =>
  `base/admin/asset-managers/${organizationId}`;
export const FETCH_ASSER_MANAGER_ORGANIZATION_USERS_ENDPOINT = (
  organizationId: string
) => `base/admin/asset-managers/${organizationId}/users`;
export const ADD_ASSER_MANAGER_SUPER_ADMIN_ENDPOINT = (
  organizationId: string
) => `base/admin/asset-managers/${organizationId}/super-admin`;
export const MERGE_ASSET_MANAGER_ORGANIZATIONS = `base/admin/organizations/merge`;
