import { IUserState } from './state';
import { MutationTree } from 'vuex';
import * as MT from './mutationsTypes';
import { User } from '@/models/User';
import { Paginated } from '@/types';

/*--------------------------------------------------/
 * User Mutations
 *--------------------------------------------------*/
export const UserMutations: IUserMutations = {
  [MT.SET_USERS]: (state: IUserState, users: Paginated<User[]>) =>
    (state.users = users),

  [MT.SET_FETCHING_USERS]: (state: IUserState, flag: boolean) =>
    (state.fetchingUsers = flag),
};

/*--------------------------------------------------/
 * User Mutations Tree Interface
 *--------------------------------------------------*/
export interface IUserMutations extends MutationTree<IUserState> {
  [MT.SET_USERS](state: IUserState, users: Paginated<User[]>): void;

  [MT.SET_FETCHING_USERS](state: IUserState, flag: boolean): void;
}
