import Investment from '@/models/Investment';
import SharedResource from '@/models/SharedResource';
import { ISubmissionState } from '@/modules/submission/store/state';
import { MutationTree } from 'vuex';
import { TemplateResolution } from '../types';
import * as MT from './mutationsTypes';
import OrganizationAssociation from '@/models/OrganizationAssociation';

/*--------------------------------------------------/
 * Submission Mutations
 *--------------------------------------------------*/
export const SubmissionMutations: ISubmissionMutations = {
  [MT.SET_INVESTMENT]: (state: ISubmissionState, users: Investment) =>
    (state.investment = users),

  [MT.SET_FETCHING_INVESTMENT]: (state: ISubmissionState, flag: boolean) =>
    (state.fetchingInvestment = flag),

  [MT.SET_PROVIDED_TEMPLATE_TYPES]: (
    state: ISubmissionState,
    templateTypes: SharedResource[]
  ) => (state.providedTemplateTypes = templateTypes),
  [MT.SET_TEMPLATE_RESPONSE_SWIFTNESS]: (
    state: ISubmissionState,
    responseSwiftness: SharedResource[]
  ) => (state.templateResponseSwiftness = responseSwiftness),
  [MT.SET_TEMPLATE_SOURCES]: (
    state: ISubmissionState,
    templateSources: SharedResource[]
  ) => (state.templateSources = templateSources),
  [MT.SET_TEMPLATE_RESOLUTIONS]: (
    state: ISubmissionState,
    templateResolutions: TemplateResolution[]
  ) => (state.templateResolutions = templateResolutions),
  [MT.SET_MANAGER_ASSOCIATION_META]: (
    state: ISubmissionState,
    oa: OrganizationAssociation
  ) => (state.managerAssociationMeta = oa),
};

/*--------------------------------------------------/
 * Submission Mutations Tree Interface
 *--------------------------------------------------*/
export interface ISubmissionMutations extends MutationTree<ISubmissionState> {
  [MT.SET_INVESTMENT](state: ISubmissionState, users: Investment): void;

  [MT.SET_FETCHING_INVESTMENT](state: ISubmissionState, flag: boolean): void;
  [MT.SET_PROVIDED_TEMPLATE_TYPES](
    state: ISubmissionState,
    templateTypes: SharedResource[]
  ): void;
  [MT.SET_TEMPLATE_RESPONSE_SWIFTNESS](
    state: ISubmissionState,
    responseSwiftness: SharedResource[]
  ): void;
  [MT.SET_TEMPLATE_SOURCES](
    state: ISubmissionState,
    templateSources: SharedResource[]
  ): void;
  [MT.SET_TEMPLATE_RESOLUTIONS](
    state: ISubmissionState,
    templateResolutions: TemplateResolution[]
  ): void;
  [MT.SET_MANAGER_ASSOCIATION_META](
    state: ISubmissionState,
    oa: OrganizationAssociation
  ): void;
}
