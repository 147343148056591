import { TARGET_ENTITIES } from '@/utils/TargetEntity';
import { RouteConfig } from 'vue-router';
const ClientModule = () =>
  import(/* webpackChunkName: 'client' */ './ClientModule.vue');
const ListClientsPage = () =>
  import(/* webpackChunkName: 'client' */ './pages/ListClientsPage.vue');

export const ClientRoutes: RouteConfig[] = [
  {
    path: 'clients',
    component: ClientModule,
    meta: {
      middlewares: {
        canAccessEntity: TARGET_ENTITIES.listClients,
      },
    },
    children: [
      {
        path: 'list',
        name: 'clients.list',
        component: ListClientsPage,
      },
    ],
  },
];
