import { Filter } from '@/components/filter/Filter';
import { FilterPlan } from '@/components/filter/types';
import { Sort } from '@/components/sort/Sort';
import { SortPlan } from '@/components/sort/types';
import Organisation from '@/models/Organisation';
import { HTTPServiceClient } from '@/packages/http/service';
import { APIErrorResponse } from '@/packages/http/types';
import { Paginated, PaginatedParams } from '@/types';
import ErrorUtils from '@/utils/ErrorUtils';
import * as t from './endpoints';
import {
  ReportingDueDates,
  Feedback,
  InternalCodes,
  PensionSchemeDetails,
  PensionSchemeRequest,
} from './types';

/**
 * --------------------------------------------------
 *	Pension Scheme HTTP Service
 * --------------------------------------------------
 **/
export const PensionSchemeService = {
  /**
   * Fetches all the schemes.
   * @returns {Promise<Paginated<Organisation>>}
   */
  async fetchPensionSchemes(
    props?: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filterPlan?: FilterPlan | null;
    }>
  ): Promise<Paginated<Organisation>> {
    try {
      const filterParams = {
        ...(props?.filterPlan ? Filter.toQuery(props?.filterPlan) : {}),
      };
      const sortParams = {
        ...(props?.sortPlan ? Sort.toQuery(props?.sortPlan) : {}),
      };

      delete props?.sortPlan;
      delete props?.filterPlan;

      const params = { ...props, ...sortParams, ...filterParams };
      const { data } = await HTTPServiceClient.get<Paginated<Organisation>>(
        t.FETCH_PENSION_SCHEMES_ENDPOINT,
        {
          params,
        }
      );
      return data ?? {};
    } catch (e) {
      throw new Error(ErrorUtils.getErrorMessage(e as APIErrorResponse));
    }
  },

  /**
   * Fetches scheme details.
   * @returns {Promise<PensionSchemeDetails>}
   */
  async fetchPensionSchemeDetails(
    pensionSchemeId: string
  ): Promise<PensionSchemeDetails> {
    try {
      const { data } = await HTTPServiceClient.get<PensionSchemeDetails>(
        t.FETCH_PENSION_SCHEME_DETAILS_ENDPOINT(pensionSchemeId)
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Fetches scheme due date details.
   * @returns {Promise<PensionSchemeDetails>}
   */
  async fetchPensionSchemeDueDateDetails(
    pensionSchemeId: string
  ): Promise<PensionSchemeDetails> {
    try {
      const { data } = await HTTPServiceClient.get<PensionSchemeDetails>(
        t.FETCH_REPORTING_DUE_DATE_DETAILS_ENDPOINT(pensionSchemeId)
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Stores Feedback.
   * @returns {Promise<Response>}
   */
  async storePensionSchemeFeedback(feedback: Feedback): Promise<Response> {
    try {
      const body = {
        asset_manager_ratings: JSON.stringify(feedback),
      };

      const { data } = await HTTPServiceClient.post<Response>(
        t.STORE_PENSION_SCHEME_FEEDBACK_ENDPOINT,
        body
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Store Reporting Due date.
   * @returns {Promise<Response>}
   */
  async storeReportingDueDate(
    reportingDueDates: ReportingDueDates
  ): Promise<Response> {
    try {
      const { data } = await HTTPServiceClient.patch<Response>(
        t.STORE_REPORTING_DUE_DATE,
        { reporting_due_dates: reportingDueDates }
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(
        ErrorUtils.getErrorMessage(res) ?? 'Something went wrong'
      );
    }
  },

  /**
   * Store Due date.
   * @returns {Promise<Response>}
   */
  async storePensionScheme(
    pensionSchemeRequest: PensionSchemeRequest
  ): Promise<Response> {
    try {
      const body = { ...pensionSchemeRequest };
      const { data } = await HTTPServiceClient.post<Response>(
        t.STORE_PENSION_SCHEME_ENDPOINT(pensionSchemeRequest.orgId),
        body
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(
        ErrorUtils.getErrorMessage(res) ?? 'Something went wrong'
      );
    }
  },

  /**
   * Fetch Unique asset mangers.
   * @returns {Promise<Response>}
   */
  async fetchUniqueAssetManagers(pensionSchemeId: string): Promise<Response> {
    try {
      const { data } = await HTTPServiceClient.get<Response>(
        t.INTERNAL_CLIENT_CODE_DETAILS(pensionSchemeId)
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(
        ErrorUtils.getErrorMessage(res) ?? 'Something went wrong'
      );
    }
  },

  /**
   * Store Internal Client Code
   * @returns {Promise<Response>}
   */
  async storeInternalClientCode(
    internalCodes: InternalCodes,
    pensionSchemeId: string
  ): Promise<Response> {
    try {
      const { data } = await HTTPServiceClient.patch<Response>(
        t.INTERNAL_CLIENT_CODE_DETAILS(pensionSchemeId),
        { internal_client_code: internalCodes }
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(
        ErrorUtils.getErrorMessage(res) ?? 'Something went wrong'
      );
    }
  },
};
