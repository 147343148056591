import Vue from 'vue';
import Vuex, { Store, StoreOptions } from 'vuex';
import { AppModules } from '@/modules/store';

Vue.use(Vuex);

export const RootStore: IRootStore = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: AppModules,
});

export type IRootState = {};

export type IRootStore = Store<StoreOptions<IRootState>>;
