import { APIErrorResponse } from '@/packages/http/types';

export default class ErrorUtils {
  static getErrorMessage(errorResponse: APIErrorResponse): string {
    let errorMessage = errorResponse.data?.msg;
    const errors =
      errorResponse?.data?.errors || errorResponse?.data?.message?.errors;

    if (errors) {
      const errorKeys = Object.keys(errors).join('-');
      const regEx = /(\.id\.|idsAsArray)/;
      if (!errorMessage && regEx.test(errorKeys)) {
        errorMessage =
          'ID you have entered is invalid. Please enter correct one.';
      }
    }

    return errorMessage ?? 'Something went wrong';
  }
}
