import { Paginated } from '@/types';
import { QCFundFilters, QCFundInvestment } from '@/modules/qc/types';
import { FundCost } from '@/models/Analysis';

export type IQCState = {
  paginatedQCFundInvestment: Paginated<QCFundInvestment>;
  fetchingQCFunds: boolean;
  appliedQCFilters: QCFundFilters | null;
  yoyFundAnalysis: Paginated<FundCost> | null;
};

export const QCState: IQCState = {
  paginatedQCFundInvestment: {} as Paginated<QCFundInvestment>,
  fetchingQCFunds: false,
  appliedQCFilters: null,
  yoyFundAnalysis: null,
};
