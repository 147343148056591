var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    { attrs: { persistent: "", value: true, "max-width": "500" } },
    [
      _c(
        "v-card",
        { staticClass: "grey lighten-4 px-6 py-6" },
        [
          _c("div", { staticClass: "font-weight-medium text--primary my-1" }, [
            _vm._v(" Update Priority Date "),
          ]),
          _c("v-input", {
            attrs: { loading: _vm.loading },
            model: {
              value: _vm.clientName,
              callback: function ($$v) {
                _vm.clientName = $$v
              },
              expression: "clientName",
            },
          }),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    staticClass: "font-weight-medium border-0 mt-1",
                    attrs: {
                      multiple: false,
                      value: _vm.clientName,
                      dense: "",
                      disabled: true,
                      loading: _vm.loading,
                      color: "black",
                      "hide-details": "",
                      "data-testid": "pension-scheme-name",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "black--text" }, [
                              _vm._v("Client Name"),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    staticClass: "font-weight-medium",
                    attrs: {
                      multiple: false,
                      value: _vm.timePeriodRange,
                      dense: "",
                      disabled: true,
                      loading: _vm.loading,
                      "data-testid": "reporting-period",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "black--text" }, [
                              _vm._v("Reporting Period"),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    staticClass: "font-weight-medium border-0",
                    attrs: {
                      multiple: false,
                      value: _vm.currentPriorityDate,
                      dense: "",
                      disabled: true,
                      loading: _vm.loading,
                      color: "black",
                      "hide-details": "",
                      "data-testid": "current-priority-date",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "black--text" }, [
                              _vm._v("Current Priority Date"),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c("v-text-field", {
                    staticClass: "font-weight-medium",
                    attrs: {
                      multiple: false,
                      value: _vm.currentPriorityReason,
                      dense: "",
                      disabled: true,
                      loading: _vm.loading,
                      "data-testid": "current-priority-reason",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "black--text" }, [
                              _vm._v("Current Priority Reason"),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("DatePickerField", {
            staticClass: "mt-2",
            attrs: {
              "element-class": "''",
              label: "New Priority Date",
              value: _vm.priorityDate,
              min: new Date().toISOString(),
              disabled: _vm.loading,
              "date-picker-test-id": "qc-priority-date",
              "input-field-test-id": "qc-priority-date-input",
            },
            on: { input: (val) => (_vm.priorityDate = val) },
          }),
          _c("AutocompleteField", {
            staticClass: "mt-2",
            attrs: {
              label: "New Priority Reason",
              "chip-width": "100%",
              "element-class": "''",
              multiple: false,
              items: _vm.priorityReasons,
              value: _vm.priorityReason,
              "test-id": "qc-priority-reason",
            },
            on: { change: (val) => (_vm.priorityReason = val) },
          }),
          !_vm.userHasUpdatePermission
            ? _c(
                "v-card",
                {
                  staticClass: "mt-2 red--text text-sm-body-2",
                  attrs: { color: "error lighten-4" },
                },
                [
                  _c("ul", [
                    _c("li", [
                      _vm._v(
                        "You don't have permission to update priority dates"
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "v-card-actions",
            { staticClass: "text-right d-block pb-2 mt-3" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: {
                    color: "error",
                    outlined: "",
                    depressed: "",
                    dense: "",
                    small: "",
                    "data-testid": "pension-scheme-cancel-btn",
                  },
                  on: { click: this.redirectToQCList },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    type: "submit",
                    color: "success",
                    "data-testid": "pension-scheme-add-btn",
                    depressed: "",
                    dense: "",
                    small: "",
                    disabled:
                      !this.priorityDate ||
                      !this.priorityReason ||
                      !_vm.userHasUpdatePermission,
                  },
                  on: { click: _vm.update },
                },
                [_vm._v("Update")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }