import { User } from '@/models/User';

export default class Auth {
  static setToken(token: string): void {
    window.localStorage.setItem('token', token);
  }

  static getToken(): string | null {
    return window.localStorage.getItem('token');
  }

  static destroyToken(): void {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('userId');
  }

  static setUserId(userId: string): void {
    window.localStorage.setItem('userId', userId);
  }

  static getUserId(): string | null {
    return window.localStorage.getItem('userId');
  }

  static setUser(user: User): void {
    window.localStorage.setItem('user', JSON.stringify(user));
  }

  static getUser(): User | null {
    const user = window.localStorage.getItem('user');
    if (!user) {
      return null;
    }
    return JSON.parse(user);
  }

  static removeUser(): void {
    window.localStorage.removeItem('user');
  }

  static isAuthenticated(): boolean {
    return Auth.getUserId() != null && Auth.getToken() != null;
  }

  static setActiveRoles(roles: string[]) {
    const user: User | null = Auth.getUser();
    if (user) {
      user.roles = roles;
      Auth.setUser(user);
    }
  }
}
