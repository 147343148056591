import { IDashboardState } from '@/modules/dashboard/store/state';
import { MutationTree } from 'vuex';
import * as MT from './mutationsTypes';
import { User } from '@/models/User';
import Snackbar from '../../../models/Snackbar';
import Organisation from '@/models/Organisation';
import OrganisationType from '@/models/OrganisationType';
import Currency from '@/models/Currency';
import Country from '@/models/Country';

/*--------------------------------------------------/
 * Dashboard Mutations
 *--------------------------------------------------*/
export const DashboardMutations: IDashboardMutations = {
  [MT.SET_SNACKBAR]: (state: IDashboardState, snackbar: Snackbar) =>
    (state.snackbar = snackbar),
  [MT.SET_CURRENT_USER]: (state: IDashboardState, user: User) =>
    (state.currentUser = user),
  [MT.SET_VALIDATING_ENTITY_ACCESS]: (
    state: IDashboardState,
    validatingAccess: Boolean
  ) => (state.validatingEntityAccess = validatingAccess),
  [MT.SET_ORGANIZATIONS]: (
    state: IDashboardState,
    organizations: Organisation[]
  ) => (state.organizations = organizations),

  [MT.SET_ORGANIZATION_TYPES]: (
    state: IDashboardState,
    organizationTypes: OrganisationType[]
  ) => (state.organizationTypes = organizationTypes),

  [MT.SET_CURRENCY_LIST]: (state: IDashboardState, currencyList: Currency[]) =>
    (state.currencies = currencyList),

  [MT.SET_COUNTRY_LIST]: (state: IDashboardState, countryList: Country[]) =>
    (state.countries = countryList),
};

/*--------------------------------------------------/
 * Dashboard Mutations Tree Interface
 *--------------------------------------------------*/
export interface IDashboardMutations extends MutationTree<IDashboardState> {
  [MT.SET_SNACKBAR]: (state: IDashboardState, snackbar: Snackbar) => void;
  [MT.SET_CURRENT_USER](state: IDashboardState, user: User): void;
  [MT.SET_VALIDATING_ENTITY_ACCESS](
    state: IDashboardState,
    validatingAccess: Boolean
  ): void;
  [MT.SET_ORGANIZATIONS](
    state: IDashboardState,
    organizations: Organisation[]
  ): void;
  [MT.SET_ORGANIZATION_TYPES](
    state: IDashboardState,
    organizationTypes: OrganisationType[]
  ): void;
  [MT.SET_CURRENCY_LIST](
    state: IDashboardState,
    currencyList: Currency[]
  ): void;
  [MT.SET_COUNTRY_LIST](state: IDashboardState, countryList: Country[]): void;
}
