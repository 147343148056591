import { IUserState } from './state';
import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { User } from '@/models/User';
import { Paginated } from '@/types';
/**
 * --------------------------------------------------
 *	User Getters
 * --------------------------------------------------
 **/
export const UserGetters: IUserGetters = {
  users: (state: IUserState): Paginated<User[]> | null => state.users,

  fetchingUsers: (state: IUserState): boolean => state.fetchingUsers,
};

/**
 * --------------------------------------------------
 *	User Getters Interface
 * --------------------------------------------------
 **/
export interface IUserGetters extends GetterTree<IUserState, IRootState> {
  users(state: IUserState): Paginated<User[]> | null;

  fetchingUsers(state: IUserState): boolean;
}
