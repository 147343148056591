import { ConsultantActions } from './actions';
import { ConsultantGetters } from './getters';
import { ConsultantMutations } from './mutations';
import { ConsultantState, IConsultantState } from './state';
import { IRootState } from '@/store';
import { Module } from 'vuex';

export const ConsultantModuleStore: IConsultantModuleStore = {
  state: ConsultantState,
  actions: ConsultantActions,
  getters: ConsultantGetters,
  mutations: ConsultantMutations,
};

/**
 * --------------------------------------------------
 *	Consultant Store Interface
 * --------------------------------------------------
 **/
export type IConsultantModuleStore = Module<IConsultantState, IRootState>;
