export const FETCH_USER_ENDPOINT = `base/admin/user`;
export const VALIDATE_ENTITY_ACCESS_ENDPOINT = (entity: string) =>
  `base/admin/user/access/${entity}`;
export const FETCH_ORGANIZATIONS = `base/admin/organizations?page=1&limit=-1`;
export const FETCH_ORGANIZATION_TYPES = `base/organization-types`;
export const CREATE_CLIENT_CONTRACTS = `base/admin/organizations/contracts`;
export const USER_2FA_DETAILS_ENDPOINT = (user_id: string = '') => {
  return `base/admin/users/${user_id}/g2fa`;
};
export const SET_CLIENTS_PREFERRED_CURRENCY = `base/admin/organizations/preferred-currency`;
export const FETCH_CURRENCY_LIST = `base/currencies`;
export const FETCH_COUNTRY_LIST = `base/countries`;
