export const FETCH_FUNDS_INVESTMENTS_ENDPOINT = (paramString: string) => {
  return `base/admin/investments/funds?${paramString}`;
};
export const FETCH_SUB_FUNDS = `base/admin/sub-funds?page=1&limit=-1`;
export const FETCH_FUND_NATURES = `base/fund-natures`;
export const FETCH_STRATEGIES = `base/product-types`;
export const FETCH_TEMPLATES = `base/templates`;
export const FETCH_TIME_PERIODS = `base/time-periods`;
export const EXPORT_DATA = (paramString: string) =>
  `base/admin/investments/export?${paramString}`;
export const DOWNLOAD_DATA = (jobId: string) =>
  `base/admin/investments/jobs/${jobId}/download`;
export const RECOLLECT_DATA = `base/admin/investments/recollect`;
export const SEND_EMAILS = `base/admin/investments/emails`;
export const SEND_SUBMISSION_REQUESTS = `base/admin/submission-requests`;
export const FETCH_PRODUCT_TYPES = `base/strategies`;
export const UPDATE_FUNDS = `base/admin/investments/update`;
export const IMPORT_FUNDS = `base/admin/investments/import`;
export const IMPORT_ONBOARDING_SHEET = `base/admin/investments/import/onboarding_sheet`;
export const DOWNLOAD_SUBMISSION_TEMPLATE = (submissionId: string) =>
  `base/admin/submissions/${submissionId}/download`;
export const IMPERSONATE_CLIENT = (investmentId: string) =>
  `base/admin/clients/impersonate?investmentId=${investmentId}`;
export const FETCH_ADMIN_USERS = `base/admin/admin-users`;
export const RETRACT_SUBMISSION_REQUESTS = `base/admin/submission-requests/bulk-delete`;
export const MERGE_ENTITY_IDS = `base/admin/investments/merge`;
export const UPDATE_ROLLUP_TAG = `base/admin/investments/rollup-tag`;
export const UPDATE_INVESTMENT = (id: string) => {
  return `base/admin/investments/${id}`;
};
