import { RouteConfig } from 'vue-router';

const AuthModule = () =>
  import(/* webpackChunkName: 'auth' */ '@/modules/auth/AuthModule.vue');
const LoginPage = () =>
  import(/* webpackChunkName: 'auth' */ '@/modules/auth/pages/LoginPage.vue');
const TwoFactorVerificationPage = () =>
  import('@/modules/auth/pages/TwoFactorVerificationPage.vue');

export const AuthRoutes: RouteConfig[] = [
  {
    path: '/auth',
    component: AuthModule,
    meta: {
      middlewares: {
        requiresGuest: true,
      },
    },
    children: [
      {
        path: '/',
        name: 'auth',
        redirect: { name: 'auth.login' },
      },
      {
        path: 'login',
        name: 'auth.login',
        component: LoginPage,
      },
      {
        path: 'two-factor-authentication',
        name: 'auth.verify',
        component: TwoFactorVerificationPage,
        props: true,
      },
    ],
  },
];
