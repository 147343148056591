import { TARGET_ENTITIES } from '@/utils/TargetEntity';
import { RouteConfig } from 'vue-router';
const InvestmentModule = () =>
  import(/* webpackChunkName: 'investment' */ './InvestmentModule.vue');
const ListInvestmentsPage = () =>
  import(
    /* webpackChunkName: 'investment' */ './pages/ListInvestmentsPage.vue'
  );

export const InvestmentRoutes: RouteConfig[] = [
  {
    path: 'investments',
    component: InvestmentModule,
    meta: {
      middlewares: {
        canAccessEntity: TARGET_ENTITIES.listFunds,
      },
    },
    children: [
      {
        path: 'list',
        name: 'investments.list',
        component: ListInvestmentsPage,
      },
    ],
  },
];
