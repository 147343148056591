import Vue from 'vue';
import Router from 'vue-router';
import { Routes } from './routes';
import { MiddlewareMappings } from '@/router/middlewares';
import { Dispatcher } from '@/router/middlewares/dispatcher';

Vue.use(Router);

let router: Router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...Routes],
});

router = Dispatcher.register(router, MiddlewareMappings);

export default router;
