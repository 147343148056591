import { HTTPServiceClient } from '@/packages/http/service';
import * as t from './endpoints';
import Investment from '@/models/Investment';
import Submission from '@/models/Submission';
import {
  DownloadErrorReportsForBatchResponse,
  DownloadTemplateResponse,
  FetchErrorReportsForBatchUrlResponse,
  NotesRequest,
  summaryApiParam,
  SummaryResponse,
  TemplateResolution,
  TemplateUploadParams,
} from './types';
import SharedResource from '@/models/SharedResource';
import { APIErrorResponse } from '@/packages/http/types';
import ErrorUtils from '@/utils/ErrorUtils';
import { OrganisationAssociationMeta } from '@/models/OrganizationAssociation';

/**
 * --------------------------------------------------
 *	Submission HTTP Serice
 * --------------------------------------------------
 **/
export const SubmissionService = {
  async fetchInvestment(
    investment_id: string,
    includes?: string
  ): Promise<Investment> {
    const defaultInclude =
      'entity,timePeriod,rootInvestment.entity,entity.strategy,entity.productType,' +
      'latestSubmission,latestSubmission.notes,latestSubmission.latestRollUpJob,' +
      'submissionRequest,entity.subStrategy,rootInvestment.entity.providers,' +
      'latestSubmission.bypassedErrors,' +
      'rootInvestment.entity.parentOrganization,entity.assetManager,' +
      'latestQCItem.latestStatus';

    includes = includes ?? defaultInclude;

    try {
      const { data } = await HTTPServiceClient.get(
        t.INVESTMENT_ENDPOINT(investment_id),
        {
          params: {
            include: includes,
          },
        }
      );
      return data?.data ?? {};
    } catch (e) {
      throw new Error(ErrorUtils.getErrorMessage(e as APIErrorResponse));
    }
  },

  async fetchManagerAssociationMeta(
    investment_id: string
  ): Promise<OrganisationAssociationMeta> {
    try {
      const { data } = await HTTPServiceClient.get(
        t.MANAGER_ASSOCIATION_META_ENDPOINT(investment_id)
      );
      return data?.data ?? {};
    } catch (e) {
      throw new Error(e?.data?.message ?? 'Something went wrong');
    }
  },

  async submitTemplate(paramData: TemplateUploadParams): Promise<Investment> {
    try {
      const url = t.SUBMISSION_ENDPOINT();
      const formData = new FormData();
      formData.append('file', paramData.file, paramData.file.name);
      formData.append('investmentId', paramData.investmentId);
      formData.append('source', paramData.source);

      formData.append(
        'originallyProvidedTemplateType',
        paramData.originallyProvidedTemplateType
      );
      formData.append('templateSource', paramData.templateSource);
      formData.append(
        'templateResponseSwiftness',
        paramData.templateResponseSwiftness
      );
      formData.append('templateResolution', paramData.templateResolution);
      formData.append('notify', paramData.notify);
      formData.append('disabledRules', paramData.disabledRules);

      if (paramData.startDate && paramData.endDate) {
        formData.append('startDate', paramData.startDate);
        formData.append('endDate', paramData.endDate);
      }

      if (paramData.templateSubmittedOn) {
        formData.append('templateSubmittedOn', paramData.templateSubmittedOn);
      }

      const { data } = await HTTPServiceClient.post(url, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      return data?.data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  async fetchSubmission(submission_id: string): Promise<Submission> {
    try {
      const { data } = await HTTPServiceClient.get(
        t.SUBMISSION_ENDPOINT(submission_id)
      );
      return data?.data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  async changeSubmissionStatus(paramData: any): Promise<Submission> {
    try {
      const { data } = await HTTPServiceClient.post(
        t.EDIT_SUBMISSION_STATUS_ENDPOINT(paramData.submissionId),
        {
          ...paramData,
          submissionId: undefined,
          status: paramData?.status?.toLowerCase(),
        }
      );
      return data?.data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  async requestSubmissionReopen(paramData: any): Promise<any> {
    try {
      const { data } = await HTTPServiceClient.post(
        t.SUBMISSION_REQUEST_REOPEN_ENDPOINT(),
        paramData
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  async fetchSubmissionErrors(submission_id: string): Promise<any> {
    try {
      const { data } = await HTTPServiceClient.get(
        t.SUBMISSION_ERRORS_ENDPOINT(submission_id)
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  async downloadErrorReport(submission_id: string): Promise<any> {
    try {
      const { data } = await HTTPServiceClient.get(
        t.ERROR_REPORT_DOWNLOAD_ENDPOINT(submission_id)
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  async storeBypassedErrors(paramData: any): Promise<any> {
    try {
      const { data } = await HTTPServiceClient.post(
        t.SUBMISSION_BYPASSABLE_ERRORS_ENDPOINT(paramData.id),
        {
          ...paramData.postData,
        }
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  async fetchSubmissionsForBatch(batchId: string): Promise<Submission[]> {
    try {
      const { data } = await HTTPServiceClient.get(
        t.FETCH_SUBMISSION_FOR_BATCH(batchId)
      );
      return data?.data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  async downloadErrorReportsForBatch(
    batchId: string
  ): Promise<DownloadErrorReportsForBatchResponse> {
    try {
      const { data } = await HTTPServiceClient.post(
        t.DOWNLOAD_ERROR_REPORTS_FOR_BATCH(batchId)
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  async fetchErrorReporsUrlForBatch(
    jobId: string
  ): Promise<FetchErrorReportsForBatchUrlResponse> {
    try {
      const { data } = await HTTPServiceClient.get(
        t.FETCH_ERROR_REPORTS_URL_FOR_BATCH(jobId)
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  async fetchSummary(params: summaryApiParam): Promise<SummaryResponse> {
    try {
      const submissionId = params?.submissionIds?.length
        ? params?.submissionIds[0]
        : null;
      if (!submissionId) {
        throw new Error('Submission Id null');
      }
      const requestUrl = t.FETCH_SUBMISSION_SUMMARY(submissionId);
      const { data } = await HTTPServiceClient.get(requestUrl);
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  async downloadTemplate(
    submissionId: string
  ): Promise<DownloadTemplateResponse> {
    try {
      const { data } = await HTTPServiceClient.get(
        t.DOWNLOAD_TEMPLATE(submissionId)
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  async saveNotes(params: NotesRequest): Promise<Response> {
    try {
      const { data } = await HTTPServiceClient.post(t.SAVE_NOTES, params);
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  async fetchProvidedTemplateTypes(): Promise<SharedResource[]> {
    try {
      const { data } = await HTTPServiceClient.get(
        t.FETCH_PROVIDED_TEMPLATE_TYPES
      );
      return data?.data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  async fetchTemplateSources(): Promise<SharedResource[]> {
    try {
      const { data } = await HTTPServiceClient.get(t.FETCH_TEMPLATE_SOURCES);
      return data?.data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  async fetchResponseSwiftness(): Promise<SharedResource[]> {
    try {
      const { data } = await HTTPServiceClient.get(
        t.FETCH_TEMPLATE_RESPONSE_SWIFTNESS
      );
      return data?.data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  async fetchTemplateResolutions(): Promise<TemplateResolution[]> {
    try {
      const { data } = await HTTPServiceClient.get(
        t.FETCH_TEMPLATE_RESOLUTIONS
      );
      return data?.data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },
};
