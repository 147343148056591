import {
  Middleware,
  MiddlewareMapping,
  MiddlewareNext,
} from '@/router/middlewares/types';
import VueRouter, { Route } from 'vue-router';

export class Dispatcher {
  /**
   * Register middlewares on the router.
   *
   * @param router Vue Router Instance
   * @param middlewareMappings Middleware Mappings
   * @returns Configured router instance.
   */
  static register(router: VueRouter, middlewareMappings: MiddlewareMapping) {
    Dispatcher.registerBeforeResolver(router, middlewareMappings);
    return router;
  }

  private static registerBeforeResolver(
    router: VueRouter,
    middlewareMappings: MiddlewareMapping
  ) {
    router.beforeResolve(
      async (to: Route, from: Route, next: MiddlewareNext) => {
        // Get matched routes that have middlewares defined
        const matched = to.matched.filter(
          (route) => route.meta && route.meta.middlewares !== undefined
        );

        for (const route of matched) {
          const aliases = Object.keys(route.meta.middlewares);
          for (const alias of aliases) {
            const middlewareData = route.meta.middlewares[alias];
            const middleware: Middleware = middlewareMappings[alias];

            if (middleware) {
              const ret = await middleware.handle.call(
                this,
                to,
                from,
                next,
                middlewareData
              );

              if (ret) {
                // Middleware returned early
                return ret;
              }
            }
          }
        }
        // Continue
        next();
      }
    );
  }
}
