import { FilterPlan } from '@/components/filter/types';
import { SortPlan } from '@/components/sort/types';
import Organisation from '@/models/Organisation';
import { IRootState } from '@/store';
import { Paginated, PaginatedParams } from '@/types';
import { ActionContext, ActionTree } from 'vuex';
import { ConsultantService } from '../services';
import * as AT from './actionTypes';
import { SET_CONSULTANTS, SET_FETCHING_CONSULTANTS } from './mutationsTypes';
import { IConsultantState } from './state';

/**
 * --------------------------------------------------
 *  Consultant Actions
 * --------------------------------------------------
 **/
export const ConsultantActions: IConsultantActions = {
  [AT.FETCH_CONSULTANTS]: async (
    { commit },
    params?: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filterPlan?: FilterPlan | null;
    }>
  ): Promise<Paginated<Organisation>> => {
    commit(SET_FETCHING_CONSULTANTS, true);
    try {
      const data = await ConsultantService.fetchConsultants(params);
      commit(SET_CONSULTANTS, data);
      return data;
    } catch (e) {
      commit(SET_CONSULTANTS, []);
      throw e;
    } finally {
      commit(SET_FETCHING_CONSULTANTS, false);
    }
  },
};

/**
 * --------------------------------------------------
 *  Consultant Actions Interface
 * --------------------------------------------------
 **/
export interface IConsultantActions
  extends ActionTree<IConsultantState, IRootState> {
  [AT.FETCH_CONSULTANTS](
    context: IConsultantActionContext,
    params: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filterPlan?: FilterPlan | null;
    }>
  ): Promise<Paginated<Organisation>>;
}

/**
 * --------------------------------------------------
 *  Consultant Action Context Interface
 * --------------------------------------------------
 **/
export type IConsultantActionContext = ActionContext<
  IConsultantState,
  IRootState
>;
