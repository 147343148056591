var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("ValidationProvider", {
    staticClass: "text-field",
    attrs: { name: _vm.$attrs.label, rules: _vm.rules },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ errors }) {
          return _c(
            "v-text-field",
            _vm._g(
              _vm._b(
                {
                  attrs: {
                    "data-testId": _vm.testId,
                    "error-messages": errors,
                  },
                  model: {
                    value: _vm.innerValue,
                    callback: function ($$v) {
                      _vm.innerValue = $$v
                    },
                    expression: "innerValue",
                  },
                },
                "v-text-field",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          )
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }