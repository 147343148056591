import { SortPlan } from '@/components/sort/types';
import { HTTPServiceClient } from '@/packages/http/service';
import { APIErrorResponse } from '@/packages/http/types';
import { Paginated, PaginatedParams } from '@/types';
import ErrorUtils from '@/utils/ErrorUtils';
import RenewalOrganisation from '../../models/RenewalOrganisation';
import * as t from './endpoints';
import { MapRenewalFiltersToQuery } from './helper';
import { RenewalFilters } from './types';
import { ClientRenewalRequest } from '@/modules/dashboard/types';

/**
 * --------------------------------------------------
 *	Renewal HTTP Service
 * --------------------------------------------------
 **/
export const RenewalService = {
  /**
   * Fetches all the Renewal Organisations.
   * @returns {Promise<Paginated<RenewalOrganisation>>}
   */
  async fetchRenewalOrganisations(
    props?: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filters?: RenewalFilters | null;
    }>
  ): Promise<Paginated<RenewalOrganisation>> {
    try {
      const filterParamString = props?.filters
        ? MapRenewalFiltersToQuery(props?.filters)
        : '';
      const sortParams = {
        ...(props?.sortPlan ? this.sortQueryMapper(props?.sortPlan) : {}),
      };

      delete props?.sortPlan;
      delete props?.filters;

      const params = { ...props, ...sortParams };
      const { data } = await HTTPServiceClient.get<
        Paginated<RenewalOrganisation>
      >(t.FETCH_RENEWAL_ORGANISATIONS_ENDPOINT(filterParamString), {
        params,
      });
      return data ?? {};
    } catch (e) {
      throw new Error(ErrorUtils.getErrorMessage(e as APIErrorResponse));
    }
  },

  sortQueryMapper(plan: SortPlan): Record<string, string> {
    const params = new Map();
    let sortValueString = '';
    plan?.sortSets?.forEach((sortSet, index) => {
      let op = index > 0 ? ',' : '';
      if (sortSet?.operator === 'desc') {
        op += '-';
      }
      sortValueString += `${op}${sortSet.slug}`;
    });
    if (sortValueString) {
      params.set(`sort`, sortValueString);
    }
    return Object.fromEntries(params);
  },

  /**
   * Fetches Renewal Organisation Details
   * @returns {Promise<RenewalOrganisation>}
   */
  async fetchRenewalOrganisationDetails(
    renewalOrgId: string
  ): Promise<RenewalOrganisation> {
    try {
      const { data } = await HTTPServiceClient.get<RenewalOrganisation>(
        t.RENEWAL_ORGANISATION_DETAILS_ENDPOINT(renewalOrgId)
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Update Renewal Organisation Details
   * @returns {Promise<RenewalOrganisation>}
   */
  async updateRenewalOrganisationDetails(
    renewalOrgId: string
  ): Promise<RenewalOrganisation> {
    try {
      const { data } = await HTTPServiceClient.put<RenewalOrganisation>(
        t.RENEWAL_ORGANISATION_DETAILS_ENDPOINT(renewalOrgId),
        { mark_done_toggle: 'true' }
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Save Client Renewal Date.
   * @returns {Promise<Response>}
   */
  async saveClientRenewals(request: ClientRenewalRequest): Promise<Response> {
    try {
      const { data } = await HTTPServiceClient.put<Response>(
        t.CREATE_CLIENT_RENEWALS,
        request
      );
      return data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },
};
