import { TagActions } from './actions';
import { TagGetters } from './getters';
import { TagMutations } from './mutations';
import { ITagState, TagState } from './state';
import { IRootState } from '@/store';
import { Module } from 'vuex';

export const TagModuleStore: ITagModuleStore = {
  state: TagState,
  actions: TagActions,
  getters: TagGetters,
  mutations: TagMutations,
};

/**
 * --------------------------------------------------
 *  Tag Store Interface
 * --------------------------------------------------
 **/
export type ITagModuleStore = Module<ITagState, IRootState>;
