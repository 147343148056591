import { RouteConfig } from 'vue-router';
const SubmissionModule = () =>
  import(
    /* webpackChunkName: 'submission' */ '@/modules/submission/SubmissionModule.vue'
  );
const SubmissionPage = () =>
  import(/* webpackChunkName: 'submission' */ './pages/SubmissionPage.vue');

export const SubmissionRoutes: RouteConfig[] = [
  {
    path: 'submission',
    component: SubmissionModule,
    props: true,
    meta: {
      middlewares: {
        requiresAuth: true,
      },
    },
    children: [
      {
        path: ':investmentId',
        meta: {
          title: 'Submission',
        },
        name: 'submission-page',
        component: SubmissionPage,
      },
    ],
  },
];
