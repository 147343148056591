import { IClientState } from './state';
import { MutationTree } from 'vuex';
import * as MT from './mutationsTypes';
import { Paginated } from '@/types';
import Organisation from '@/models/Organisation';

/*--------------------------------------------------/
 * Client Mutations
 *--------------------------------------------------*/
export const ClientMutations: IClientMutations = {
  [MT.SET_CLIENTS]: (state: IClientState, clients: Paginated<Organisation[]>) =>
    (state.clients = clients),

  [MT.SET_FETCHING_CLIENTS]: (state: IClientState, flag: boolean) =>
    (state.fetchingClients = flag),
};

/*--------------------------------------------------/
 * Client Mutations Tree Interface
 *--------------------------------------------------*/
export interface IClientMutations extends MutationTree<IClientState> {
  [MT.SET_CLIENTS](
    state: IClientState,
    clients: Paginated<Organisation[]>
  ): void;

  [MT.SET_FETCHING_CLIENTS](state: IClientState, flag: boolean): void;
}
