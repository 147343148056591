import { IAssetManagerState } from './state';
import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { Paginated } from '@/types';
import Organisation from '@/models/Organisation';
/**
 * --------------------------------------------------
 *	AssetManager Getters
 * --------------------------------------------------
 **/
export const AssetManagerGetters: IAssetManagerGetters = {
  assetManagers: (
    state: IAssetManagerState
  ): Paginated<Organisation[]> | null => state.assetManagers,

  fetchingAssetManagers: (state: IAssetManagerState): boolean =>
    state.fetchingAssetManagers,
};

/**
 * --------------------------------------------------
 *	AssetManager Getters Interface
 * --------------------------------------------------
 **/
export interface IAssetManagerGetters
  extends GetterTree<IAssetManagerState, IRootState> {
  assetManagers(state: IAssetManagerState): Paginated<Organisation[]> | null;

  fetchingAssetManagers(state: IAssetManagerState): boolean;
}
