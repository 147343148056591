import { FilterPlan } from '@/components/filter/types';
import { SortPlan } from '@/components/sort/types';
import { IRootState } from '@/store';
import { Paginated, PaginatedParams } from '@/types';
import { ActionContext, ActionTree } from 'vuex';
import RenewalOrganisation from '../../../models/RenewalOrganisation';
import { RenewalService } from '../services';
import * as AT from './actionTypes';
import {
  SET_RENEWAL_ORGANISATIONS,
  SET_FETCHING_RENEWAL_ORGANISATION,
} from './mutationsTypes';
import { IRenewalState } from './state';

/**
 * --------------------------------------------------
 *  Renewal Actions
 * --------------------------------------------------
 **/
export const RenewalActions: IRenewalActions = {
  [AT.FETCH_RENEWAL_ORGANISATIONS]: async (
    { commit },
    params?: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filterPlan?: FilterPlan | null;
    }>
  ): Promise<Paginated<RenewalOrganisation>> => {
    commit(SET_FETCHING_RENEWAL_ORGANISATION, true);

    try {
      const data = await RenewalService.fetchRenewalOrganisations(params);
      commit(SET_RENEWAL_ORGANISATIONS, data);
      return data;
    } catch (e) {
      commit(SET_RENEWAL_ORGANISATIONS, []);
      throw e;
    } finally {
      commit(SET_FETCHING_RENEWAL_ORGANISATION, false);
    }
  },

  [AT.FETCH_RENEWAL_ORGANISATION_DETAILS]: async (
    { commit },
    schemeId: string
  ): Promise<RenewalOrganisation> => {
    return RenewalService.fetchRenewalOrganisationDetails(schemeId).then(
      (data) => {
        return data;
      }
    );
  },
};

/**
 * --------------------------------------------------
 *  Renewal Actions Interface
 * --------------------------------------------------
 **/
export interface IRenewalActions extends ActionTree<IRenewalState, IRootState> {
  [AT.FETCH_RENEWAL_ORGANISATIONS](
    context: IRenewalActionContext,
    params: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filterPlan?: FilterPlan | null;
    }>
  ): Promise<Paginated<RenewalOrganisation>>;

  [AT.FETCH_RENEWAL_ORGANISATION_DETAILS](
    context: IRenewalActionContext,
    schemeId: string
  ): Promise<RenewalOrganisation>;
}

/**
 * --------------------------------------------------
 *  Renewal Action Context Interface
 * --------------------------------------------------
 **/
export type IRenewalActionContext = ActionContext<IRenewalState, IRootState>;
