export type FilterBuilder = FilterPlan & {
  schema: FilterBuilderSchema;
};

export interface FilterPlan {
  filterSets: FilterSet[];
  conjunction: FilterConjunction;
}

export interface FilterBuilderSchema {
  availableConjunctions?: FilterConjunction[] | null;
  availableAttributes: FilterAttribute[];
  availableOperators?: FilterOperator[];
}

export type FilterConjunction = 'AND' | 'OR';

export interface FilterSet {
  id: string;
  slug: FilterAttribute['slug'] | null;
  operator: FilterOperatorSlug | null;
  data: FilterSetData | null;
  appendOperatorToValue?: boolean;
  includeSetAsArray?: boolean;
}

export type FilterSetData =
  | string
  | number
  | boolean
  | Date
  | string[]
  | number[]
  | Date[];

export interface FilterSetComplexData {
  key: string;
  value: FilterSetData;
  [key: string]: any;
}

export interface FilterAttribute {
  name: string;
  slug: string;
  type: FilterAttributeType['slug'];
  options?: FilterAttributeOptions;
}

export interface FilterAttributeType {
  slug: FilterAttributeTypeSlug;
  options?: {};
}

export type FilterAttributeTypeSlug = 'text' | 'list' | 'number' | 'date';

export interface FilterAttributeOptions {
  validate?: null | (() => FilterAttributeValidationResult);
  availableOperators?:
    | FilterOperator['slug'][]
    | FilterAvailableOperatorsResolver;
  availableValues?: FilterAttributeValue[] | FilterAvailableValuesResolver;

  multiple?: boolean;

  default_color_bg?: string;
  default_color_fg?: string;

  [key: string]: any;
  appendOperatorToValue?: boolean;
  includeSetAsArray?: boolean;
}

export type FilterAvailableOperatorsResolver = (
  params: FilterAvailableOperatorsResolverParams
) => FilterOperator['slug'][];

export type FilterAvailableValuesResolver = (
  params: FilterAvailableValuesResolverParams
) => FilterAttributeValue[];

export interface FilterAvailableOperatorsResolverParams {
  conjunction: FilterConjunction;
  attr: FilterAttribute;
}

export interface FilterAvailableValuesResolverParams {
  attribute: FilterAttribute;
}

export interface FilterAttributeValidationResult {
  error: boolean;
  message?: string;
}

export type FilterAttributeValue =
  | string
  | number
  | boolean
  | FilterAttributeValueObj;

export interface FilterAttributeValueObj {
  label: string;
  slug: string;
  options?: {
    color_fg?: string;
    color_bg?: string;
    [key: string]: any;
  };
}

export interface FilterOperator {
  name: string;
  slug: FilterOperatorSlug;
  options?: {
    ignoreValue?: boolean | null;
    enabledFor?: null | FilterAttributeTypeSlug[];
    disabledFor?: null | FilterAttributeTypeSlug[];
  };
}

export type FilterOperatorSlug =
  | 'contains'
  | 'doesNotContain'
  | 'isAllOf'
  | FilterOperatorSlugs.IS_ANY_OF
  | 'isNoneOf'
  | 'isEqualTo'
  | 'isNotEqualTo'
  | 'isEmpty'
  | 'isNotEmpty'
  | 'lessThan'
  | 'lessThanOrEqualTo'
  | 'moreThanOrEqualTo'
  | 'moreThan';

export enum FilterOperatorSlugs {
  IS_ANY_OF = 'isAnyOf',
}
