import { TARGET_ENTITIES } from '@/utils/TargetEntity';
import { RouteConfig } from 'vue-router';
const ConsultantModule = () =>
  import(/* webpackChunkName: 'consultant' */ './ConsultantModule.vue');
const ListConsultantsPage = () =>
  import(
    /* webpackChunkName: 'consultant' */ './pages/ListConsultantsPage.vue'
  );

export const ConsultantRoutes: RouteConfig[] = [
  {
    path: 'consultants',
    component: ConsultantModule,
    meta: {
      middlewares: {
        canAccessEntity: TARGET_ENTITIES.listConsultants,
      },
    },
    children: [
      {
        path: 'list',
        name: 'consultants.list',
        component: ListConsultantsPage,
      },
    ],
  },
];
