
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';

@Component({
  components: {
    ValidationProvider,
  },
})
export default class TextField extends Vue {
  @Prop({
    type: String,
    default: 'text-field-input',
  })
  testId?: string;

  @Watch('innerValue')
  innerValueWatcher(newVal: any) {
    this.$emit('input', newVal);
  }

  @Watch('value')
  valueWatcher(newVal: any) {
    this.innerValue = newVal;
  }

  @Prop({
    type: String,
    default: '',
  })
  value?: string;

  @Prop({
    type: String,
    default: '',
  })
  rules!: string;

  public innerValue = '';

  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
}
