import { ITagState } from './state';
import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { Tag } from '@/models/Tag';
import { Paginated } from '@/types';

/**
 * --------------------------------------------------
 *  Tag Getters
 * --------------------------------------------------
 **/
export const TagGetters: ITagGetters = {
  tags: (state: ITagState): Paginated<Tag> | null => state.tags,
};

/**
 * --------------------------------------------------
 *  Tag Getters Interface
 * --------------------------------------------------
 **/
export interface ITagGetters extends GetterTree<ITagState, IRootState> {
  tags(state: ITagState): Paginated<Tag> | null;
}
