import { AuthActions } from '@/modules/auth/store/actions';
import { AuthGetters } from '@/modules/auth/store/getters';
import { AuthMutations } from '@/modules/auth/store/mutations';
import { AuthState, IAuthState } from '@/modules/auth/store/state';
import { IRootState } from '@/store';
import { Module } from 'vuex';

export const AuthModuleStore: IAuthModuleStore = {
  state: AuthState,
  actions: AuthActions,
  getters: AuthGetters,
  mutations: AuthMutations,
};

/**
 * --------------------------------------------------
 *	Auth Store Interface
 * --------------------------------------------------
 **/
export type IAuthModuleStore = Module<IAuthState, IRootState>;
