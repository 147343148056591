var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { class: _vm.labelClass }, [_vm._v(_vm._s(_vm.label))]),
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "nudge-right": 40,
            transition: "scale-transition",
            "offset-y": "",
            disabled: _vm.disabled,
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      {
                        class: _vm.elementClass,
                        attrs: {
                          "data-testid": _vm.inputFieldTestId,
                          value: _vm.value,
                          label: _vm.textFieldLabel,
                          placeholder: _vm.label,
                          "prepend-inner-icon": "fa fa-calendar",
                          readonly: "",
                          clearable: !_vm.disabled,
                          dense: _vm.dense,
                          "hide-details": "",
                          solo: !_vm.outlined,
                          outlined: _vm.outlined,
                          disabled: _vm.disabled,
                        },
                        on: { input: _vm.input },
                      },
                      on
                    )
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.menu,
            callback: function ($$v) {
              _vm.menu = $$v
            },
            expression: "menu",
          },
        },
        [
          _c("v-date-picker", {
            attrs: {
              "data-testid": _vm.datePickerTestId,
              value: _vm.value,
              disabled: _vm.disabled,
              min: _vm.min,
              max: _vm.max,
            },
            on: { input: _vm.input },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }