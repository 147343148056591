import Organisation from '@/models/Organisation';
import { Paginated } from '@/types';

export type IAssetManagerState = {
  assetManagers: Paginated<Organisation[]> | null;
  fetchingAssetManagers: boolean;
};

export const AssetManagerState: IAssetManagerState = {
  assetManagers: null,
  fetchingAssetManagers: false,
};
