import Organisation from '@/models/Organisation';
import { Paginated } from '@/types';

export type IPensionSchemeState = {
  pensionSchemes: Paginated<Organisation[]> | null;
  fetchingPensionSchemes: boolean;
};

export const PensionSchemeState: IPensionSchemeState = {
  pensionSchemes: null,
  fetchingPensionSchemes: false,
};
