import * as AT from './actionTypes';
import * as MT from './mutationsTypes';
import { IRootState } from '@/store';
import { ActionContext, ActionTree } from 'vuex';
import { Paginated } from '@/types';
import { ResourceBenchmarkingItem } from '@/components/resource/types';
import { IBenchmarkingState } from './state';
import { BenchmarkingService } from '../services';

/**
 * --------------------------------------------------
 *  Benchmarking Actions
 * --------------------------------------------------
 **/
export const BenchmarkingActions: IBenchmarkingActions = {
  [AT.FETCH_BENCHMARKING_ITEMS]: async ({
    commit,
  }): Promise<Paginated<ResourceBenchmarkingItem>> => {
    return BenchmarkingService.fetchBenchmarking().then((data) => {
      commit(MT.SET_BENCHMARKING_ITEMS, data);
      return data;
    });
  },
};

/**
 * --------------------------------------------------
 *  Benchmarking Actions Interface
 * --------------------------------------------------
 **/
export interface IBenchmarkingActions
  extends ActionTree<IBenchmarkingState, IRootState> {
  [AT.FETCH_BENCHMARKING_ITEMS](
    context: IBenchmarkingActionContext
  ): Promise<Paginated<ResourceBenchmarkingItem>>;
}

/**
 * --------------------------------------------------
 *  Benchmarking Action Context Interface
 * --------------------------------------------------
 **/
export type IBenchmarkingActionContext = ActionContext<
  IBenchmarkingState,
  IRootState
>;
