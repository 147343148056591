import { ExportDataRequest, FundFilters } from './types';
import { RenewalFilters } from '@/modules/renewal/types';
import { Utils } from '@/utils/Utils';

export const MapFiltersToQuery = (fundFilters: FundFilters) => {
  const params = new URLSearchParams();

  fundFilters?.consultants?.forEach((it) =>
    params.append('organizationIds[]', it)
  );

  if (fundFilters?.disableTopLevelFilter) {
    params.set(
      'disableTopLevelFilter',
      fundFilters?.disableTopLevelFilter?.toString()
    );
  }

  if (fundFilters?.searchByUnderlyingFundIds) {
    params.set(
      'searchByUnderlyingFundIds',
      fundFilters?.searchByUnderlyingFundIds?.toString()
    );
  }

  fundFilters?.investors?.forEach((it) =>
    params.append('organizationIds[]', it)
  );

  fundFilters?.assetManagers?.forEach((it) =>
    params.append('filter[entity.managerOrgId][]', it)
  );
  fundFilters?.pensionSchemes?.forEach((it) =>
    params.append('filter[rootEntityId][]', it)
  );
  fundFilters?.productTypes?.forEach((it) =>
    params.append('filter[entity.strategyId][]', it)
  );
  fundFilters?.strategies?.forEach((it) =>
    params.append('filter[entity.productTypeId][]', it)
  );
  fundFilters?.fundNatures?.forEach((it) =>
    params.append('filter[entity.fundNatureId][]', it)
  );
  fundFilters?.subFunds?.forEach((it) =>
    params.append('filter[parentEntityId][]', it)
  );

  fundFilters?.status?.forEach((it) => params.append('status[]', it));

  fundFilters?.templates?.forEach((it) =>
    params.append('filter[templateId][]', it)
  );

  fundFilters?.timePeriods?.forEach((it) =>
    params.append('filter[time_period_id][]', it)
  );

  fundFilters?.tags?.forEach((it) => params.append('filter[tagId][]', it));

  fundFilters?.assignees?.forEach((it) =>
    params.append('filter[assignees.userId][]', it)
  );

  fundFilters?.qcLastUpdatedBy?.forEach((it) =>
    params.append('filter[qcLastUpdatedBy.userId][]', it)
  );

  fundFilters?.qcPriorityReasons?.forEach((it) =>
    params.append('filter[qcPriorityReasons][]', it)
  );

  if (fundFilters?.qcPriorityDateStart) {
    params.set(
      'filter[qcPriorityDateStart]',
      fundFilters?.qcPriorityDateStart?.toString()
    );
  }

  if (fundFilters?.qcPriorityDateEnd) {
    params.set(
      'filter[qcPriorityDateEnd]',
      fundFilters?.qcPriorityDateEnd?.toString()
    );
  }

  fundFilters?.participants?.forEach((it) =>
    params.append('filter[participants.userId][]', it)
  );

  fundFilters?.subStrategies?.forEach((it) =>
    params.append('filter[entity.thirdLevelStrategyId][]', it)
  );

  fundFilters?.qcStatus?.forEach((it) =>
    params.append('filter[qcStatus][]', it)
  );

  fundFilters?.investmentStatus?.forEach((it) =>
    params.append('filter[investment_status][]', it)
  );

  if (fundFilters?.qcStatusStartDate) {
    params.set(
      'filter[qcStatusStartDate]',
      fundFilters?.qcStatusStartDate?.toString()
    );
  }

  if (fundFilters?.qcStatusEndDate) {
    params.set(
      'filter[qcStatusEndDate]',
      fundFilters?.qcStatusEndDate?.toString()
    );
  }

  if (fundFilters?.name) {
    params.set('filter[entity.name]', fundFilters?.name?.toString());
  }

  // Single ID
  if (fundFilters?.id) {
    params.set('filter[id][]', fundFilters?.id?.toString());
  }

  if (fundFilters?.parentId) {
    params.set('filter[parent_id][]', fundFilters?.parentId?.toString());
  }

  // Multiple ID
  fundFilters?.investmentIds?.forEach((it) =>
    params.append('filter[id][]', it)
  );

  if (fundFilters?.startDate) {
    params.set('filter[requestStartDate]', fundFilters?.startDate?.toString());
  }

  if (fundFilters?.endDate) {
    params.set('filter[requestEndDate]', fundFilters?.endDate?.toString());
  }

  fundFilters?.benchmarkingStatus?.forEach((it) =>
    params.append('filter[benchmarkingStatus][]', it)
  );

  if (fundFilters?.submissionMadeOnStartDate) {
    params.set(
      'filter[submissionMadeOnStartDate]',
      fundFilters?.submissionMadeOnStartDate?.toString()
    );
  }

  if (fundFilters?.submissionDaysCount) {
    const now = new Date();
    now.setDate(now.getDate() - fundFilters?.submissionDaysCount);

    const date = Utils.formatDate(now.toString(), 'yyyy-MM-dd') ?? '';
    if (date) {
      params.set('filter[submissionMadeOnStartDate]', date);
    }
  }

  if (fundFilters?.submissionMadeOnEndDate) {
    params.set(
      'filter[submissionMadeOnEndDate]',
      fundFilters?.submissionMadeOnEndDate?.toString()
    );
  }

  fundFilters?.submissionMadeBy?.forEach((it) =>
    params.append('filter[submissionMadeBy][]', it)
  );

  if (fundFilters?.uniqueIdentifier) {
    params.set(
      'filter[entity.uniqueIdentifier]',
      fundFilters?.uniqueIdentifier?.toString()
    );
  }

  if (fundFilters?.internalCode) {
    params.set(
      'filter[entity.internalCode]',
      fundFilters?.internalCode?.toString()
    );
  }

  if (fundFilters?.internalPortfolioCode) {
    params.set(
      'filter[entity.internalPortfolioCode]',
      fundFilters?.internalPortfolioCode?.toString()
    );
  }

  if (fundFilters?.fundManagerEmail) {
    params.set(
      'filter[entity.managerEmail]',
      fundFilters?.fundManagerEmail?.toString()
    );
  }

  return params.toString();
};

export const MapRenewalsFiltersToQuery = (renewalFilters: RenewalFilters) => {
  const params = new URLSearchParams();

  renewalFilters?.pensionSchemes?.forEach((it) =>
    params.append('filter[pension_scheme_id][]', it)
  );

  renewalFilters?.timePeriods?.forEach((it) =>
    params.append('filter[time_period_id][]', it)
  );

  renewalFilters?.assignees?.forEach((it) =>
    params.append('filter[assignees.userId][]', it)
  );

  if (renewalFilters?.consultantSearchKey) {
    params.set(
      'filter[consultant_name]',
      renewalFilters?.consultantSearchKey?.toString()
    );
  }

  if (renewalFilters?.providerSearchKey) {
    params.set(
      'filter[provider_name]',
      renewalFilters?.providerSearchKey?.toString()
    );
  }

  return params.toString();
};

export const ResolveExportDataParams = (request: ExportDataRequest) => {
  const params: any = { exportType: request?.exportType };

  if (request?.dataRequestsFor === 'currentPage') {
    params['page'] = request?.page ?? 1;
    params['limit'] = request?.limit ?? 15;
  }

  if (request?.investmentId) {
    params['investmentId'] = request?.investmentId;
  }

  if (request?.schemeName) {
    params['schemeName'] = request?.schemeName;
  }

  if (request?.reportingPeriod) {
    params['reportingPeriod'] = request?.reportingPeriod;
  }

  return params;
};

export const DateFormat: string = 'yyyy-MM-dd H:m:s';
