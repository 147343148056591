import { HTTPServiceClient } from '@/packages/http/service';
import { Paginated, PaginatedParams } from '@/types';
import ErrorUtils from '@/utils/ErrorUtils';
import { SortPlan } from '../../components/sort/types';
import { FundCost } from '../../models/Analysis';
import Fund from '../../models/Fund';
import FundNature from '../../models/FundNature';
import Investment from '../../models/Investment';
import Organisation from '../../models/Organisation';
import OrganisationType from '../../models/OrganisationType';
import ProductType from '../../models/ProductType';
import Strategy from '../../models/Strategy';
import Template from '../../models/Template';
import TimePeriod from '../../models/TimePeriod';
import { APIErrorResponse } from '../../packages/http/types';
import * as t from './endpoints';
import {
  AnalysisFundFilters,
  AnalysisParamWithFilter,
  ClientsParam,
  ExcelReportParam,
  GeneratedReportDetails,
  ReportResponse,
  RootDetail,
  SubFund,
} from './types';
import { CTR_REPORT_VERSION_ENDPOINT } from './endpoints';

/**
 * --------------------------------------------------
 *	Analysis HTTP Service
 * --------------------------------------------------
 **/
export const AnalysisService = {
  async getFundAnalysis(
    props?: PaginatedParams<{
      sortPlan?: SortPlan | null;
      filters?: AnalysisFundFilters | null;
    }>
  ): Promise<Paginated<FundCost>> {
    try {
      const { data } = await HTTPServiceClient.get(t.GET_FUND_ANALYSIS(props));
      return data ?? {};
    } catch (e) {
      throw new Error(ErrorUtils.getErrorMessage(e as APIErrorResponse));
    }
  },

  /**
   * Fetches all the root details
   * @returns {Promise<Organisation[]>}
   */
  getRootDetails(
    params: AnalysisParamWithFilter
  ): Promise<Paginated<RootDetail>> {
    return HTTPServiceClient.get(t.GET_ROOT_DETAILS(params))
      .then(({ data }) => data)
      .catch((error) => {
        return null;
      });
  },

  /**
   * Fetches all the root details
   * @returns {Promise<Organisation[]>}
   */
  getClientsByOrgId(params: AnalysisParamWithFilter): Promise<Organisation[]> {
    return HTTPServiceClient.get(t.GET_ORG_CLIENTS(params))
      .then(({ data }) => data)
      .catch((error) => {
        return null;
      });
  },

  async getSubFundsByOrgIds(params: ClientsParam): Promise<SubFund[]> {
    const { data } = await HTTPServiceClient.get(
      t.GET_SUB_FUNDS_BY_ORG_IDS(params)
    );
    return data.data;
  },

  async fetchSchemesByOrgIds(params: ClientsParam): Promise<Investment[]> {
    const { data } = await HTTPServiceClient.get(t.INVESTMENT_ENDPOINT(params));
    return data.data;
  },

  async fetchCTRReportVersion(): Promise<{ label: string; value: number }[]> {
    const { data } = await HTTPServiceClient.get(t.CTR_REPORT_VERSION_ENDPOINT);
    return data.data;
  },

  generateDataReconReportExport(
    generateReportRequest: any
  ): Promise<ReportResponse> {
    const body = { ...generateReportRequest };
    return HTTPServiceClient.post(t.GENERATE_REPORT, body).then(
      ({ data }) => data
    );
  },

  async downloadReport(
    downloadReportRequest: ReportResponse
  ): Promise<GeneratedReportDetails> {
    const { data } = await HTTPServiceClient.get(
      t.DOWNLOAD_REPORT(downloadReportRequest.jobId)
    );
    return data;
  },

  /**
   * Fetches all the subfunds.
   * @returns {Promise<Fund[]>}
   */
  async fetchSubFunds(): Promise<Fund[]> {
    try {
      const { data } = await HTTPServiceClient.get<Paginated<Fund>>(
        t.FETCH_SUB_FUNDS
      );
      return data.data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Fetches all product types or Fund Strategies
   * @returns {Promise<ProductType[]>}
   */
  fetchProductTypes(params: any): Promise<ProductType[]> {
    return HTTPServiceClient.get(t.FETCH_PRODUCT_TYPES)
      .then(({ data }) => data?.data)
      .catch((error) => {
        return null;
      });
  },

  /**
   * Fetches all time periods / Reporting Periods
   * @returns {Promise<TimePeriod[]>}
   */
  fetchTimePeriods(params: any): Promise<TimePeriod[]> {
    return HTTPServiceClient.get(t.FETCH_TIME_PERIODS)
      .then(({ data }) => data?.data)
      .catch((error) => {
        return null;
      });
  },

  /**
   * Fetches all the strategies.
   * @returns {Promise<Strategy[]>}
   */
  async fetchStrategies(): Promise<Strategy[]> {
    try {
      const { data } = await HTTPServiceClient.get<Paginated<Strategy>>(
        t.FETCH_STRATEGIES
      );
      return data.data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Fetches all the fund natures.
   * @returns {Promise<FundNature[]>}
   */
  async fetchFundNatures(): Promise<FundNature[]> {
    try {
      const { data } = await HTTPServiceClient.get<Paginated<FundNature>>(
        t.FETCH_FUND_NATURES
      );
      return data.data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },

  /**
   * Fetches all the templates.
   * @returns {Promise<Template[]>}
   */
  async fetchTemplates(): Promise<Template[]> {
    try {
      const { data } = await HTTPServiceClient.get<Paginated<Template>>(
        t.FETCH_TEMPLATES
      );
      return data.data ?? {};
    } catch (e) {
      const res = e as APIErrorResponse;
      throw new Error(res.data?.msg ?? 'Something went wrong');
    }
  },
};
