import { TARGET_ENTITIES } from '@/utils/TargetEntity';
import { RouteConfig } from 'vue-router';
const FiduciaryModel = () =>
  import(/* webpackChunkName: 'fiduciary' */ './FiduciaryModule.vue');
const ListFiduciaryPage = () =>
  import(/* webpackChunkName: 'fiduciary' */ './pages/ListFiduciaryPage.vue');

export const FiduciaryRoutes: RouteConfig[] = [
  {
    path: 'fiduciaries',
    component: FiduciaryModel,
    meta: {
      middlewares: {
        canAccessEntity: TARGET_ENTITIES.listConsultants,
      },
    },
    children: [
      {
        path: 'list',
        name: 'fiduciaries.list',
        component: ListFiduciaryPage,
      },
    ],
  },
];
